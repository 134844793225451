import {Heading1} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import {CustomLink} from '../../components/CustomLink/CustomLink';
import styled from 'styled-components';

const StyledNoMatch = styled.div`
    min-height: 400px;
`;

const NoMatch = () => {
    return (
        <StyledNoMatch>
            <Heading1 $noMargin>404 - Pagina niet gevonden</Heading1>
            <Text>Het lijkt erop dat deze pagina niet (meer) bestaat. Wil je <CustomLink to="/">inloggen</CustomLink>?</Text>
        </StyledNoMatch>
    )
}

export default NoMatch;