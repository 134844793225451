import React from 'react';
import styled from 'styled-components';
import {ReactComponent as Bell} from '../../assets/icons/bell.svg';
import {useOpenModal} from '../../hooks/useOpenModal';
import {AspectRatio} from '../AspectRatio/AspectRatio';
import {Heading2, SubHeading} from '../Heading/Heading';
import sanitizeHtml from 'sanitize-html';
import NewsSideDrawer from '../SideDrawer/NewsSideDrawer';
import {HTMLText} from '../Text/Text';

const StyledWhatsNewButton = styled.button`
    position: relative;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: var(--color-primary-light);
    margin-left: auto;
    cursor: pointer;
    outline: none;
    border: none;
    
    &:hover {
        background-color: var(--color-primary-light-hover);
    }
`;

const StyledBell = styled(Bell)`
    width: 24px;
    height: 24px;

    path { fill: var(--color-primary); }
`;

const Unread = styled.div`
    position: absolute;
    top: -4px;
    right: -4px;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: red;
    white-space: nowrap;
    border-radius: 50%;
    color: var(--color-white);
    font-size: 1.1rem;
    font-weight: var(--fw-bold);
`;

const Item = styled.div``;
const ImageWrapper = styled.div``;

const ItemContent = styled.div`
    padding: 0 30px;
`;

const InsideItemContent = styled.div`
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 2px solid var(--color-divider);
`;

const StyledHTMLText = styled(HTMLText)`
    p {
        margin-top: 0;
        margin-bottom: 10px;
    }

    & > *:last-child {
        margin-bottom: 0;
    }
`;

// TODO remove test data
const data ={
    items: [
        {
            id: 1,
            image: null,
            title: "Nieuw: App accounts aanmaken in het portaal",
            date: "18 juni 2024",
            text: "<p>Het is nu mogelijk om in het portaal, bij de persoonlijke pagina van een patiënt, een app account voor diegene aan te maken. Ook kan een app account van een patiënt juist weer verwijderd worden.</p>"
        },
        {
            id: 2,
            image: "https://placehold.co/600x600",
            title: "Nieuw: het berichtencentrum",
            date: "16 juni 2024",
            text: "<p>Verstuur nu berichten naar meerdere patiënten tegelijk door te filteren op locatie, datum van de afspraak, zorgverzekeraar en of ze nog openstaande vragenlijsten hebben.</p>Verstuur tot 200 pushberichten tegelijk, direct of plan deze in voor een later moment.<p></p><p>Vind alles wat verstuurd of ingepland is terug bij ‘Verstuurde berichten’, of apart bij de persoonlijke pagina van iedere patiënt.</p>"
        },
        {
            id: 3,
            image: null,
            title: "Nieuw: App accounts aanmaken in het portaal",
            date: "18 juni 2024",
            text: "<p>Het is nu mogelijk om in het portaal, bij de persoonlijke pagina van een patiënt, een app account voor diegene aan te maken. Ook kan een app account van een patiënt juist weer verwijderd worden.</p>"
        },
        {
            id: 4,
            image: "https://placehold.co/600x600",
            title: "Nieuw: het berichtencentrum",
            date: "16 juni 2024",
            text: "<p>Verstuur nu berichten naar meerdere patiënten tegelijk door te filteren op locatie, datum van de afspraak, zorgverzekeraar en of ze nog openstaande vragenlijsten hebben.</p>Verstuur tot 200 pushberichten tegelijk, direct of plan deze in voor een later moment.<p></p><p>Vind alles wat verstuurd of ingepland is terug bij ‘Verstuurde berichten’, of apart bij de persoonlijke pagina van iedere patiënt.</p>"
        },
        {
            id: 5,
            image: null,
            title: "Nieuw: App accounts aanmaken in het portaal",
            date: "18 juni 2024",
            text: "<p>Het is nu mogelijk om in het portaal, bij de persoonlijke pagina van een patiënt, een app account voor diegene aan te maken. Ook kan een app account van een patiënt juist weer verwijderd worden.</p>"
        },
        {
            id: 6,
            image: "https://placehold.co/600x600",
            title: "Nieuw: het berichtencentrum",
            date: "16 juni 2024",
            text: "<p>Verstuur nu berichten naar meerdere patiënten tegelijk door te filteren op locatie, datum van de afspraak, zorgverzekeraar en of ze nog openstaande vragenlijsten hebben.</p>Verstuur tot 200 pushberichten tegelijk, direct of plan deze in voor een later moment.<p></p><p>Vind alles wat verstuurd of ingepland is terug bij ‘Verstuurde berichten’, of apart bij de persoonlijke pagina van iedere patiënt.</p>"
        },
        {
            id: 7,
            image: null,
            title: "Nieuw: App accounts aanmaken in het portaal",
            date: "18 juni 2024",
            text: "<p>Het is nu mogelijk om in het portaal, bij de persoonlijke pagina van een patiënt, een app account voor diegene aan te maken. Ook kan een app account van een patiënt juist weer verwijderd worden.</p>"
        },
        {
            id: 8,
            image: "https://placehold.co/600x600",
            title: "Nieuw: het berichtencentrum",
            date: "16 juni 2024",
            text: "<p>Verstuur nu berichten naar meerdere patiënten tegelijk door te filteren op locatie, datum van de afspraak, zorgverzekeraar en of ze nog openstaande vragenlijsten hebben.</p>Verstuur tot 200 pushberichten tegelijk, direct of plan deze in voor een later moment.<p></p><p>Vind alles wat verstuurd of ingepland is terug bij ‘Verstuurde berichten’, of apart bij de persoonlijke pagina van iedere patiënt.</p>"
        },
        {
            id: 9,
            image: null,
            title: "Nieuw: App accounts aanmaken in het portaal",
            date: "18 juni 2024",
            text: "<p>Het is nu mogelijk om in het portaal, bij de persoonlijke pagina van een patiënt, een app account voor diegene aan te maken. Ook kan een app account van een patiënt juist weer verwijderd worden.</p>"
        },
        {
            id: 10,
            image: "https://placehold.co/600x600",
            title: "Nieuw: het berichtencentrum",
            date: "16 juni 2024",
            text: "<p>Verstuur nu berichten naar meerdere patiënten tegelijk door te filteren op locatie, datum van de afspraak, zorgverzekeraar en of ze nog openstaande vragenlijsten hebben.</p>Verstuur tot 200 pushberichten tegelijk, direct of plan deze in voor een later moment.<p></p><p>Vind alles wat verstuurd of ingepland is terug bij ‘Verstuurde berichten’, of apart bij de persoonlijke pagina van iedere patiënt.</p>"
        },
        {
            id: 11,
            image: null,
            title: "Nieuw: App accounts aanmaken in het portaal",
            date: "18 juni 2024",
            text: "<p>Het is nu mogelijk om in het portaal, bij de persoonlijke pagina van een patiënt, een app account voor diegene aan te maken. Ook kan een app account van een patiënt juist weer verwijderd worden.</p>"
        },
        {
            id: 12,
            image: "https://placehold.co/600x600",
            title: "Nieuw: het berichtencentrum",
            date: "16 juni 2024",
            text: "<p>Verstuur nu berichten naar meerdere patiënten tegelijk door te filteren op locatie, datum van de afspraak, zorgverzekeraar en of ze nog openstaande vragenlijsten hebben.</p>Verstuur tot 200 pushberichten tegelijk, direct of plan deze in voor een later moment.<p></p><p>Vind alles wat verstuurd of ingepland is terug bij ‘Verstuurde berichten’, of apart bij de persoonlijke pagina van iedere patiënt.</p>"
        },
        {
            id: 13,
            image: null,
            title: "Nieuw: App accounts aanmaken in het portaal",
            date: "18 juni 2024",
            text: "<p>Het is nu mogelijk om in het portaal, bij de persoonlijke pagina van een patiënt, een app account voor diegene aan te maken. Ook kan een app account van een patiënt juist weer verwijderd worden.</p>"
        },
        {
            id: 14,
            image: "https://placehold.co/600x600",
            title: "Nieuw: het berichtencentrum",
            date: "16 juni 2024",
            text: "<p>Verstuur nu berichten naar meerdere patiënten tegelijk door te filteren op locatie, datum van de afspraak, zorgverzekeraar en of ze nog openstaande vragenlijsten hebben.</p>Verstuur tot 200 pushberichten tegelijk, direct of plan deze in voor een later moment.<p></p><p>Vind alles wat verstuurd of ingepland is terug bij ‘Verstuurde berichten’, of apart bij de persoonlijke pagina van iedere patiënt.</p>"
        },
        {
            id: 15,
            image: null,
            title: "Nieuw: App accounts aanmaken in het portaal",
            date: "18 juni 2024",
            text: "<p>Het is nu mogelijk om in het portaal, bij de persoonlijke pagina van een patiënt, een app account voor diegene aan te maken. Ook kan een app account van een patiënt juist weer verwijderd worden.</p>"
        },
        {
            id: 16,
            image: "https://placehold.co/600x600",
            title: "Nieuw: het berichtencentrum",
            date: "16 juni 2024",
            text: "<p>Verstuur nu berichten naar meerdere patiënten tegelijk door te filteren op locatie, datum van de afspraak, zorgverzekeraar en of ze nog openstaande vragenlijsten hebben.</p>Verstuur tot 200 pushberichten tegelijk, direct of plan deze in voor een later moment.<p></p><p>Vind alles wat verstuurd of ingepland is terug bij ‘Verstuurde berichten’, of apart bij de persoonlijke pagina van iedere patiënt.</p>"
        },
        {
            id: 17,
            image: null,
            title: "Nieuw: App accounts aanmaken in het portaal",
            date: "18 juni 2024",
            text: "<p>Het is nu mogelijk om in het portaal, bij de persoonlijke pagina van een patiënt, een app account voor diegene aan te maken. Ook kan een app account van een patiënt juist weer verwijderd worden.</p>"
        },
        {
            id: 18,
            image: "https://placehold.co/600x600",
            title: "Nieuw: het berichtencentrum",
            date: "16 juni 2024",
            text: "<p>Verstuur nu berichten naar meerdere patiënten tegelijk door te filteren op locatie, datum van de afspraak, zorgverzekeraar en of ze nog openstaande vragenlijsten hebben.</p>Verstuur tot 200 pushberichten tegelijk, direct of plan deze in voor een later moment.<p></p><p>Vind alles wat verstuurd of ingepland is terug bij ‘Verstuurde berichten’, of apart bij de persoonlijke pagina van iedere patiënt.</p>"
        },
        {
            id: 19,
            image: null,
            title: "Nieuw: App accounts aanmaken in het portaal",
            date: "18 juni 2024",
            text: "<p>Het is nu mogelijk om in het portaal, bij de persoonlijke pagina van een patiënt, een app account voor diegene aan te maken. Ook kan een app account van een patiënt juist weer verwijderd worden.</p>"
        },
        {
            id: 20,
            image: "https://placehold.co/600x600",
            title: "Nieuw: het berichtencentrum",
            date: "16 juni 2024",
            text: "<p>Verstuur nu berichten naar meerdere patiënten tegelijk door te filteren op locatie, datum van de afspraak, zorgverzekeraar en of ze nog openstaande vragenlijsten hebben.</p>Verstuur tot 200 pushberichten tegelijk, direct of plan deze in voor een later moment.<p></p><p>Vind alles wat verstuurd of ingepland is terug bij ‘Verstuurde berichten’, of apart bij de persoonlijke pagina van iedere patiënt.</p>"
        },
        {
            id: 21,
            image: null,
            title: "Nieuw: App accounts aanmaken in het portaal",
            date: "18 juni 2024",
            text: "<p>Het is nu mogelijk om in het portaal, bij de persoonlijke pagina van een patiënt, een app account voor diegene aan te maken. Ook kan een app account van een patiënt juist weer verwijderd worden.</p>"
        },
        {
            id: 22,
            image: "https://placehold.co/600x600",
            title: "Nieuw: het berichtencentrum",
            date: "16 juni 2024",
            text: "<p>Verstuur nu berichten naar meerdere patiënten tegelijk door te filteren op locatie, datum van de afspraak, zorgverzekeraar en of ze nog openstaande vragenlijsten hebben.</p>Verstuur tot 200 pushberichten tegelijk, direct of plan deze in voor een later moment.<p></p><p>Vind alles wat verstuurd of ingepland is terug bij ‘Verstuurde berichten’, of apart bij de persoonlijke pagina van iedere patiënt.</p>"
        },
        {
            id: 23,
            image: null,
            title: "Nieuw: App accounts aanmaken in het portaal",
            date: "18 juni 2024",
            text: "<p>Het is nu mogelijk om in het portaal, bij de persoonlijke pagina van een patiënt, een app account voor diegene aan te maken. Ook kan een app account van een patiënt juist weer verwijderd worden.</p>"
        },
        {
            id: 24,
            image: "https://placehold.co/600x600",
            title: "Nieuw: het berichtencentrum",
            date: "16 juni 2024",
            text: "<p>Verstuur nu berichten naar meerdere patiënten tegelijk door te filteren op locatie, datum van de afspraak, zorgverzekeraar en of ze nog openstaande vragenlijsten hebben.</p>Verstuur tot 200 pushberichten tegelijk, direct of plan deze in voor een later moment.<p></p><p>Vind alles wat verstuurd of ingepland is terug bij ‘Verstuurde berichten’, of apart bij de persoonlijke pagina van iedere patiënt.</p>"
        },
        {
            id: 25,
            image: null,
            title: "Nieuw: App accounts aanmaken in het portaal",
            date: "18 juni 2024",
            text: "<p>Het is nu mogelijk om in het portaal, bij de persoonlijke pagina van een patiënt, een app account voor diegene aan te maken. Ook kan een app account van een patiënt juist weer verwijderd worden.</p>"
        },
        {
            id: 26,
            image: "https://placehold.co/600x600",
            title: "Nieuw: het berichtencentrum",
            date: "16 juni 2024",
            text: "<p>Verstuur nu berichten naar meerdere patiënten tegelijk door te filteren op locatie, datum van de afspraak, zorgverzekeraar en of ze nog openstaande vragenlijsten hebben.</p>Verstuur tot 200 pushberichten tegelijk, direct of plan deze in voor een later moment.<p></p><p>Vind alles wat verstuurd of ingepland is terug bij ‘Verstuurde berichten’, of apart bij de persoonlijke pagina van iedere patiënt.</p>"
        },
        {
            id: 27,
            image: null,
            title: "Nieuw: App accounts aanmaken in het portaal",
            date: "18 juni 2024",
            text: "<p>Het is nu mogelijk om in het portaal, bij de persoonlijke pagina van een patiënt, een app account voor diegene aan te maken. Ook kan een app account van een patiënt juist weer verwijderd worden.</p>"
        },
        {
            id: 28,
            image: "https://placehold.co/600x600",
            title: "Nieuw: het berichtencentrum",
            date: "16 juni 2024",
            text: "<p>Verstuur nu berichten naar meerdere patiënten tegelijk door te filteren op locatie, datum van de afspraak, zorgverzekeraar en of ze nog openstaande vragenlijsten hebben.</p>Verstuur tot 200 pushberichten tegelijk, direct of plan deze in voor een later moment.<p></p><p>Vind alles wat verstuurd of ingepland is terug bij ‘Verstuurde berichten’, of apart bij de persoonlijke pagina van iedere patiënt.</p>"
        },
        {
            id: 29,
            image: null,
            title: "Nieuw: App accounts aanmaken in het portaal",
            date: "18 juni 2024",
            text: "<p>Het is nu mogelijk om in het portaal, bij de persoonlijke pagina van een patiënt, een app account voor diegene aan te maken. Ook kan een app account van een patiënt juist weer verwijderd worden.</p>"
        },
    ],
    limit: 5,
    next: "",
    pages: 5,
    prev: null,
    total: 29,
};

export function WhatsNewButton() {
    const {isOpen, handleOpen, handleClose} = useOpenModal();

    return (
        <>
            <StyledWhatsNewButton onClick={handleOpen}>
                <StyledBell />
                {data?.items?.length > 0 &&
                    <Unread>{data.items.length > 9 ? "9+" : data.items.length}</Unread>
                }
            </StyledWhatsNewButton>

            {data?.items?.length > 0 &&
                <NewsSideDrawer isOpen={isOpen} handleClose={handleClose}>
                    {data.items.map(item => (
                        <Item key={item.id}>
                            {Boolean(item.image) &&
                                <ImageWrapper>
                                    <AspectRatio src={item.image} alt="" />
                                </ImageWrapper>
                            }
                            <ItemContent>
                                <InsideItemContent>
                                    <Heading2 $noMargin>{item.title}</Heading2>
                                    <SubHeading $noMargin>{item.date}</SubHeading>
                                    <StyledHTMLText dangerouslySetInnerHTML={{__html: sanitizeHtml(item.text)}} />
                                </InsideItemContent>
                            </ItemContent>
                        </Item>
                    ))}
                </NewsSideDrawer>
            }
        </>
    );
}