import styled from 'styled-components';
import {CheckboxField} from './CheckboxField';
import {CheckboxesField} from './CheckboxesField';
import {FileUploadField} from './FileUploadField';
import {Heading3} from '../Heading/Heading';
import {LikertScaleField} from './LikertScaleField';
import {LinearSliderField} from './LinearSliderField';
import {MultiTextInputField} from './MultiTextInputField';
import {RadioButtonsField} from './RadioButtonsField';
import ReactMarkdown from 'react-markdown';
import {SelectField} from './SelectField';
import {SmileyButtonsField} from './SmileyButtonsField';
import {TextAreaField} from './TextAreaField';
import {TextBlockField} from './TextBlockField';
import {TextInputField} from './TextInputField';
import {DateField} from "./DateField";
import {Text} from '../Text/Text';
import {MultiFileUploadField} from "./MultiFileUploadField";

const StyledFormFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;
`;

const Label = styled(Heading3).attrs({
    as: "label"
})`
    display: inline-block;
    margin-bottom: 10px;
    
    span {
        color: var(--color-required);
    }
`;

const Description = styled(Text).attrs({
    as: "div"
})`
    // Don't add margin-bottom when TextBlock because TextBlock will add spacing if needed
    ${({$type}) => $type !== "TextBlock" && 'margin-bottom: 20px;'};

    & > *:last-child {
        margin: 0;
    }
`;

export const FormFields = ({ data }) => {
    if(!data) { return null }

    return (
        <StyledFormFields>
            {data?.items?.map((item) => (
                <FormFieldItem key={item.id} item={item} />
            ))}
        </StyledFormFields>
    )
}

const formFields = {
    "TextField": TextInputField,
    "TextArea": TextAreaField,
    "TextBlock": TextBlockField,
    "Checkboxes": CheckboxesField,
    "RadioButtons": RadioButtonsField,
    "SmileyRadioButtons": SmileyButtonsField,
    "LikertRadioButtons" : LikertScaleField,
    "Checkbox": CheckboxField,
    "FileUpload": FileUploadField,
    "MultiFileUpload": MultiFileUploadField,
    "LinearSlider": LinearSliderField,
    "MultiTextField": MultiTextInputField,
    "SelectField": SelectField,
    "DateField": DateField
}

const FormFieldItem = ({ item }) => {
    const FormFieldComponent = formFields[item?.type]

    if (!FormFieldComponent) return null;

    return (
        <div>
            {item.title && <Label>{item.title} {item.required && <span>*</span>}</Label>}
            {item.description && <Description $noMargin>{item.description}</Description>}
            <FormFieldComponent data={item} />
        </div>
    );
}

export const FormField = ({className, type, ...props}) => {
    const FormFieldComponent = formFields[type]

    if (!FormFieldComponent) return null;

    return (
        <div className={className}>
            {props.label && <Label htmlFor={props.id}>{props.label} {props.required && <span>*</span>}</Label>}
            {props.description && <Description $type={type} $noMargin><ReactMarkdown>{props.description}</ReactMarkdown></Description>}
            <FormFieldComponent {...props} />
            <FieldErrors {...props} />
        </div>
    );
}

export const FormFieldWrapper = ({className, children, ...props}) => {
    return (
        <div className={className} {...props}>
            {props.label && <Label htmlFor={props.id}>{props.label} {props.required && <span>*</span>}</Label>}
            {children}
            <FieldErrors {...props} />
        </div>
    );
}

const FieldErrors = ({...props}) => {
    return (
        <>
            {(props.error?.type === 'required' || props.error?.id?.type === 'required') && <Text $error>Dit veld is verplicht</Text>}
            {(props.error?.type === 'maxLength' || props.error?.id?.type === 'maxLength') && <Text $error>Tekst mag niet langer zijn dan {props.maxLength} tekens</Text>}
            {(Boolean(props.error?.message) || Boolean(props.error?.id?.message)) && <Text $error>{props.error?.message}</Text>}
        </>
    );
}