import styled, {css} from "styled-components";
import {Heading2, Heading3} from "../Heading/Heading";
import {Text, truncateMixin} from "../Text/Text";
import {ReactComponent as Upload} from '../../assets/icons/upload.svg';
import {ReactComponent as Trash} from '../../assets/icons/trash.svg';
import {formatBytes} from "../../utils/formatBytes";
import {useEffect, useState} from "react";

const StyledFileUploadField = styled.div``;

const InnerField = styled.label`
    background-color: var(--color-white);
    border: 2px dashed ${({$isDragging}) => $isDragging ? 'var(--color-primary)' : 'var(--color-grey-20)'};
    border-radius: 8px;
    padding: 17px 20px;
    width: ${({$userMayUpload}) => $userMayUpload ? '100%' : '342px'};
    max-width: 100%;
    min-height: ${({$userMayUpload}) => $userMayUpload ? '120px' : '80px'};
    display: flex;
    align-items: center;
    box-shadow: var(--box-shadow);

    ${({$userMayUpload, $hasUrl}) => ($userMayUpload || $hasUrl) && css`
        cursor: pointer;
    `};

    ${({$empty}) => $empty && css`
        justify-content: center;
        align-items: center;
    `};
`;

const FileName2 = styled(Heading2).attrs({
    as: "p",
    $noMargin: true,
})`
    ${truncateMixin};
    margin-bottom: 10px;
`;

const FileName = styled(Heading3).attrs({
    as: "p",
    $noMargin: true,
})`
    ${truncateMixin};
    margin-bottom: 8px;
`;

const FileSize = styled(Text).attrs({
    $noMargin: true,
})``;

const UserMayUpload = styled(Heading2)`
    display: flex;
    align-items: center;
    margin: 0 auto;
    gap: 20px;
    color: var(--color-primary);
`;

const HiddenInput = styled.input`
    display: none;
`;

const IconStyles = css`
    width: 32px;
    height: 32px;
    vertical-align: middle;
    cursor: pointer;
`;

const UploadIcon = styled(Upload)`
    ${IconStyles};
`;

const DeleteIcon = styled(Trash)`
    ${IconStyles};
    flex-shrink: 0;
    position: relative;
    z-index: 1;
`;

const UploadedFilesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 25px;
`

const UploadedFile = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-white);
    border: 2px dashed ${({$isDragging}) => $isDragging ? 'var(--color-primary)' : 'var(--color-grey-20)'};
    border-radius: 8px;
    padding: 17px 20px;
    box-shadow: var(--box-shadow);
`;

export const MultiFileUploadField = ({ data, ...props }) => {
    const userMayUpload = Boolean(props?.userMayUpload);

    if (userMayUpload) {
        return <AddDocumentField data={data} {...props} />;
    }

    const files = data?.answer?.files || [];

    return (
        <StyledFileUploadField title={files.length > 0 ? "Geüploade bestanden" : "Geen bestanden geüpload"}>
            {files.map((file, index) => (
                <div key={index}>
                    <FileName>{file.fileName}</FileName>
                    {file.fileSize && <FileSize>{formatBytes(file.fileSize)}</FileSize>}
                </div>
            ))}
        </StyledFileUploadField>
    );
};

// Actual field used in AddDocument
const AddDocumentField = ({ data, ...props }) => {
    const [files, setFiles] = useState([]);
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        setFiles([]);
        props?.setFileSizeError(false);
        props?.setValue(props?.name, [], { shouldValidate: true });
        props?.setValue("title", "");
        //eslint-disable-next-line
    }, []);

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);

        const droppedFiles = Array.from(e.dataTransfer.files);
        setFiles([...files, ...droppedFiles]);
        props?.setValue(props?.name, [...files, ...droppedFiles]);
        handleUploadFiles(droppedFiles);
    };

    const handleFileInputChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles([...files, ...selectedFiles]);
        props?.setValue(props?.name, [...files, ...selectedFiles]);
        handleUploadFiles(selectedFiles);
    };

    const handleUploadFiles = (uploadedFiles) => {
        props?.setFileSizeError(false);
        uploadedFiles.forEach((file) => {
            if (!props?.watchTitle) props?.setValue("title", file?.name);
            if (file.size > 25000 * 1000) {
                props?.setFileSizeError(true);
            }
        });
    };

    const handleDelete = (index) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
        props?.setValue(props?.name, updatedFiles);
        props?.setFileSizeError(false);
    };

    return (
        <StyledFileUploadField title={files.length > 0 ? "Uploaded Files" : "No files uploaded"}>
            <InnerField
                htmlFor={props.id}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                $isDragging={isDragging}
                $empty={!Boolean(files.length)}
                $userMayUpload
            >
                <UserMayUpload>
                    <UploadIcon /> Upload een of meerdere bestanden
                </UserMayUpload>
                <HiddenInput
                    id={props.id}
                    type="file"
                    multiple={true}
                    {...(props.register &&
                        props.register(props.name, {
                            required: props.required,
                            onBlur: () => {
                                if (props.onFormFieldBlur) props.onFormFieldBlur();
                            },
                            onChange: (e) => handleFileInputChange(e),
                        }))}
                    disabled={props.disabled}
                />
            </InnerField>
            <UploadedFilesContainer>
                {files.map((file, index) => (
                    <UploadedFile key={index}>
                        <div>
                            <FileName2>{file?.name}</FileName2>
                            {file.size && <FileSize>{formatBytes(file.size)}</FileSize>}
                        </div>
                        <DeleteIcon title="Delete file" onClick={() => handleDelete(index)} />
                    </UploadedFile>
                ))}
            </UploadedFilesContainer>
        </StyledFileUploadField>
    );
};