import {useEffect, useState} from 'react';

export const useLocalStorage = (key, options = {}) => {
    const {
        stringify = true,
        type = "string",
        parentKey,
        maxLength
    } = options;

    const [value, setValue] = useState(() => {
        const storedValue = localStorage.getItem(key);
        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);
            return parentKey ? (parsedValue[parentKey] || null) : parsedValue;
        }
        return null;
    });

    useEffect(() => {
        if (value !== null) {
            let updatedStorage;
            if (parentKey) {
                const existingStorage = localStorage.getItem(key);
                updatedStorage = existingStorage ? JSON.parse(existingStorage) : {};

                switch (type) {
                    case 'array': {
                        let newValue = Array.isArray(value) ? value : [value];
                        updatedStorage[parentKey] = newValue;
                        break;
                    }
                    default:
                        updatedStorage[parentKey] = value;
                }
            } else {
                updatedStorage = value;
            }

            localStorage.setItem(key, stringify ? JSON.stringify(updatedStorage) : updatedStorage);
        }
    }, [key, value, stringify, type, parentKey]);

    const setStoredValue = (newValue) => {
        if (type === 'array') {
            setValue((prevValue) => {
                let arrayValue = Array.isArray(prevValue) ? prevValue : [];
                arrayValue = arrayValue.filter(item => item !== newValue); // Remove if it exists in the array
                arrayValue = [newValue, ...arrayValue] // Add the new value, as the first item in the array
                // Make sure the array doesn't exceed the optional maxLength option
                if (maxLength && arrayValue.length > maxLength) {
                    arrayValue = arrayValue.slice(0, maxLength);
                }
                return arrayValue;
            });
        } else {
            setValue(newValue);
        }
    };

    return [value, setStoredValue];
};