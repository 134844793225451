import {DivaGrid} from './DivaFormFields';
import styled, {css} from 'styled-components';
import {FormField} from '../FormFields/FormFields';

const DivaGridItem = styled.div`
    ${({$fullSpan}) => $fullSpan && css`
        grid-column: span 2;
    `};
`;

export const QuestionsGrid = ({data, disabled = false, errors = [], register = undefined, watch = undefined, control = undefined, onFormFieldBlur = undefined, fullSpanIndexes = [], identifier}) => {
    return (
        <>
            <FormField
                key={data.introduction.id}
                type={data.introduction.type}
                id={data.introduction.id}
                label={data.introduction.title}
                required={data.introduction.required}
                description={data.introduction.description}
                disabled={disabled}
                error={errors[data.introduction.id]}
                register={register}
                watch={watch}
                control={control}
                onFormFieldBlur={onFormFieldBlur}
                name={data.introduction.id}
                data={data.introduction}
                identifier={identifier}
            />

            <DivaGrid>
                {data.questions.map((item, index) => (
                    <DivaGridItem key={item.id} $fullSpan={fullSpanIndexes.includes(index)}>
                        <FormField
                            type={item.type}
                            id={item.id}
                            label={item.title}
                            required={item.required}
                            description={item.description}
                            disabled={disabled}
                            error={errors[item.id]}
                            register={register}
                            watch={watch}
                            control={control}
                            onFormFieldBlur={onFormFieldBlur}
                            name={item.id}
                            data={item}
                            identifier={identifier}
                        />
                    </DivaGridItem>
                ))}
            </DivaGrid>
        </>
    )
}