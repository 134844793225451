import styled from 'styled-components';
import {Heading2, SubHeading} from '../Heading/Heading';
import {ReactComponent as LogoutIcon} from '../../assets/icons/logout.svg';
import {LogoutModal} from '../Modal/LogoutModal';
import {useRef, useState} from 'react';
import {Text} from '../Text/Text';
import {Button} from '../Button/Button';
import Cookies from 'js-cookie';
import {useLocation, useNavigate} from 'react-router-dom';
import {ConditionalWrap} from '../Utils/Utils';
import Tooltip from '../Tooltip/Tooltip';
import {NavItemLabel} from '../SideBar/NavItem';
import {resetRootLoaderPromise} from '../../routes/Root/Root';

const StyledLogoutButton = styled(SubHeading).attrs({
    as: "button"
})`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 18px;
    border-radius: 8px;
    padding: 12px 14px;
    background: transparent;
    border: none;
    width: 100%;
    cursor: pointer;

    &:hover { background-color: var(--color-aside-item-hover); }
`;

const StyledLogoutIcon = styled(LogoutIcon)`
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    
    path {
        fill: var(--color-primary);
    }
`;

const Inner = styled.div`
    pointer-events: initial;
    position: relative;
    width: 100%;
    max-width: 299px;
    max-height: 100%;
    overflow-y: auto;
    background-color: var(--color-content-background);
    box-shadow: var(--box-shadow);
    border-radius: 20px;
    padding: 20px;
`;

const LogoutTitle = styled(Heading2)`
    margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
    border: none;
    width: 100%;
    margin-top: 20px;
`;

const CancelButton = styled.button`
    background-color: transparent;
    color: var(--color-primary);
    text-align: center;
    cursor: pointer;
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    text-decoration: none;
    line-height: 29px;
    border: none;
    width: 100%;
    margin-top: 10px;
    
    &:hover {
        color: var(--color-primary-hover);
    }
`;

export const LogoutButton = ({expanded}) => {
    const [isOpen, setIsOpen] = useState(false);
    const touchActionStyle = useRef("");
    const overflowStyle = useRef("");
    const navigate = useNavigate();
    const location = useLocation();

    const openLogoutModal = () => {
        setIsOpen(true);
        touchActionStyle.current = document.body.style.touchAction;
        document.body.style.touchAction = "none";
        overflowStyle.current = document.body.style.overflow;
        document.body.style.overflow = "hidden";
    }

    const closeLogoutModal = () => {
        setIsOpen(false);
        document.body.style.touchAction = touchActionStyle.current;
        document.body.style.overflow = overflowStyle.current;
    }

    const handleLogout = () => {
        Cookies.remove('adhdcentraal-portal_token');
        Cookies.remove('adhdcentraal-portal_expiration');

        resetRootLoaderPromise();

        navigate("/login", { replace: true, state : { from: location, reason: "manually" } })
    }

    return (
        <>
            <ConditionalWrap condition={!expanded} wrap={children => <Tooltip content="Uitloggen" placement={'top'}>{children}</Tooltip>}>
                <StyledLogoutButton onClick={openLogoutModal}>
                    <StyledLogoutIcon />

                    {expanded &&
                        <NavItemLabel>
                            Uitloggen
                        </NavItemLabel>
                    }
                </StyledLogoutButton>
            </ConditionalWrap>

            <LogoutModal isOpen={isOpen} closeLogoutModal={closeLogoutModal}>
                <Inner>
                    <LogoutTitle $noMargin $align="center">Uitloggen</LogoutTitle>
                    <Text $noMargin $align="center">Weet je zeker dat je nu wil uitloggen?</Text>
                    <StyledButton type="button" onClick={handleLogout}>Ja, uitloggen</StyledButton>
                    <CancelButton onClick={closeLogoutModal}>Annuleer</CancelButton>
                </Inner>
            </LogoutModal>
        </>
    );
}