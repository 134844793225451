import styled from 'styled-components';
import {Input} from '../Input/Input';
import {useState} from "react";
import useAutoSizeTextArea from "../../hooks/useAutoSizeTextArea";

const StyledTextAreaField = styled(Input).attrs({
    as: "textarea"
})`
    resize: none;
    overflow-y: hidden;
    min-height: 7em;
    white-space: pre-line;
`;
export const TextAreaField = ({ className, data, ...props }) => {
    const [value, setValue] = useState("");

    useAutoSizeTextArea({ id: props.id, value });

    const handleChange = (evt) => {
        const val = evt.target.value;
        setValue(val);
    };

    return (
        <StyledTextAreaField
            className={className}
            id={props.id}
            placeholder={props.placeholder}
            defaultValue={data?.answer}
            {...(props.register && props.register(props.name, {
                required: props.required,
                ...(props.maxLength && {maxLength: props.maxLength}),
                onBlur: () => { if (props.onFormFieldBlur) props.onFormFieldBlur() }
            }))}
            disabled={props.disabled}
            onInput={handleChange}
        />
    );
}