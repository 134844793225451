import styled from 'styled-components';
import {Table, TableHead, TableHeadCell, TableHeadRow, TableRow, TableRowCell, TableRowInner} from '../Table/Table';

const StyledDivaSummaryTable = styled.div`
    margin-top: 50px;
`;

export const DivaSummaryTable = ({summaryPart1, summaryPart2, disabled}) => {
    return (
        <StyledDivaSummaryTable>
            <Table>
                <TableHead>
                    <TableHeadRow>
                        <TableHeadCell $flexGrow={undefined} $minWidth="unset">Criterium<br/>DSM-5</TableHeadCell>
                        <TableHeadCell $flexGrow={3} $minWidth="unset">Symptoom</TableHeadCell>
                        <TableHeadCell $flexGrow={undefined} $minWidth="unset">Aanwezig volwassen&shy;heid</TableHeadCell>
                        <TableHeadCell $flexGrow={undefined} $minWidth="unset">Aanwezig kinder&shy;tijd</TableHeadCell>
                    </TableHeadRow>
                </TableHead>

                {/* Part 1 */}
                {summaryPart1.map((item, index) => (
                    <TableRow key={item.id}>
                        <TableRowInner $isOddRow={index % 2 === 0} $disabled={disabled}>
                            <TableRowCell $variant="title" $flexGrow={undefined} $minWidth="unset">{item.criterion}</TableRowCell>
                            <TableRowCell $flexGrow={3} $minWidth="unset">{item.symptom}</TableRowCell>
                            <TableRowCell $flexGrow={undefined} $minWidth="unset">{item.presentMaturity}</TableRowCell>
                            <TableRowCell $flexGrow={undefined} $minWidth="unset">{item.presentChildhood}</TableRowCell>
                        </TableRowInner>
                    </TableRow>
                ))}
                <TableRow>
                    <TableRowInner>
                        <TableRowCell $variant="total" $flexGrow={3} $minWidth="unset">Totaal aantal criteria Aandachtsdeficiëntie</TableRowCell>
                        <TableRowCell $variant="total" $flexGrow={undefined} $minWidth="unset"></TableRowCell>
                        <TableRowCell $variant="total" $flexGrow={undefined} $minWidth="unset">{summaryPart1?.filter(item => item.presentMaturity === "Ja")?.length} / 9</TableRowCell>
                        <TableRowCell $variant="total" $flexGrow={undefined} $minWidth="unset">{summaryPart1?.filter(item => item.presentChildhood === "Ja")?.length} / 9</TableRowCell>
                    </TableRowInner>
                </TableRow>

                {/* Part 2 */}
                {summaryPart2.map((item, index) => (
                    <TableRow key={item.id}>
                        <TableRowInner $isOddRow={index % 2 === 0} $disabled={disabled}>
                            <TableRowCell $variant="title" $flexGrow={undefined} $minWidth="unset">{item.criterion}</TableRowCell>
                            <TableRowCell $flexGrow={3} $minWidth="unset">{item.symptom}</TableRowCell>
                            <TableRowCell $flexGrow={undefined} $minWidth="unset">{item.presentMaturity}</TableRowCell>
                            <TableRowCell $flexGrow={undefined} $minWidth="unset">{item.presentChildhood}</TableRowCell>
                        </TableRowInner>
                    </TableRow>
                ))}
                <TableRow>
                    <TableRowInner>
                        <TableRowCell $variant="total" $flexGrow={3} $minWidth="unset">Totaal aantal criteria Hyperactiviteit/Impulsiviteit</TableRowCell>
                        <TableRowCell $variant="total" $flexGrow={undefined} $minWidth="unset"></TableRowCell>
                        <TableRowCell $variant="total" $flexGrow={undefined} $minWidth="unset">{summaryPart2?.filter(item => item.presentMaturity === "Ja")?.length} / 9</TableRowCell>
                        <TableRowCell $variant="total" $flexGrow={undefined} $minWidth="unset">{summaryPart2?.filter(item => item.presentChildhood === "Ja")?.length} / 9</TableRowCell>
                    </TableRowInner>
                </TableRow>
            </Table>
        </StyledDivaSummaryTable>
    )
}