import {Text} from "../../components/Text/Text";
import React, {useState} from "react";
import {Permissions} from "../../constants/enums";
import {Spinner, SpinnerOverlay, SpinnerWrapper} from "../../components/Spinner/Spinner";
import PageHeadingContainer from "../../components/PageHeadingContainer/PageHeadingContainer";
import {Heading1} from "../../components/Heading/Heading";
import {Table, TableHead, TableHeadCell, TableHeadRow, TableRow, TableRowCell, TableRowInner} from "../../components/Table/Table";
import Pagination from "../../components/Pagination";
import {Checkbox} from "../../components/Input/Input";
import styled from "styled-components";
import {Await, defer, Link, useAsyncValue, useLoaderData, useNavigation} from "react-router-dom";
import dayjs from "dayjs";
import {AddNotificationGroup} from "./Partials/AddNotificationGroup";
import {fetchProtectedData, postData} from "../../api/fetch";
import {FilterButton} from './Partials/FilterButton';
import qs from 'qs';
import {AppliedFilterList} from '../../components/AppliedFilters/AppliedFilterList';
import {checkRequiredAccount} from '../Root/Root';
import Cookies from 'js-cookie';

const PatientLink = styled(Link)`
    color: var(--color-blue-50);
    font-weight: var(--fw-bold);
    text-decoration: underline;
    cursor: pointer;
`;

const PageHeadingInnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Buttons = styled.div`
    display: flex;
    gap: 20px;
`;

const RelativeWrapper = styled.div`
    position: relative;
`;

export async function notificationCenterAction({request}) {
    let formData = await request.json()
    let intent = formData.intent

    switch (intent) {
        case "add-notification-group": {
            delete formData.intent
            return await postData(request, `group-notification`, JSON.stringify(formData))
        }
        default:
            return {"default": true}
    }
}

export async function notificationCenterLoader({request}) {
    await checkRequiredAccount([Permissions.PORTAL_ACCESS_NOTIFICATION_CENTER_OVERVIEW, Permissions.NOTIFICATION_VIEW]);

    const url = new URL(request.url);
    const searchParams = new URLSearchParams(url?.search)

    const page = searchParams.get("page") ?? 1;
    const nextAppointmentFrom = searchParams.get("nextAppointmentFrom") ?? null;
    const nextAppointmentUntil = searchParams.get("nextAppointmentUntil") ?? null;
    const healthInsurance = searchParams.get("healthInsurance") ?? null;
    const hasUncompletedQuestionnaires = searchParams.get("hasUncompletedQuestionnaires") ?? null;
    const location = Cookies.get('locationFilter');

    const queryString = qs.stringify({
        ...(page && {page}),
        ...(nextAppointmentFrom && {nextAppointmentFrom}),
        ...(nextAppointmentUntil && {nextAppointmentUntil}),
        ...(healthInsurance && {healthInsurance}),
        ...(hasUncompletedQuestionnaires && {hasUncompletedQuestionnaires}),
        ...(location && {location}),
    });

    const notificationCenterPromise = fetchProtectedData(request,`group-notification/search${queryString ? `?${queryString}` : ""}`);
    const healthInsuranceData = await fetchProtectedData(request, 'health-insurance');

    return defer({notificationCenterPromise, healthInsuranceData})
}

const NotificationCenter = () => {
    const {notificationCenterPromise, healthInsuranceData} = useLoaderData();
    const [selected, setSelected] = useState([]); // Lifted state to store checkboxes

    return (
        <>
            <PageHeadingContainer $flexDirection="column" $alignItems='start'>
                <PageHeadingInnerContainer>
                    <Heading1>Berichtencentrum</Heading1>
                    <Buttons>
                        <AddNotificationGroup
                            selected={selected}
                            onAfterSubmit={() => setSelected([])}
                        />
                        <FilterButton
                            healthInsuranceData={healthInsuranceData}
                            onAfterSubmit={() => setSelected([])}
                        />
                    </Buttons>
                </PageHeadingInnerContainer>
                <AppliedFilterList healthInsuranceData={healthInsuranceData} />
            </PageHeadingContainer>

            <React.Suspense fallback={
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            }>
                <Await resolve={notificationCenterPromise} errorElement={<Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}>
                    <DeferredNotificationCenter selected={selected} setSelected={setSelected} />
                </Await>
            </React.Suspense>
        </>
    )
}

function DeferredNotificationCenter({selected, setSelected}) {
    const notificationCenterData = useAsyncValue();
    const navigation = useNavigation();
    const isPending = navigation?.location?.pathname?.includes("/berichtencentrum");

    // Handle the checkboxes and select all
    const handleSelectAllOnPage = ({ target: { checked }}) => {
        let _selected = Object.assign([], selected);

        if (checked) {
            notificationCenterData?.items?.forEach((item) => {
                if (!_selected.includes(item.id)) {
                    _selected.push(item.id)
                }
            })
        } else {
            const ids = notificationCenterData?.items?.map(i => i.id)
            notificationCenterData?.items?.forEach((item) => {
                _selected = _selected.filter(s => !ids.includes(s))
            })
        }

        setSelected(_selected)
    }

    const selectedAll = () => {
        return notificationCenterData?.items?.map(i => i.id).every(s => selected.includes(s))
    }

    const toggleSelected = (id) => {
        if (!selected.includes(id)) {
            setSelected([...selected, id])
        } else {
            setSelected(selected.filter(s => s !== id))
        }
    }

    return (
        <>
            <RelativeWrapper>
                {notificationCenterData?.items?.length > 0 ? (
                    <>
                        <Pagination searchResults={notificationCenterData} $position="top"/>
                        <Table>
                            <TableHead>
                                <TableHeadRow>
                                    <TableHeadCell $flex="0">
                                        <Checkbox type='checkbox' checked={selectedAll()} onChange={handleSelectAllOnPage} />
                                    </TableHeadCell>
                                    <TableHeadCell>Naam patient</TableHeadCell>
                                    <TableHeadCell>Locatie</TableHeadCell>
                                    <TableHeadCell>Zorgverzekeraar</TableHeadCell>
                                    <TableHeadCell>Datum diagnostiekdag</TableHeadCell>
                                    <TableHeadCell>Openstaande vragenlijsten?</TableHeadCell>
                                </TableHeadRow>
                            </TableHead>
                            {notificationCenterData?.items?.map((item, rowIndex) => {
                                return (
                                    <TableRow key={`row-${rowIndex}`}>
                                        <TableRowInner $isOddRow={rowIndex % 2 === 0}>
                                            <TableRowCell $flex="0">
                                                <Checkbox type='checkbox' checked={selected.includes(item.id)} onChange={() => toggleSelected(item.id)} />
                                            </TableRowCell>
                                            <TableRowCell>
                                                <PatientLink to={{pathname: `/patient/${item.id}`}}>
                                                    { item.name }
                                                </PatientLink>
                                            </TableRowCell>
                                            <TableRowCell>{item.location?.[0]}</TableRowCell>
                                            <TableRowCell>{item.healthInsurance}</TableRowCell>
                                            <TableRowCell>
                                                {item.nextAppointment &&
                                                    dayjs(item.nextAppointment?.internalDate).format("DD-MM-YYYY")
                                                }
                                            </TableRowCell>
                                            <TableRowCell>{
                                                item.uncompletedQuestionnaires?.length > 0 ? 'Ja' : 'Nee'
                                            }</TableRowCell>
                                        </TableRowInner>
                                    </TableRow>
                                );
                            })}
                        </Table>
                        <Pagination searchResults={notificationCenterData} />
                    </>
                ) : (
                    <Text>Geen resultaten gevonden</Text>
                )}

                {isPending &&
                    <SpinnerOverlay>
                        <SpinnerWrapper>
                            <Spinner />
                        </SpinnerWrapper>
                    </SpinnerOverlay>
                }
            </RelativeWrapper>
        </>
    );
}

export default NotificationCenter