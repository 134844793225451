import React from "react";
import {checkRequiredAccount} from "../Root/Root";
import {fetchProtectedData} from "../../api/fetch";
import styled from "styled-components";
import {FormField} from "../../components/FormFields/FormFields";
import {Await, defer, useLoaderData, useNavigation, useRouteLoaderData} from "react-router-dom";
import dayjs from "dayjs";
import PageHeadingContainer from "../../components/PageHeadingContainer/PageHeadingContainer";
import {Heading1} from "../../components/Heading/Heading";
import {Table, TableHeadCell, TableHeadRow, TableRow, TableRowCell, TableRowInner} from "../../components/Table/Table";
import Pagination from "../../components/Pagination";
import {BackButton} from "../../components/BackButton/BackButton";
import {UsedFiltersList} from '../../components/AppliedFilters/UsedFiltersList';
import {Spinner, SpinnerOverlay, SpinnerWrapper} from '../../components/Spinner/Spinner';
import qs from 'qs';
import {Text} from '../../components/Text/Text';
import {Permissions} from '../../constants/enums';

const NotificationDetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    margin-bottom: 48px;
`;

const RelativeWrapper = styled.div`
    position: relative;
`;

export async function sentGroupNotificationLoader({ request, params}) {
    await checkRequiredAccount([Permissions.PORTAL_ACCESS_NOTIFICATION_CENTER_OVERVIEW, Permissions.NOTIFICATION_READ]);

    const url = new URL(request.url);
    const searchParams = new URLSearchParams(url?.search)
    const page = searchParams.get("page") ?? 1;

    const queryString = qs.stringify({
        ...(page && {page}),
    });

    const sentGroupNotificationPromise = fetchProtectedData(request,`group-notification/${params.groupNotificationUUID}/notifications${queryString ? `?${queryString}` : ""}`);
    const groupNotificationPromise = fetchProtectedData(request, `group-notification/${params.groupNotificationUUID}`)
    const healthInsurancePromise = fetchProtectedData(request, 'health-insurance');
    const [groupNotificationData, healthInsuranceData] = await Promise.all([groupNotificationPromise, healthInsurancePromise]);

    return defer({sentGroupNotificationPromise, groupNotificationData, healthInsuranceData});
}

const SentGroupNotification = () => {
    const {locationData} = useRouteLoaderData("portal");
    const { sentGroupNotificationPromise, groupNotificationData: data, healthInsuranceData } = useLoaderData();

    const navigation = useNavigation();
    const isPending = navigation?.location?.pathname?.includes("/berichtencentrum/verstuurde-berichten");

    return (
        <>
            <BackButton to="/berichtencentrum/verstuurde-berichten" />
            <NotificationDetailsContainer>
                <FormField type='TextField' label="Datum" disabled data={{answer: dayjs(data.sendAt).format('DD-MM-YYYY HH:mm')}} />
                <FormField type='TextField' label="Status" disabled data={{answer: data.sentAt ? 'Verstuurd' : 'Ingepland'}} />
                <FormField type='TextField' label="Titel" disabled data={{answer: data.title}} />
                <FormField type='TextArea' label="Bericht" disabled data={{answer: data.text}}/>
            </NotificationDetailsContainer>
            <PageHeadingContainer $flexDirection='column' $alignItems='start' >
                <Heading1>Pushbericht naar {data.count} patiënten</Heading1>
                <UsedFiltersList locationData={locationData} healthInsuranceData={healthInsuranceData} usedFilters={data.usedFilters} />
            </PageHeadingContainer>

            <React.Suspense fallback={
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            }>
                <Await resolve={sentGroupNotificationPromise} errorElement={<Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}>
                    {(sentGroupNotificationData) => {
                        return (
                            <RelativeWrapper>
                                {sentGroupNotificationData?.items?.length > 0 ? (
                                    <>
                                        <Pagination searchResults={sentGroupNotificationData} $position="top" />
                                        <Table>
                                            <TableHeadRow>
                                                <TableHeadCell>Naam patiënt</TableHeadCell>
                                                <TableHeadCell>Locatie</TableHeadCell>
                                                <TableHeadCell>Zorgverzekeraar</TableHeadCell>
                                                <TableHeadCell>Volgende afspraak</TableHeadCell>
                                                <TableHeadCell>Volgende deadline</TableHeadCell>
                                                <TableHeadCell>Openstaande vragenlijsten?</TableHeadCell>
                                            </TableHeadRow>
                                            {sentGroupNotificationData?.items?.map((item, rowIndex) => {
                                                return (
                                                    <TableRow key={`row-${rowIndex}`}>
                                                        <TableRowInner $isOddRow={rowIndex % 2 === 0}>
                                                            <TableRowCell><strong>{ item.patient.name }</strong></TableRowCell>
                                                            <TableRowCell>{ item.patient.location[0] }</TableRowCell>
                                                            <TableRowCell>{ item.patient.healthInsurance }</TableRowCell>
                                                            <TableRowCell>{ item.patient.nextAppointment?.internalDate }</TableRowCell>
                                                            <TableRowCell>{ item.patient.name }</TableRowCell>
                                                            <TableRowCell>{ item.patient.uncompletedQuestionnaires?.length ? 'Ja' : 'Nee' }</TableRowCell>
                                                        </TableRowInner>
                                                    </TableRow>
                                                );
                                            })}
                                        </Table>
                                        <Pagination searchResults={sentGroupNotificationData} />
                                    </>
                                ) : (
                                    <Text>Geen resultaten gevonden</Text>
                                )}

                                {isPending &&
                                    <SpinnerOverlay>
                                        <SpinnerWrapper>
                                            <Spinner />
                                        </SpinnerWrapper>
                                    </SpinnerOverlay>
                                }
                            </RelativeWrapper>
                        );
                    }}
                </Await>
            </React.Suspense>
        </>
    )
}

export default SentGroupNotification