import styled from 'styled-components';

const StyledLikertScaleField = styled.div`
    max-width: fit-content;
`;

const Options = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
`;

const OptionInput = styled.input`
    position: fixed;
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
    
    &:not(:disabled):checked + span {
        background-color: var(--color-primary);
        color: var(--color-white);
        border: 1px solid transparent;
    }

    &:disabled:checked + span {
        background-color: var(--color-text-alpha-50);
        color: var(--color-white);
        border: 1px solid transparent;
    }
`;

const OptionText = styled.span`
    font-size: var(--fs-likert-text);
    color: ${({$disabled}) => $disabled ? 'var(--color-text-alpha-50)' : 'var(--color-primary)'};
    line-height: 16px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: ${({$options}) => $options >= 5 ? '7px 20px' : '7px 31px'};
    border: 1px solid ${({$disabled}) => $disabled ? 'var(--color-text-alpha-50)' : 'var(--color-primary)'};
`;

export const LikertScaleField = ({data, ...props}) => {
    return (
        <StyledLikertScaleField>
            <Options>
                {data?.options?.map((option, index) => {
                    // option.id is not the unique ID, and value is also not unique
                    let optionId = `${data.id}-${option.id}-${option.title}-${index}`;

                    return (
                        <label key={optionId} htmlFor={optionId}>
                            <OptionInput
                                id={optionId}
                                type="radio"
                                defaultChecked={data.answer === option.id}
                                value={option.id}
                                {...(props.register && props.register(props.name, {
                                    required: props.required,
                                    onBlur: () => {
                                        if (props.onFormFieldBlur) props.onFormFieldBlur()
                                    }
                                }))}
                                disabled={props.disabled}
                            />
                            <OptionText $options={data?.options?.length} $disabled={props.disabled}>{option.title}</OptionText>
                        </label>
                    )
                })}
            </Options>
        </StyledLikertScaleField>
    );
}