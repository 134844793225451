import {Table, TableHead, TableHeadCell, TableHeadCellSubtitle, TableHeadRow, TableRow, TableRowCell, TableRowInner} from '../../../components/Table/Table';
import {ConditionalWrap} from '../../../components/Utils/Utils';
import React from 'react';
import styled from 'styled-components';
import {CustomLink} from '../../../components/CustomLink/CustomLink';
import {useParams} from 'react-router-dom';

const StyledTable = styled(Table)`
    margin-top: 60px;
`;

const StyledCustomLink = styled(CustomLink)`
    text-decoration: underline;
    color: inherit;
    
    &:hover {
        text-decoration: none;
    }
`;

export const ResultTable = ({data, resultType}) => {
    const {patientUUID} = useParams();
    const isDivaResult = resultType === "DivaResult";
    const isCaarsFellowResult = resultType === "CaarsFellowResult";

    return (
        <StyledTable>
            <TableHead>
                <TableHeadRow>
                    {data?.head?.[0]?.cells?.map((item, index) => (
                        <TableHeadCell
                            key={`${index}-${item?.title}`}
                            $flexGrow={(!isDivaResult && !isCaarsFellowResult && index === 0) ? 3 : undefined}
                            $minWidth="unset"
                            $isGrayedOut={item?.isGrayedOut}
                        >
                            <ConditionalWrap condition={Boolean(item.id)} wrap={children => <StyledCustomLink to={`/patient/${patientUUID}/vragenlijst/${item.id}`}>{children}</StyledCustomLink>}>
                                {item?.title}
                            </ConditionalWrap>

                            {item?.subtitle &&
                                <TableHeadCellSubtitle $isGrayedOut={item?.isGrayedOut}>{item.subtitle}</TableHeadCellSubtitle>
                            }
                        </TableHeadCell>
                    ))}
                </TableHeadRow>
            </TableHead>

            {data?.body?.map((item, rowIndex, {length}) => {
                const key = `${rowIndex}-${item?.cells?.[0]}`;
                const isOddRow = rowIndex % 2 === 0;

                return (
                    <TableRow key={key}>
                        <TableRowInner $isOddRow={isOddRow}>
                            {item?.cells?.map((cell, cellIndex) => (
                                <TableRowCell
                                    key={`${cellIndex}-${cell?.content}`}
                                    $variant={(!isDivaResult && !isCaarsFellowResult && length > 1 && rowIndex === length - 1) ? "total" : cellIndex === 0 ? "title" : undefined}
                                    $flexGrow={(!isDivaResult && !isCaarsFellowResult && cellIndex === 0) ? 3 : undefined}
                                    $minWidth="unset"
                                    $isGrayedOut={cell?.isGrayedOut}
                                >
                                    {cell?.content}
                                </TableRowCell>
                            ))}
                        </TableRowInner>
                    </TableRow>
                );
            })}

            {data?.total &&
                <TableRow as="div">
                    <TableRowInner $isOddRow={data?.categories?.length % 2 === 0}>
                        <TableRowCell $title $flexGrow={3} $minWidth="unset">{data?.total?.totalTitle}</TableRowCell>
                        <TableRowCell $variant="title" $minWidth="unset">{data?.total?.totalQuestions}</TableRowCell>
                        <TableRowCell $variant="title" $minWidth="unset">{data?.total?.totalScore}</TableRowCell>
                        <TableRowCell $variant="title" $minWidth="unset">{data?.total?.yourTotalScore}</TableRowCell>
                    </TableRowInner>
                </TableRow>
            }
        </StyledTable>
    );
}