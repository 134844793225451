import {createContext, useContext} from 'react';
import {useCookie} from '../hooks/useCookie';

const FilterContext = createContext(null);

export const FilterProvider = ({ children }) => {
    const [locationFilter, setLocationFilter] = useCookie("locationFilter");

    return (
        <FilterContext.Provider value={{
            locationFilter, setLocationFilter,
        }}>
            {children}
        </FilterContext.Provider>
    );
};

export const useFilterContext = () => useContext(FilterContext);