import {Button, buttonVariants} from "../../../components/Button/Button";
import {useOpenModal} from "../../../hooks/useOpenModal";
import ConfirmationModal, {CancelButton} from "../../../components/Modal/ConfirmationModal";
import {Text} from "../../../components/Text/Text";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Statuses} from "../../../constants/enums";
import {useFetcher, useLoaderData} from "react-router-dom";
import FormModal from '../../../components/Modal/FormModal';
import {useForm} from 'react-hook-form';
import {FormField} from '../../../components/FormFields/FormFields';
import {EmailPreview} from './EmailPreview';

const Box = styled.div`
    flex: 1 1 0;
`;

const StyledButton = styled(Button)`
    ${({$variant}) => buttonVariants($variant)};
    border: none;
    width: 100%;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const AddReservationInvite = ({ selected, onAfterSubmit }) => {
    const {emailTextData} = useLoaderData();
    const {isOpen, handleOpen, handleClose} = useOpenModal()
    const {isOpen: confirmIsOpen, handleOpen: handleConfirmOpen, handleClose: handleConfirmClose} = useOpenModal()

    const fetcher = useFetcher();
    const [status, setStatus] = useState(Statuses.IDLE);
    const [error, setError] = useState(false);

    const {register, watch, reset, handleSubmit, trigger, formState: { errors }, clearErrors} = useForm({
        defaultValues: {
            intent: "add-reservation-invite",
            customTextNL: emailTextData.customTextNL,
            customTextEN: emailTextData.customTextEN
        }
    })

    const watchCustomTextNL = watch("customTextNL", emailTextData.customTextNL);
    const watchCustomTextEN = watch("customTextEN", emailTextData.customTextEN);

    const confirm = async () => {
        if (await trigger()) {
            handleConfirmOpen()
        }
    }

    const onSubmit = (values) => {
        clearErrors();

        setStatus(Statuses.SUBMITTING)
        setError(false)

        const data = {
            intent: values.intent,
            sendTo: selected,
            customTextNL: values.customTextNL,
            customTextEN: values.customTextEN
        }

        fetcher.submit(data, { method: "POST", encType: "application/json", action: "/reservation/waiting-list"})
    }

    // Fetcher callback
    useEffect(() => {
        if(fetcher?.state === "idle") {
            if(fetcher?.data?.error) {
                setStatus(Statuses.IDLE);
                return setError(true);
            }

            if(fetcher?.data || fetcher?.data === "") {
                setStatus(Statuses.SUCCESS);
                handleConfirmClose();
                handleClose();
            }
        }
        //eslint-disable-next-line
    }, [fetcher]);

    const handleOnExited = () => {
        reset();
        onAfterSubmit();
        setStatus(Statuses.IDLE);
    }

    const disabled = status === Statuses.SUBMITTING;

    return <>
        <Button type='button' disabled={!selected?.length} onClick={handleOpen}>
            Uitnodiging versturen ({ selected?.length ?? 0 })
        </Button>

        <FormModal isOpen={isOpen} handleClose={handleClose}>
            <FormModal.Header handleClose={handleClose}>Uitnodiging versturen</FormModal.Header>

            <Form id="add-reservation-invite-form" onSubmit={handleSubmit(onSubmit)}>
                <FormField
                    type="TextArea"
                    id="customTextNL"
                    placeholder={emailTextData.customTextNL}
                    label="Tekst"
                    required={true}
                    error={errors.customTextNL}
                    register={register}
                    name="customTextNL"
                />

                <FormField
                    type="TextArea"
                    id="customTextEN"
                    placeholder={emailTextData.customTextEN}
                    label="Tekst (Engels)"
                    required={true}
                    error={errors.customTextEN}
                    register={register}
                    name="customTextEN"
                />

                <EmailPreview watchCustomTextNL={watchCustomTextNL} watchCustomTextEN={watchCustomTextEN} />
            </Form>

            {error && <Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}

            <FormModal.Footer>
                <Box><StyledButton type="button" onClick={handleClose} $variant="secondary">Annuleren</StyledButton></Box>
                <Box><StyledButton type="button" onClick={confirm} disabled={disabled} loading={disabled}>Versturen</StyledButton></Box>
            </FormModal.Footer>

            <ConfirmationModal isOpen={confirmIsOpen} handleOpen={handleConfirmOpen} handleClose={handleConfirmClose} onExited={status === Statuses.SUCCESS ? handleOnExited : undefined}>
                <ConfirmationModal.Header>Uitnodiging versturen</ConfirmationModal.Header>
                <Text $align="center">
                    Weet je zeker dat je de uitnodiging naar <strong>{ selected?.length ?? 0 } patiënten</strong> wil versturen? Je kunt dit niet meer ongedaan maken.
                </Text>
                <StyledButton type="submit" form="add-reservation-invite-form" disabled={disabled} loading={disabled}>Ja, versturen</StyledButton>
                <CancelButton onClick={handleConfirmClose}>Annuleer</CancelButton>
                {error && <Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}
            </ConfirmationModal>
        </FormModal>
    </>
}