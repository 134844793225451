export const weekdayOptions = [
    { value: "0", label: "Maandag" },
    { value: "1", label: "Dinsdag" },
    { value: "2", label: "Woensdag" },
    { value: "3", label: "Donderdag" },
    { value: "4", label: "Vrijdag" },
    { value: "5", label: "Zaterdag" },
    { value: "6", label: "Zondag" },
];

export const monthOptions = [
    { value: "1", label: "Januari" },
    { value: "2", label: "Februari" },
    { value: "3", label: "Maart" },
    { value: "4", label: "April" },
    { value: "5", label: "Mei" },
    { value: "6", label: "Juni" },
    { value: "7", label: "Juli" },
    { value: "8", label: "Augustus" },
    { value: "9", label: "September" },
    { value: "10", label: "Oktober" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
];