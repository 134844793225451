import styled from 'styled-components';
import {truncateMixin} from '../Text/Text';

export const Heading1 = styled.h1`
    color: ${({$white}) => $white ? 'var(--color-white)' : 'var(--color-heading)'};
    font-weight: var(--fw-extra-bold);
    font-size: var(--fs-heading-1);
    line-height: 1.25;
    ${({$noMargin}) => $noMargin && 'margin: 0;'};
    overflow-wrap: anywhere;
    text-align: ${({$align}) => $align === "center" ? "center" : "left"};
    ${({ $truncate }) => $truncate && truncateMixin};
`;

export const Heading2 = styled(Heading1).attrs({
    as: "h2"
})`
    font-size: var(--fs-heading-2);
`;

export const Heading3 = styled(Heading1).attrs({
    as: "h3"
})`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-3);
`;

export const SubHeading = styled(Heading1).attrs({
    as: "p"
})`
    font-weight: var(--fw-regular);
    font-size: var(--fs-sub-heading);
`;