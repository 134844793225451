import {Button, buttonVariants} from '../../../components/Button/Button';
import React, {useEffect, useState} from 'react';
import FormModal from '../../../components/Modal/FormModal';
import styled from 'styled-components';
import {useOpenModal} from '../../../hooks/useOpenModal';
import {useForm} from 'react-hook-form';
import {FormField} from '../../../components/FormFields/FormFields';
import {useFetcher, useParams} from 'react-router-dom';
import {Text} from '../../../components/Text/Text';
import {Statuses} from '../../../constants/enums';

const Box = styled.div`
    flex: 1 1 0;
`;

const StyledButton = styled(Button)`
    ${({$variant}) => buttonVariants($variant)};
    border: none;
    width: 100%;
`;

const FileUploadFieldBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
`;

export const AddDocument = () => {
    const {isOpen, handleOpen, handleClose} = useOpenModal();
    const {patientUUID} = useParams();

    const {register, handleSubmit, formState: { errors }, setValue, watch, setError: setFormFieldError, clearErrors} = useForm({
        defaultValues: {
            intent: "add-document",
        }
    });

    const watchTitle = watch('title');

    const fetcher = useFetcher();
    const [status, setStatus] = useState(Statuses.IDLE);
    const [error, setError] = useState(false);
    const [fileSizeError, setFileSizeError] = useState(false);

    const onSubmit = (data) => {
        clearErrors();

        if (!data.file.length) return setFormFieldError("file");
        if (fileSizeError) return setFormFieldError("file", { type: 'custom', message: 'Het bestand mag maximaal 25MB zijn' });

        setStatus(Statuses.SUBMITTING);
        setError(false);

        const formData = new FormData();
        formData.append("intent", data.intent);

        data.file.forEach((file) => {
            formData.append(`file[]`, file);
        });

        return fetcher.submit(formData, {
            method: "POST",
            encType: "multipart/form-data",
            action: `/patient/${patientUUID}/documenten`,
        });
    };

    // Fetcher callback
    useEffect(() => {
        if (fetcher?.state === "idle") {
            if (fetcher?.data?.error) {
                setStatus(Statuses.IDLE);
                return setError(true);
            }

            if (fetcher?.data) {
                setStatus(Statuses.IDLE);
                handleClose();
            }
        }
        //eslint-disable-next-line
    }, [fetcher]);

    const disabled = status === Statuses.SUBMITTING;

    return (
        <>
            <Button type="button" onClick={handleOpen}>Bestand uploaden</Button>

            <FormModal isOpen={isOpen} handleClose={handleClose}>
                <FormModal.Header handleClose={handleClose}>Bestand uploaden</FormModal.Header>

                <form id="add-document-form" onSubmit={handleSubmit(onSubmit)}>
                    <FileUploadFieldBox>
                        <FormField
                            type="MultiFileUpload"
                            id="File"
                            disabled={disabled}
                            error={errors.files}
                            register={register}
                            name="file"
                            userMayUpload
                            setValue={setValue}
                            watchTitle={watchTitle}
                            setFileSizeError={setFileSizeError}
                        />
                        <Text $italic $noMargin>Gelieve PDF te uploaden i.v.m. het openen in de app van de patiënt</Text>
                    </FileUploadFieldBox>
                </form>

                {error && <Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}

                <FormModal.Footer>
                    <Box><StyledButton type="button" onClick={handleClose} $variant="secondary">Annuleren</StyledButton></Box>
                    <Box><StyledButton type="submit" form="add-document-form" disabled={disabled} loading={disabled}>Uploaden</StyledButton></Box>
                </FormModal.Footer>
            </FormModal>
        </>
    );
}