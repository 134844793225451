import {isRouteErrorResponse, Navigate, useLocation, useRouteError} from 'react-router-dom';
import NoMatch from './NoMatch';
import Forbidden from './Forbidden';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import {Heading1} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import {resetRootLoaderPromise} from '../Root/Root';

const StyledErrorBoundary = styled.div`
    min-height: 400px;
`;

const ErrorBoundary = () => {
    const error = useRouteError();
    const location = useLocation();

    if(isRouteErrorResponse(error)){
        switch (error.status) {
            // Unauthorized
            case 401:
                Cookies.remove('adhdcentraal-portal_token');
                Cookies.remove('adhdcentraal-portal_expiration');

                resetRootLoaderPromise();

                return <Navigate to="/login" state={{ from: location, reason: "token_expired" }} replace />;
            // Forbidden
            case 403:
                return <Forbidden message={error.data.name ?? error.data} />;
            // Not Found
            case 404:
                return <NoMatch />
            default:
                break;
        }
    }

    return (
        <StyledErrorBoundary>
            <Heading1 $noMargin>Er is iets mis gegaan</Heading1>
            <Text>Probeer het opnieuw</Text>
        </StyledErrorBoundary>
    );
}

export default ErrorBoundary;