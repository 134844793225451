import styled from "styled-components";
import {AppliedFilter} from './AppliedFilter';
import React from 'react';
import dayjs from 'dayjs';

const StyledUsedFiltersList = styled.div`
    display: flex;
    flex-wrap: wrap;
    color: var(--color-dark);
    gap: 10px;
`;

export const UsedFiltersList = ({locationData, healthInsuranceData, usedFilters}) => {
    const location = usedFilters?.find(item => item?.key === "location");
    const nextAppointmentFrom = usedFilters?.find(item => item?.key === "nextAppointmentFrom");
    const nextAppointmentUntil = usedFilters?.find(item => item?.key === "nextAppointmentUntil");
    const healthInsurance = usedFilters?.find(item => item?.key === "healthInsurance");
    const hasUncompletedQuestionnaires = usedFilters?.find(item => item?.key === "hasUncompletedQuestionnaires");

    return (
        <StyledUsedFiltersList>
            {location && <AppliedFilter removable={false} title={'Locatie'} value={locationData?.find(item => item.id === location.value)?.name} />}
            {nextAppointmentFrom && <AppliedFilter removable={false} title={'Eerstkomende afspraak vanaf'} value={dayjs(nextAppointmentFrom.value).format("DD-MM-YYYY")} />}
            {nextAppointmentUntil && <AppliedFilter removable={false} title={'Eerstkomende afspraak vóór'} value={dayjs(nextAppointmentUntil.value).format("DD-MM-YYYY")} />}
            {healthInsurance && <AppliedFilter removable={false} title={'Zorgverzekeraar'} value={healthInsuranceData?.find(item => item?.id?.toLowerCase() === healthInsurance)?.name} />}
            {hasUncompletedQuestionnaires && <AppliedFilter removable={false} title={'Openstaande vragenlijsten'} value={hasUncompletedQuestionnaires.value === "1" ? "Ja" : hasUncompletedQuestionnaires.value === "0" ? "Nee" : ""} />}
        </StyledUsedFiltersList>
    );
}