import {Input} from '../Input/Input';
import styled from 'styled-components';
import selectChevron from '../../assets/icons/select-chevron.svg';

const StyledSelectField = styled(Input).attrs({
    as: "select",
})`
    box-shadow: var(--box-shadow);
    background-image: url(${selectChevron});
    background-repeat: no-repeat;
    background-position: right 13px top 50%;
    padding: 13px 42px 13px 17px;
`;

export const SelectField = ({data, ...props}) => {

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        if (props.onChange) {
            props.onChange(selectedValue);
        }
    };

    return (
        <StyledSelectField
            id={props.id}
            {...(props.register && props.register(props.name, {
                required: props.required,
                onBlur: () => { if(props.onFormFieldBlur) props.onFormFieldBlur() }
            }))}
            defaultValue={props.defaultValue}
            disabled={props.disabled}
            onChange={handleSelectChange}
        >
            {data?.map(item => (
                <option key={item.id} value={item.id} disabled={item.disabled ?? false}>{item.title}</option>
            ))}
        </StyledSelectField>
    );
}