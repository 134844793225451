import {Text} from '../../../components/Text/Text';
import styled from 'styled-components';

const StyledResultParagraph = styled.div`
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
`;

export const ResultParagraph = ({data}) => {
    return (
        <StyledResultParagraph>
            <Text $bold $noMargin>{data?.title}</Text>
            <Text $noMargin>{data?.text}</Text>
        </StyledResultParagraph>
    );
}