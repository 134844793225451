import {Text} from '../Text/Text';
import React from 'react';
import styled from 'styled-components';
import {ReactComponent as Impersonate} from '../../assets/icons/impersonate.svg';
import {getCurrentEnvironment} from '../../utils/helpers';

const StyledImpersonateBar = styled.div`
    position: fixed;
    top: ${({$hasEnvironmentBar}) => $hasEnvironmentBar ? "20px" : "0"};
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    border-top: 6px solid var(--color-impersonate);
    pointer-events: none;
`;

const InnerImpersonateBar = styled.div`
    background-color: var(--color-impersonate);
    padding: 2px 30px 8px 30px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 0 0 6px 6px;
`;

const StyledImpersonate = styled(Impersonate)`
    width: 20px;
    height: 20px;
`;

export const ImpersonateBar = (props) => {
    const currentEnvironment = getCurrentEnvironment();

    return (
        <StyledImpersonateBar $hasEnvironmentBar={currentEnvironment.showBar}>
            <InnerImpersonateBar>
                <Text $small $noMargin $white>
                    Je kijkt nu als: <strong>{props.name}</strong>
                </Text>
                <StyledImpersonate />
            </InnerImpersonateBar>
        </StyledImpersonateBar>
    );
}