import {Link, useRouteError} from "react-router-dom";
import {useEffect} from 'react';
import Cookies from 'js-cookie';

const ApiFailureFallback = () => {
    const { error, isError, message } = useRouteError();

    useEffect(() => {
        Cookies.remove('adhdcentraal-portal_token');
        Cookies.remove('adhdcentraal-portal_expiration');
    }, []);

    if (isError) {
        console.log("Error occured: ", error, message)
    }

    return (
        <>
            <h1>Er is iets fout gegaan.</h1>
            <p>Het is momenteel niet mogelijk om de actie te voltooien.</p>
            <Link to="/" reloadDocument={true}>Probeer opnieuw</Link>
        </>
    )
}

export default ApiFailureFallback;