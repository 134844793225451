import styled, {css} from 'styled-components';
import {Heading2, Heading3} from '../Heading/Heading';
import {useEffect, useState} from 'react';
import {ReactComponent as Upload} from '../../assets/icons/upload.svg';
import {ReactComponent as Trash} from '../../assets/icons/trash.svg';
import {Text, truncateMixin} from '../Text/Text';
import {formatBytes} from '../../utils/formatBytes';
import {ConditionalWrap} from '../Utils/Utils';
import {CustomLink} from '../CustomLink/CustomLink';

const StyledFileUploadField = styled.div``;

const InnerField = styled.label`
    background-color: var(--color-white);
    border: 2px dashed ${({$isDragging}) => $isDragging ? 'var(--color-primary)' : 'var(--color-grey-20)'};
    border-radius: 8px;
    padding: 17px 20px;
    width: ${({$userMayUpload}) => $userMayUpload ? '100%' : '342px'};
    max-width: 100%;
    min-height: ${({$userMayUpload}) => $userMayUpload ? '120px' : '80px'};
    display: flex;
    align-items: center;
    box-shadow: var(--box-shadow);
    
    ${({$userMayUpload, $hasUrl}) => ($userMayUpload || $hasUrl) && css`
        cursor: pointer;
    `};

    ${({$empty}) => $empty && css`
        justify-content: center;
        align-items: center;
    `};
`;

const FileName2 = styled(Heading2).attrs({
    as: "p",
    $noMargin: true,
})`
    ${truncateMixin};
    margin-bottom: 10px;
`;

const FileName = styled(Heading3).attrs({
    as: "p",
    $noMargin: true,
})`
    ${truncateMixin};
    margin-bottom: 8px;
`;

const FileSize = styled(Text).attrs({
    $noMargin: true,
})``;

const EmptyNotice = styled(FileSize)`
    text-align: center;
    font-weight: var(--fw-bold);
    color: var(--color-text-alpha-50);
`;

const UserMayUpload = styled(Heading2)`
    display: flex;
    align-items: center;
    gap: 20px;
    color: var(--color-primary);
`;

const HiddenInput = styled.input`
    display: none;
`;

const IconStyles = css`
    width: 32px;
    height: 32px;
    vertical-align: middle;
    cursor: pointer;
`;

const UploadIcon = styled(Upload)`
    ${IconStyles};
`;

const DeleteIcon = styled(Trash)`
    ${IconStyles};
    flex-shrink: 0;
    position: relative;
    z-index: 1;
`;

const UploadedFile = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
`;

const StyledCustomLink = styled(CustomLink)`
    display: inline-block;
    color: inherit;
    text-decoration: none;
`;

export const FileUploadField = ({data, ...props}) => {
    const userMayUpload = Boolean(props?.userMayUpload);

    if(userMayUpload) {
        return (
            <AddDocumentField data={data} {...props} />
        );
    }

    // Preview-only variant of FileUploadField, used in ViewAnswersModal
    const hasFileName = Boolean(data?.answer?.fileName);
    const hasUrl = Boolean(data?.answer?.url);

    return (
        <StyledFileUploadField title={hasFileName ? data?.answer?.fileName : "Geen bestand geüpload"}>
            <ConditionalWrap condition={hasUrl && hasFileName} wrap={children => <StyledCustomLink to={data?.answer?.url} type="external">{children}</StyledCustomLink>}>
                <InnerField $empty={!hasFileName} $hasUrl={hasUrl}>
                    {hasFileName ? (
                        <div>
                            <FileName>{data?.answer?.fileName}</FileName>
                            {data?.answer?.fileSize && <FileSize>{formatBytes(data.answer.fileSize)}</FileSize>}
                        </div>
                    ) : (
                        <EmptyNotice>Geen bestand geüpload</EmptyNotice>
                    )}
                </InnerField>
            </ConditionalWrap>
        </StyledFileUploadField>
    );
}

// Actual field used in AddDocument
const AddDocumentField = ({data, ...props}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [file, setFile] = useState(null);
    const [fileSize, setFileSize] = useState(null);

    // Reset AddDocumentField content on render
    useEffect(() => {
        setFile(null);
        setFileSize(null);
        props?.setFileSizeError(false);
        props?.setValue(props?.name, "", { shouldValidate: true });
        props?.setValue("title", "");
        //eslint-disable-next-line
    }, []);

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);

        const uploadedFile = e.dataTransfer.files[0]
        if(uploadedFile) {
            props?.setValue(props?.name, e.dataTransfer.files);
            handleUploadFile(uploadedFile);
        }
    };

    const handleFileInputChange = (e) => {
        const uploadedFile = e.target.files[0]
        if(uploadedFile) {
            handleUploadFile(uploadedFile);
        }
    };

    const handleUploadFile = (uploadedFile) => {
        props?.setFileSizeError(false);
        if(!props?.watchTitle) props?.setValue("title", uploadedFile?.name);
        setFile(uploadedFile);
        setFileSize(uploadedFile?.size ? formatBytes(uploadedFile?.size) : null);

        if(uploadedFile?.size > 25000 * 1000) {
            props?.setFileSizeError(true);
        }
    }

    const handleDelete = (e) => {
        e.preventDefault();
        setFile(null);
        setFileSize(null);
        props?.setFileSizeError(false);
        props?.setValue(props?.name, "");
    }

    return (
        <StyledFileUploadField title={Boolean(file?.name) ? file?.name : "Upload een bestand"}>
            <InnerField
                htmlFor={props.id}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                $isDragging={isDragging}
                $empty={!Boolean(file)}
                $userMayUpload
            >
                {Boolean(file) ? (
                    <UploadedFile>
                        <div>
                            <FileName2>{file?.name}</FileName2>
                            {fileSize && <FileSize>{fileSize}</FileSize>}
                        </div>
                        <DeleteIcon title="Bestand verwijderen" onClick={handleDelete} />
                    </UploadedFile>
                ) : (
                    <UserMayUpload><UploadIcon /> Upload een bestand</UserMayUpload>
                )}

                <HiddenInput
                    id={props.id}
                    type="file"
                    {...(props.register && props.register(props.name, {
                        required: props.required,
                        onBlur: () => { if(props.onFormFieldBlur) props.onFormFieldBlur() },
                        onChange: (e) => handleFileInputChange(e)
                    }))}
                    disabled={props.disabled}
                />
            </InnerField>
        </StyledFileUploadField>
    );
}