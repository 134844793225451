import {createContext, useState, useContext, useEffect} from "react";

export const QuestionnaireContext = createContext(null);

export default function QuestionnaireProvider({children}) {
    const [questionnaireValues, setQuestionnaireValues] = useState(() => {
        const storedValues = localStorage.getItem("questionnaireValues");
        return storedValues ? JSON.parse(storedValues) : {};
    });

    const updateQuestionnaireValues = (data, patientUUID, questionnaireUUID) => {
        setQuestionnaireValues((prevValues) => ({
            ...prevValues,
            [patientUUID]: {
                ...prevValues[patientUUID],
                [questionnaireUUID]: {
                    ...prevValues[questionnaireUUID],
                    ...data,
                }
            }
        }));
    };

    const removeSpecificQuestionnaireValues = (patientUUID, questionnaireUUID) => {
        setQuestionnaireValues((prevValues) => {
            const updatedValues = { ...prevValues };
            if (updatedValues[patientUUID]) {
                delete updatedValues[patientUUID][questionnaireUUID];
            }
            return updatedValues;
        });
    };

    useEffect(() => {
        localStorage.setItem("questionnaireValues", JSON.stringify(questionnaireValues));
    }, [questionnaireValues]);

    return (
        <QuestionnaireContext.Provider value={{questionnaireValues, updateQuestionnaireValues, removeSpecificQuestionnaireValues}}>
            {children}
        </QuestionnaireContext.Provider>
    );
}

export const useQuestionnaireContext = () => useContext(QuestionnaireContext);

