import styled, {css} from "styled-components";

const AppliedFilterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 16px 5px 16px;
    border-radius: 30px;
    font-size: 16px;

    ${({$variant}) => $variant === "button" ? css`
        background-color: rgba(var(--color-blue-50-rgb), 0.1);
        color: rgba(var(--color-blue-50-rgb), 1);
    ` : css`
        background-color: rgba(var(--color-grey-20-rgb), 1);
    `};
`

const AppliedFilterValue = styled.span`
    font-weight: bold;
`

const AppliedFilterTitle = styled.span`
    margin-right: 4px;
`

const AppliedFilterRemoveButton = styled.button`
    cursor: pointer;
    border: none;
    font-size: 22px;
    color: rgba(var(--color-blue-50-rgb), 1);
    transform: translateX(6px);
    background: transparent;
`

export const AppliedFilter = ({ title, value, removable = true, onRemove, ...props }) => {
    return <AppliedFilterContainer $variant={ removable ? 'button' : 'default' } {...props}>
        <AppliedFilterTitle>{title}:</AppliedFilterTitle>
        <AppliedFilterValue>{value}</AppliedFilterValue>
        { removable && <AppliedFilterRemoveButton onClick={onRemove}>&times;</AppliedFilterRemoveButton>}
    </AppliedFilterContainer>
}