import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import {useRef} from 'react';
import {createPortal} from 'react-dom';
import {getCurrentEnvironment} from '../../utils/helpers';

const StyledSideDrawer = styled.section`
    ${({$hasEnvironmentBar}) => $hasEnvironmentBar && "padding-top: 20px"};
    position: fixed;
    top: 0;
    right: -500px;
    background-color: var(--color-content-background);
    min-height: 100vh;
    height: 100%;

    @supports (min-height: -webkit-fill-available) {
        min-height: -webkit-fill-available;
    }

    box-shadow: var(--box-shadow-aside-flipped);
    width: 100%;
    max-width: 500px;
    z-index: 100;
    transition: right .2s ease;
    
    &.entering { right: 0; }
    &.entered { right: 0; }
    &.exiting { right: -500px; }
    &.exited { right: -500px; }
`;

const SideDrawerInner = styled.div`
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const Underlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-black);
    height: 100%;
    width: 100%;
    z-index: 99;
    opacity: 0;
    transition: opacity .2s ease;

    &.entering { opacity: .3; }
    &.entered { opacity: .3; }
    &.exiting { opacity: 0; }
    &.exited { opacity: 0; }
`;

export const SideDrawer = ({isOpen, handleClose, children}) => {
    const currentEnvironment = getCurrentEnvironment();
    const sideDrawerNodeRef = useRef(null);
    const underlayNodeRef = useRef(null);

    return createPortal(
        <>
            <Transition nodeRef={sideDrawerNodeRef} in={isOpen} timeout={200} unmountOnExit>
                {state => (
                    <StyledSideDrawer ref={sideDrawerNodeRef} className={state} $hasEnvironmentBar={currentEnvironment.showBar}>
                        <SideDrawerInner>
                            {children}
                        </SideDrawerInner>
                    </StyledSideDrawer>
                )}
            </Transition>

            <Transition nodeRef={underlayNodeRef} in={isOpen} timeout={200} unmountOnExit>
                {state => (
                    <Underlay ref={underlayNodeRef} className={state} onClick={handleClose} />
                )}
            </Transition>
        </>, document.body
    );
}