import {Outlet} from 'react-router-dom';
import styled from 'styled-components';
import background from '../assets/background.jpg';
import {Container} from '../components/Utils/Utils';

const StyledAuthLayout = styled.div`
    background-color: var(--color-background);
    background-image: url(${background});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledContainer = styled(Container)`
    max-width: 510px;
`;

const Content = styled.div`
    background-color: var(--color-content-background);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    padding: 50px 60px;
    position: relative;
    min-height: 460px;
    margin-bottom: 200px;
`;

export default function AuthLayout() {
    return (
        <StyledAuthLayout>
            <StyledContainer>
                <Content>
                    <Outlet />
                </Content>
            </StyledContainer>
        </StyledAuthLayout>
    );
}