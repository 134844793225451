import {Modal} from './Modal';
import styled from 'styled-components';
import {Heading2} from '../Heading/Heading';

const StyledModal = styled(Modal)`
    max-width: 299px;
    width: 100%;
`;

const Content = styled.div`
    pointer-events: initial;
    position: relative;
    width: 100%;
    max-height: 100%;
    background-color: var(--color-content-background);
    box-shadow: var(--box-shadow);
    border-radius: 20px;
    padding: 20px;
`;

const ModalTitle = styled(Heading2).attrs({
    as: "h3",
    $align: "center",
    $noMargin: true
})`
    margin-bottom: 10px;
`;

export const CancelButton = styled.button`
    background-color: transparent;
    color: var(--color-primary);
    text-align: center;
    cursor: pointer;
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    text-decoration: none;
    line-height: 29px;
    border: none;
    width: 100%;
    margin-top: 10px;
    
    &:hover {
        color: var(--color-primary-hover);
    }
`;

ConfirmationModal.Header = function ConfirmationModal({children}) {
    return (
        <ModalTitle>{children}</ModalTitle>
    );
}

export default function ConfirmationModal({children, ...props}) {
    return (
        <StyledModal {...props}>
            <Content>
                {children}
            </Content>
        </StyledModal>
    );
}