import {useState} from 'react';

export const useTooltip = (delay) => {
    let timeout;
    const [isOpen, setIsOpen] = useState(false);

    const handleMouseEnter = () => {
        if (delay) {
            timeout = setTimeout(() => {
                setIsOpen(true);
            }, delay);
        } else {
            setIsOpen(true);
        }
    }

    const handleMouseLeave = () => {
        clearInterval(timeout);
        setIsOpen(false);
    }

    return {isOpen, handleMouseEnter, handleMouseLeave};
}