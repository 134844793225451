import {Heading3} from '../Heading/Heading';
import styled from 'styled-components';
import {ReactComponent as Chevron} from '../../assets/icons/chevron.svg';
import {CustomLink} from '../CustomLink/CustomLink';

const StyledBackButton = styled(Heading3).attrs({
    as: CustomLink
})`
    display: inline-flex;
    align-items: center;
    gap: 1px;
    margin-bottom: 40px;
    text-decoration: none;
`;

const StyledChevron = styled(Chevron)`
    width: 32px;
    height: 32px;
    transform: rotate(180deg);
    
    path { fill: var(--color-primary); }
    
    ${StyledBackButton}:hover & {
        path { fill: var(--color-primary-hover); }
    }
`;

export const BackButton = ({className, children, to, ...props}) => {
    return (
        <StyledBackButton className={className} to={to ?? "/"} {...props}>
            <StyledChevron />
            {children ?? "Terug"}
        </StyledBackButton>
    );
}