import { useLayoutEffect } from 'react';

const useAutoSizeTextArea = ({ id, value }) => {
    useLayoutEffect(() => {
        const textArea = document.getElementById(id);

        if (textArea) {
            textArea.style.height = '0px';
            const scrollHeight = textArea.scrollHeight;
            textArea.style.height = scrollHeight + 'px';
        }
    }, [id, value]);

    return null;
};

export default useAutoSizeTextArea;