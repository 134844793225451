import {createPortal} from 'react-dom';
import {Transition} from 'react-transition-group';
import {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {Button, buttonVariants} from '../Button/Button';
import {Heading1, Heading2} from '../Heading/Heading';
import {Text} from '../Text/Text';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import {DefaultFormFieldsAnswers} from '../DefaultFormFields/DefaultFormFields';
import {DivaFormFieldsAnswers} from '../DivaFormFields/DivaFormFields';
import useScrollSpy from '../../hooks/useScrollSpy';
import {TableOfContents, TableOfContentsItem} from '../TableOfContents/TableOfContents';

export const StyledModal = styled.div`
    pointer-events: none;
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    z-index: 101;
    opacity: 0;
    transition: opacity .2s ease;

    &.entering { opacity: 1; }
    &.entered { opacity: 1; }
    &.exiting { opacity: 0; }
    &.exited { opacity: 0; }
`;

export const Underlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-black);
    height: 100%;
    width: 100%;
    z-index: 100;
    opacity: 0;
    transition: opacity .2s ease;

    &.entering { opacity: .5; }
    &.entered { opacity: .5; }
    &.exiting { opacity: 0; }
    &.exited { opacity: 0; }   
`;

const Inner = styled.div`
    pointer-events: initial;
    position: relative;
    width: 100%;
    max-width: ${({$identifier}) => $identifier === "Diva" ? "1200px" : "800px"};
    max-height: 100%;
    overflow-y: auto;
    background-color: var(--color-content-background);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    padding: 50px 100px;
    scroll-behavior: smooth;
    scroll-padding-top: 30px;
`;

const ModalFooter = styled.div`
    display: flex;
    gap: 35px;
    margin-top: 50px;
`;

const Box = styled.div`
    flex: 1 1 0;
`;

const StyledButton = styled(Button)`
    ${({$variant}) => buttonVariants($variant)};
    border: none;
    width: 100%;
`;

const Steps = styled(Heading1).attrs({
    as: "h3",
    $align: "center",
    $noMargin: true
})`
    margin-bottom: 45px;
`;

const StyledCloseIcon = styled(CloseIcon)`
    width: 40px;
    height: 40px;
    position: absolute;
    top: 42px;
    right: 45px;
    cursor: pointer;
`;

const StepTitle = styled(Heading2).attrs({
    as: "h4",
    $noMargin: true
})`
    margin-bottom: 10px;
`;

const Step = styled.div`
    &:not(:last-of-type) {
        margin-bottom: 60px;
    }
`;

// NOTE: Not using the base Modal component, could be rewritten like Question.jsx
export const ViewAnswersModal = ({answersData}) => {
    const {patientUUID, questionnaireUUID} = useParams();
    const navigate = useNavigate();
    const isDiva = answersData?.identifier === "Diva";
    const ref = useRef(null);
    const underlayRef = useRef(null);
    const touchActionStyle = useRef("");
    const overflowStyle = useRef("");
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
        touchActionStyle.current = document.body.style.touchAction;
        document.body.style.touchAction = "none";
        overflowStyle.current = document.body.style.overflow;
        document.body.style.overflow = "hidden";
    }

    const closeModal = () => {
        setIsOpen(false);
        document.body.style.touchAction = touchActionStyle.current;
        document.body.style.overflow = overflowStyle.current;
    }

    useEffect(() => {
        openModal();

        return () => closeModal();
    }, []);

    let closeTimeout;
    const handleCloseModal = () => {
        clearTimeout(closeTimeout);
        closeModal();
        closeTimeout = setTimeout(() => {
            navigate(`/patient/${patientUUID}/vragenlijst/${questionnaireUUID}`, { preventScrollReset: true })
        }, 200)
    }

    // Scroll spy
    const innerRef = useRef(null);
    const sectionRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const activeSection = useScrollSpy({
        sectionElementRefs: sectionRefs,
        offsetPx: -80,
        scrollingElement: innerRef,
    })

    return createPortal(
        <>
            <Transition nodeRef={ref} in={isOpen} timeout={200} unmountOnExit>
                {state => (
                    <StyledModal ref={ref} className={state}>
                        <Inner ref={innerRef} id="view-answers-modal" $identifier={isDiva ? "Diva" : null}>
                            <StyledCloseIcon onClick={handleCloseModal} />
                            <Steps>Vragenlijst: {answersData?.title}</Steps>

                            {isDiva &&
                                <TableOfContents>
                                    <TableOfContentsItem to="deel-1" containerId="view-answers-modal" $active={activeSection === 0}>Deel 1</TableOfContentsItem>
                                    <TableOfContentsItem to="deel-2" containerId="view-answers-modal" $active={activeSection === 1}>Deel 2</TableOfContentsItem>
                                    <TableOfContentsItem to="deel-3" containerId="view-answers-modal" $active={activeSection === 2}>Deel 3</TableOfContentsItem>
                                </TableOfContents>
                            }

                            {answersData?.steps?.map((item, index) => (
                                <Step key={item.id}>
                                    <StepTitle>{item?.title}</StepTitle>
                                    {item.description && <Text $noMargin>{item.description}</Text>}

                                    {(isDiva) ? (
                                        <DivaFormFieldsAnswers
                                            questionsData={answersData}
                                            stepIndex={index}
                                            currentStepData={item}
                                            identifier={answersData?.identifier}
                                            sectionRefs={sectionRefs}
                                        />
                                    ) : (
                                        <DefaultFormFieldsAnswers data={item?.items} />
                                    )}
                                </Step>
                            ))}

                            <ModalFooter>
                                <Box />
                                <Box><StyledButton type="button" onClick={handleCloseModal}>Afsluiten</StyledButton></Box>
                            </ModalFooter>
                        </Inner>
                    </StyledModal>
                )}
            </Transition>

            <Transition nodeRef={underlayRef} in={isOpen} timeout={200} unmountOnExit>
                {state => (
                    <Underlay ref={underlayRef} className={state} onClick={handleCloseModal} />
                )}
            </Transition>
        </>, document.body
    );
}