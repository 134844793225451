import styled from "styled-components";
import {FormFieldWrapper} from "../../../components/FormFields/FormFields";
import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {PortalDateTimeField} from "../../../components/PortalFormFields/PortalDateTimeField/PortalDateTimeField";
import dayjs from "dayjs";
import {PortalSelectField} from "../../../components/PortalFormFields/PortalSelectField/PortalSelectField";
import {FilterButtonBase} from '../../../components/FilterButtonBase/FilterButtonBase';
import {PortalTextInputField} from '../../../components/PortalFormFields/PortalTextInputField/PortalTextInputField';

const StyledFormFieldWrapper = styled(FormFieldWrapper)`
    min-width: 0;
`;

// TODO: onaftersubmit is hier (nog) niet
export function FilterButton({locationData, onAfterSubmit}) {
    const [isOpen, setIsOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    // Set the unappliedFilters initially to current searchParams (retreived from searchParams
    const [unappliedFilters, setUnappliedFilters] = useState({
        fullName: searchParams.get("fullName"),
        medicoreID: searchParams.get("medicoreID"),
        birthDate: searchParams.get("birthDate"),
        location: searchParams.getAll("location"),
        appointmentFrom: searchParams.get("appointmentFrom"),
        appointmentUntil: searchParams.get("appointmentUntil"),
    });

    // Keep unappliedFilters correct when searchParams change (when deleting in AppliedFilterList)
    useEffect(() => {
        setUnappliedFilters({
            fullName: searchParams.get("fullName"),
            medicoreID: searchParams.get("medicoreID"),
            birthDate: searchParams.get("birthDate"),
            location: searchParams.getAll("location"),
            appointmentFrom: searchParams.get("appointmentFrom"),
            appointmentUntil: searchParams.get("appointmentUntil"),
        })
    }, [searchParams]);

    function handleUnappliedFiltersChange(key, value) {
        setUnappliedFilters({
            ...unappliedFilters,
            [key]: value
        })
    }

    function handleApply() {
        // Set search params optionally
        const newParams = {
            ...(unappliedFilters.fullName ? {fullName: unappliedFilters.fullName} : {}),
            ...(unappliedFilters.medicoreID ? {medicoreID: unappliedFilters.medicoreID} : {}),
            ...(unappliedFilters.birthDate ? {birthDate: unappliedFilters.birthDate} : {}),
            ...(unappliedFilters.location ? {location: unappliedFilters.location} : {}),
            ...(unappliedFilters.appointmentFrom ? {appointmentFrom: unappliedFilters.appointmentFrom} : {}),
            ...(unappliedFilters.appointmentUntil ? {appointmentUntil: unappliedFilters.appointmentUntil} : {}),
        }
        setSearchParams(newParams, {replace: true, preventScrollReset: true});
        // onAfterSubmit(); //todo
        setIsOpen(false);
    }

    // Select fields options
    const locationFilterOptions = locationData?.map(item => ({value: item.id, label: item.name})) ?? [];

    return (
        <FilterButtonBase isOpen={isOpen} setIsOpen={setIsOpen} handleApply={handleApply}>
            <StyledFormFieldWrapper
                id="fullName"
                label="Naam"
            >
                <PortalTextInputField
                    placeholder={"Naam"}
                    defaultValue={unappliedFilters?.fullName}
                    onChange={({ target: { value }}) => handleUnappliedFiltersChange("fullName", value)}
                />
            </StyledFormFieldWrapper>

            <StyledFormFieldWrapper
                id="medicoreID"
                label="Medicore ID"
            >
                <PortalTextInputField
                    placeholder={"Medicore ID"}
                    defaultValue={unappliedFilters?.medicoreID}
                    onChange={({ target: { value }}) => handleUnappliedFiltersChange("medicoreID", value)}
                />
            </StyledFormFieldWrapper>

            <StyledFormFieldWrapper
                id="birthDate"
                label="Geboortedatum"
            >
                <PortalDateTimeField
                    placeholder="Kies een datum"
                    minDate={null}
                    hideTime={true}
                    defaultValue={unappliedFilters?.birthDate ? dayjs(unappliedFilters.birthDate).format("DD-MM-YYYY") : ""}
                    onChange={(date) => handleUnappliedFiltersChange("birthDate", date ? dayjs(date).format("YYYY-MM-DD") : "")}
                />
            </StyledFormFieldWrapper>

            <StyledFormFieldWrapper
                id="location"
                label="Locatie"
            >
                <PortalSelectField
                    isMulti={true}
                    placeholder="Alle"
                    options={locationFilterOptions}
                    onChange={(option) => handleUnappliedFiltersChange("location", option?.filter(o => o?.value?.length > 0).map(o => o.value))}
                    defaultValue={locationFilterOptions?.filter(o => unappliedFilters?.location?.includes(o.value))}
                />
            </StyledFormFieldWrapper>

            <StyledFormFieldWrapper
                id="appointmentFrom"
                label="Afspraakdatum vanaf"
            >
                <PortalDateTimeField
                    placeholder="Kies een datum"
                    minDate={null}
                    hideTime={true}
                    defaultValue={unappliedFilters?.appointmentFrom ? dayjs(unappliedFilters.appointmentFrom).format("DD-MM-YYYY") : ""}
                    onChange={(date) => handleUnappliedFiltersChange("appointmentFrom", date ? dayjs(date).format("YYYY-MM-DD") : "")}
                />
            </StyledFormFieldWrapper>

            <StyledFormFieldWrapper
                id="appointmentUntil"
                label="Afspraakdatum vóór"
            >
                <PortalDateTimeField
                    placeholder="Kies een datum"
                    minDate={null}
                    hideTime={true}
                    defaultValue={unappliedFilters?.appointmentUntil ? dayjs(unappliedFilters.appointmentUntil).format("DD-MM-YYYY") : ""}
                    onChange={(date) => handleUnappliedFiltersChange("appointmentUntil", date ? dayjs(date).format("YYYY-MM-DD") : "")}
                />
            </StyledFormFieldWrapper>
        </FilterButtonBase>
    );
}