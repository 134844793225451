import {useBeforeUnload, useOutletContext, useParams} from 'react-router-dom';
import {useCallback} from 'react';
import styled from 'styled-components';
import {Heading1, Heading2} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import {FormField} from '../../components/FormFields/FormFields';
import {Button, buttonVariants} from '../../components/Button/Button';
import {CustomLink} from '../../components/CustomLink/CustomLink';
import {ConfirmBeforeSubmitModal} from '../Questions/Partials/ConfirmBeforeSubmitModal';

const StyledPublicQuestionnaireStep = styled.div``;

const Header = styled(Heading1).attrs({
    $align: "center",
    $noMargin: true
})`
    margin-bottom: 50px;
`;

const StepTitle = styled(Heading2).attrs({
    $noMargin: true
})`
    margin-bottom: 10px;
`;

const FormFieldsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 35px;
    margin-top: 50px;
    max-width: 500px;
    margin-left: auto;
`;

const StyledButton = styled(Button)`
    ${({$variant}) => buttonVariants($variant)};
    flex-grow: 1;
    border: none;
`;

const PreviousButton = styled(CustomLink)`
    font-size: var(--fs-button);
    color: var(--color-text-alpha-50);
    line-height: 1.25;
    margin: 0;
    cursor: pointer;
    padding: 8px 16px;
    text-decoration: none;

    &:hover {
        color: var(--color-text);
    }
`;

const PublicQuestionnaireStep = () => {
    const {
        publicQuestionnaireData,
        encodedSignature,
        stepIndex,
        errors,
        register,
        watch,
        shouldSoftSubmitForm,
        isOpen,
        extendedHandleClose,
        status,
        error,
        handlePreviousClick
    } = useOutletContext();
    const params = useParams();
    const currentStepData = publicQuestionnaireData?.steps?.[stepIndex];

    // Before user unloads the page, give a warning
    useBeforeUnload(
        useCallback((e) => {
            e.preventDefault();
            e.returnValue = "";
        }, [])
    );

    return (
        <StyledPublicQuestionnaireStep key={currentStepData.id}>
            <Header>Stap {stepIndex + 1} / {publicQuestionnaireData?.steps?.length}</Header>

            <StepTitle>{currentStepData?.title}</StepTitle>
            <Text $noMargin>{currentStepData?.description}</Text>

            <FormFieldsWrapper>
                {currentStepData?.items?.map(item => (
                    <FormField
                        key={item.id}
                        type={item.type}
                        id={item.id}
                        label={item.title}
                        required={item.required}
                        description={item.description}
                        disabled={false}
                        error={errors[item.id]}
                        register={register}
                        watch={watch}
                        name={item.id}
                        data={item}
                    />
                ))}
            </FormFieldsWrapper>

            <Footer>
                {/* Previous button*/}
                {stepIndex === 0
                    ? <PreviousButton to={`/vragenlijst/${params?.uuid}?c=${encodedSignature}`}>Vorige</PreviousButton>
                    : <PreviousButton to={`/vragenlijst/${params?.uuid}/${stepIndex}?c=${encodedSignature}`} onClick={handlePreviousClick}>Vorige</PreviousButton>
                }

                {/* Next/Submit button */}
                <StyledButton type="submit" form="fill-in-public-questionnaire-form">{shouldSoftSubmitForm ? "Afronden" : "Volgende"}</StyledButton>
                {shouldSoftSubmitForm && <ConfirmBeforeSubmitModal isOpen={isOpen} handleClose={extendedHandleClose} status={status} error={error} form="fill-in-public-questionnaire-form" />}
            </Footer>
        </StyledPublicQuestionnaireStep>
    );
};

export default PublicQuestionnaireStep;