import React, {useEffect, useState} from 'react';
import {useFetcher, useParams} from 'react-router-dom';
import {Statuses} from '../../../constants/enums';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal';
import {Text} from '../../../components/Text/Text';
import styled from 'styled-components';
import {Button} from '../../../components/Button/Button';

const StyledButton = styled(Button)`
    border: none;
    width: 100%;
    margin-top: 20px;
`;

const CancelButton = styled.button`
    background-color: transparent;
    color: var(--color-primary);
    text-align: center;
    cursor: pointer;
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    text-decoration: none;
    line-height: 29px;
    border: none;
    width: 100%;
    margin-top: 10px;
    
    &:hover {
        color: var(--color-primary-hover);
    }
`;

export const DeleteDocument = ({isOpen, handleClose, item}) => {
    const {patientUUID} = useParams();
    const fetcher = useFetcher();

    const [deleteStatus, setDeleteStatus] = useState(Statuses.IDLE);
    const [deleteError, setDeleteError] = useState(false);

    const handleDelete = () => {
        setDeleteStatus(Statuses.SUBMITTING);
        setDeleteError(false);

        const formData = new FormData();
        formData.append("intent", "delete-document");
        formData.append("documentId", item);
        fetcher.submit(formData, { method: "DELETE", action: `/patient/${patientUUID}/documenten`});
    }

    // Fetcher callback
    useEffect(() => {
        if(fetcher?.state === "idle" && (fetcher?.data || fetcher?.data === "")) {
            setDeleteStatus(Statuses.IDLE);

            if(fetcher?.data?.error) return setDeleteError(true);

            handleClose();
        }
        //eslint-disable-next-line
    }, [fetcher]);

    const disabled = deleteStatus === Statuses.SUBMITTING;

    useEffect(() => {
        if(isOpen) setDeleteError(false);
    }, [item, isOpen]);

    return (
        <ConfirmationModal isOpen={isOpen} handleClose={handleClose}>
            <ConfirmationModal.Header>Weet je het zeker?</ConfirmationModal.Header>
            <Text $noMargin $align="center">Je staat op het punt dit document te verwijderen. Dit kan niet meer ongedaan worden.</Text>
            <StyledButton type="button" onClick={handleDelete} disabled={disabled} loading={disabled}>Ja, verwijderen</StyledButton>
            <CancelButton onClick={handleClose}>Annuleer</CancelButton>
            {deleteError && <Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}
        </ConfirmationModal>
    );
}