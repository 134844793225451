import {useOpenModal} from "../../../hooks/useOpenModal";
import {Button, buttonVariants} from "../../../components/Button/Button";
import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {Statuses} from "../../../constants/enums";
import {useFetcher, useNavigate, useParams} from "react-router-dom";
import {Text} from "../../../components/Text/Text";
import ConfirmationModal, {CancelButton} from "../../../components/Modal/ConfirmationModal";
import WarningModal from '../../../components/Modal/WarningModal';

const StyledButton = styled(Button)`
    ${({$variant}) => buttonVariants($variant)};
    border: none;
    width: 100%;
`;

const WarningButton = styled(Button)`
    ${({$variant}) => buttonVariants($variant)};
    border: none;
    margin-left: auto;
`;

export const AddPatientReservation = ({selected, onAfterSubmit}) => {
    const {patientUUID} = useParams()
    const navigate = useNavigate()
    const {isOpen, handleOpen, handleClose, reset} = useOpenModal();
    const {isOpen: isOpenWarning, handleOpen: handleOpenWarning, handleClose: handleCloseWarning} = useOpenModal();

    const fetcher = useFetcher();
    const [status, setStatus] = useState(Statuses.IDLE);
    const disabled = status === Statuses.SUBMITTING;

    const handlePost = () => {
        setStatus(Statuses.SUBMITTING);

        fetcher.submit({
            intent: "add-reservation",
            appointment: selected
        }, {method: "POST", encType: "application/json", action: `/patient/${patientUUID}/afspraak-reserveren`});
    }

    useEffect(() => {
        if (fetcher?.state === "idle") {
            if (fetcher?.data?.error) {
                setStatus(Statuses.IDLE);
                handleClose();
                return handleOpenWarning();
            }

            if (fetcher?.data || fetcher?.data === "") {
                reset();
                navigate(`/patient/${patientUUID}/afspraken`);
            }
        }
        //eslint-disable-next-line
    }, [fetcher]);

    const handleOnExited = () => {
        onAfterSubmit();
    }

    return (
        <>
            <Button type='button' disabled={!selected?.length} onClick={handleOpen}>Afspraak reserveren</Button>

            <ConfirmationModal isOpen={isOpen} handleClose={handleClose}>
                <ConfirmationModal.Header>Afspraak reserveren</ConfirmationModal.Header>
                <Text $align="center">Weet je zeker dat je deze afspraak wil reserveren?</Text>
                <StyledButton type="button" disabled={disabled} loading={disabled} onClick={handlePost}>Ja, reserveren</StyledButton>
                <CancelButton onClick={handleClose}>Annuleer</CancelButton>
            </ConfirmationModal>

            <WarningModal isOpen={isOpenWarning} handleClose={handleCloseWarning} onExited={handleOnExited}>
                <WarningModal.Header handleClose={handleCloseWarning}>Afspraak niet meer beschikbaar</WarningModal.Header>
                <Text>Helaas is er iets fout gegaan en is deze optie al gereserveerd. Selecteer een andere optie en probeer het opnieuw.</Text>
                <WarningModal.Footer>
                    <WarningButton type="button" onClick={handleCloseWarning}>Selecteer andere optie</WarningButton>
                </WarningModal.Footer>
            </WarningModal>
        </>
    )
}