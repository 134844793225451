import {Heading1} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import styled from 'styled-components';
import i18n from "../../utils/i18n";
import {CustomLink} from '../../components/CustomLink/CustomLink';
import {useNavigate} from 'react-router-dom';

const StyledForbidden = styled.div`
    min-height: 400px;
`;

const StyledLink = styled(CustomLink).attrs({
    as: "button"
})`
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: var(--color-primary);

    &:hover {
        color: var(--color-primary-hover);
        text-decoration: underline;
    }
`;

const Forbidden = ({message}) => {
    const errorMessage = message?.toLowerCase()
    const navigate = useNavigate();

    const handlePreviousButton = () => {
        navigate(-1)
    }

    return (
        <StyledForbidden>
            <Heading1 $noMargin>{i18n.t(`error_boundary.403.${errorMessage}.title`)}</Heading1>
            <Text>{i18n.t(`error_boundary.403.${errorMessage}.message`)}</Text>
            <Text>Klik <StyledLink onClick={handlePreviousButton}>hier</StyledLink> om terug te gaan</Text>
        </StyledForbidden>
    )
}

export default Forbidden;