import styled from 'styled-components';
import {DefaultFormFields, DefaultFormFieldsAnswers} from '../DefaultFormFields/DefaultFormFields';
import {QuestionGroup} from './QuestionGroup';
import {QuestionsGrid} from './QuestionsGrid';
import {parseDivaData} from '../../utils/parseDivaData';
import {summaryPart1, summaryPart2, updateDivaSummary} from '../../utils/updateDivaSummary';
import {DivaSummaryTable} from '../DivaSummaryTable/DivaSummaryTable';

const StyledDivaFormFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;
`;

export const DivaGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
`;

const SpyGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const DivaFormFields = ({questionsData, stepIndex, currentStepData, errors, register, watch, control, onFormFieldBlur, getValues, identifier, sectionRefs}) => {
    const structuredData = parseDivaData(questionsData?.steps?.[0]?.items);
    const values = getValues();

    // Loop over the questionGroups from part 1 and 2 to update the summary data
    structuredData.part1.questionGroups.forEach((questionGroup, index) => {
        updateDivaSummary(summaryPart1, questionGroup, index, values);
    });

    structuredData.part2.questionGroups.forEach((questionGroup, index) => {
        updateDivaSummary(summaryPart2, questionGroup, index, values);
    });

    // Step 1 is using the structuredData and next steps can just use the currentStepData
    if(stepIndex >= 1) {
        return (
            <>
                {/* Samenvatting symptomen A en HI */}
                {stepIndex === 1 &&
                    <DivaSummaryTable
                        summaryPart1={summaryPart1}
                        summaryPart2={summaryPart2}
                    />
                }

                <DefaultFormFields
                    currentStepData={currentStepData}
                    errors={errors}
                    register={register}
                    watch={watch}
                    control={control}
                    onFormFieldBlur={onFormFieldBlur}
                    identifier={identifier}
                />
            </>
        )
    }

    return (
        <StyledDivaFormFields>
            {/* Deel 1: Symptomen aandachtsdeficiëntie (DSM-5 criterium A1) */}
            <SpyGroup id="deel-1" ref={sectionRefs[0]}>
                <QuestionGroup
                    data={structuredData.part1}
                    errors={errors}
                    register={register}
                    watch={watch}
                    control={control}
                    onFormFieldBlur={onFormFieldBlur}
                    identifier={identifier}
                />
            </SpyGroup>

            {/* Deel 2: Symptomen van hyperactiviteit-impulsiviteit (DSM-5 criterium A2) */}
            <SpyGroup id="deel-2" ref={sectionRefs[1]}>
                <QuestionGroup
                    data={structuredData.part2}
                    errors={errors}
                    register={register}
                    watch={watch}
                    control={control}
                    onFormFieldBlur={onFormFieldBlur}
                    identifier={identifier}
                />
            </SpyGroup>

            {/* Deel 3: Disfunctioneren als gevolg van de symptomen (DSM-5 criteria B, C en D) */}
            <SpyGroup id="deel-3" ref={sectionRefs[2]}>
                {/* Criterium B */}
                <QuestionsGrid
                    data={structuredData.part3criterionB}
                    errors={errors}
                    register={register}
                    watch={watch}
                    control={control}
                    onFormFieldBlur={onFormFieldBlur}
                    fullSpanIndexes={[0]}
                    identifier={identifier}
                />

                {/* Criterium C */}
                <QuestionsGrid
                    data={structuredData.part3criterionC}
                    errors={errors}
                    register={register}
                    watch={watch}
                    control={control}
                    onFormFieldBlur={onFormFieldBlur}
                    fullSpanIndexes={[10, 11, 12]}
                    identifier={identifier}
                />
            </SpyGroup>
        </StyledDivaFormFields>
    )
}

export const DivaFormFieldsAnswers = ({questionsData, stepIndex, currentStepData, identifier, sectionRefs}) => {
    const structuredData = parseDivaData(questionsData?.steps?.[0]?.items);

    // Loop over the questionGroups from part 1 and 2 to update the summary data
    structuredData.part1.questionGroups.forEach((questionGroup, index) => {
        updateDivaSummary(summaryPart1, questionGroup, index);
    });

    structuredData.part2.questionGroups.forEach((questionGroup, index) => {
        updateDivaSummary(summaryPart2, questionGroup, index);
    });

    // Step 1 is using the structuredData and next steps can just use the currentStepData
    if(stepIndex >= 1) {
        return (
            <>
                {/* Samenvatting symptomen A en HI */}
                {stepIndex === 1 &&
                    <DivaSummaryTable
                        summaryPart1={summaryPart1}
                        summaryPart2={summaryPart2}
                        disabled={true}
                    />
                }

                <DefaultFormFieldsAnswers
                    data={currentStepData?.items}
                    identifier={identifier}
                />
            </>
        )
    }

    return (
        <StyledDivaFormFields>
            {/* Deel 1: Symptomen aandachtsdeficiëntie (DSM-5 criterium A1) */}
            <SpyGroup id="deel-1" ref={sectionRefs[0]}>
                <QuestionGroup
                    data={structuredData.part1}
                    disabled={true}
                    identifier={identifier}
                />
            </SpyGroup>

            {/* Deel 2: Symptomen van hyperactiviteit-impulsiviteit (DSM-5 criterium A2) */}
            <SpyGroup id="deel-2" ref={sectionRefs[1]}>
                <QuestionGroup
                    data={structuredData.part2}
                    disabled={true}
                    identifier={identifier}
                />
            </SpyGroup>

            {/* Deel 3: Disfunctioneren als gevolg van de symptomen (DSM-5 criteria B, C en D) */}
            <SpyGroup id="deel-3" ref={sectionRefs[2]}>
                {/* Criterium B */}
                <QuestionsGrid
                    data={structuredData.part3criterionB}
                    disabled={true}
                    fullSpanIndexes={[0]}
                    identifier={identifier}
                />

                {/* Criterium C */}
                <QuestionsGrid
                    data={structuredData.part3criterionC}
                    disabled={true}
                    fullSpanIndexes={[10, 11, 12]}
                    identifier={identifier}
                />
            </SpyGroup>

            <SpyGroup ref={sectionRefs[3]} />
        </StyledDivaFormFields>
    )
}