import React, {useEffect} from 'react';
import {useOpenModal} from '../../hooks/useOpenModal';
import NewsModal from '../Modal/NewsModal';
import {Heading2, SubHeading} from '../Heading/Heading';
import {HTMLText} from '../Text/Text';
import styled from 'styled-components';
import sanitizeHtml from 'sanitize-html';

const StyledHTMLText = styled(HTMLText)`
    p {
        margin-top: 0;
        margin-bottom: 10px;
    }

    & > *:last-child {
        margin-bottom: 0;
    }
`;

export function NewsPopup({data}) {
    const {isOpen, handleClose} = useOpenModal();
    // const {isOpen, handleOpen, handleClose} = useOpenModal();

    useEffect(() => {
        // TODO: check if unread news item
        // handleOpen();
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <NewsModal isOpen={isOpen} handleClose={handleClose}>
                {Boolean(data.image) && <NewsModal.Image src={data.image} alt="" />}
                <NewsModal.Content handleClose={handleClose} hasImage={Boolean(data.image)}>
                    <Heading2 $noMargin>{data.title}</Heading2>
                    <SubHeading $noMargin>{data.date}</SubHeading>
                    <StyledHTMLText dangerouslySetInnerHTML={{__html: sanitizeHtml(data.text)}} />
                </NewsModal.Content>
            </NewsModal>
        </>
    );
}