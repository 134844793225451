import {createPortal} from 'react-dom';
import {Transition} from 'react-transition-group';
import {useRef} from 'react';
import {StyledModal, Underlay} from './ViewAnswersModal';

// NOTE: Not using the base Modal component
export const LogoutModal = ({children, isOpen, closeLogoutModal}) => {
    const ref = useRef(null);
    const underlayRef = useRef(null);

    return createPortal(
        <>
            <Transition nodeRef={ref} in={isOpen} timeout={200} unmountOnExit>
                {state => (
                    <StyledModal ref={ref} className={state}>
                        {children}
                    </StyledModal>
                )}
            </Transition>

            <Transition nodeRef={underlayRef} in={isOpen} timeout={200} unmountOnExit>
                {state => (
                    <Underlay ref={underlayRef} className={state} onClick={closeLogoutModal} />
                )}
            </Transition>
        </>, document.body
    );
}