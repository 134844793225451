import Cookies from "js-cookie";

// TODO refactor
export const fetchProtectedData = (request, endpoint, options = {}) => {
    const { doNotCatchError = false } = options;
    const accessToken = Cookies.get('adhdcentraal-portal_token');

    return fetch(`${process.env.REACT_APP_API_BASE_URL}/portal/api/v1/${endpoint}`, {
        ...(Boolean(accessToken) && {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }),
        ...(request && {signal: request.signal}),
    }).then((res) => {
        if(res.status === 401) {
            Cookies.remove('adhdcentraal-portal_token');
            Cookies.remove('adhdcentraal-portal_expiration');

            if(doNotCatchError) return;

            throw new Error("Unauthorized");
        }
        if(res.status === 403) throw new Error("Forbidden");
        if(res.status === 404) throw new Error("NotFound");

        if (!res.ok) throw new Error();

        return res.json();
    }).catch((error) => {
        if (error?.name === "AbortError") return;

        // Handle route errors in ErrorBoundary
        const errorMessage = error?.message;

        switch (errorMessage) {
            case "Unauthorized":
                throw new Response(errorMessage, { status: 401});
            case "Forbidden":
                throw new Response(errorMessage, { status: 403});
            case "NotFound":
                throw new Response(errorMessage, { status: 404});
            default:
                throw error;
        }
    });
};

export const postData = (request, endpoint, body) => {
    const accessToken = Cookies.get('adhdcentraal-portal_token');

    return fetch(`${process.env.REACT_APP_API_BASE_URL}/portal/api/v1/${endpoint}`, {
        method: "POST",
        body: body,
        headers: {
            ...(Boolean(accessToken) && {Authorization: `Bearer ${accessToken}`,}),
            ...(!(body instanceof FormData)) && {'Content-Type': 'application/json'},
        },
        signal: request.signal,
    }).then((res) => {
        if(res.status === 401) return Cookies.remove('adhdcentraal-portal_token');
        if (!res.ok) throw new Error();
        if(res.status === 204) return ""

        return res.json();
    }).catch((error) => {
        return handleFetchError(error);
    });
};

export const patchData = (request, endpoint, body) => {
    const accessToken = Cookies.get('adhdcentraal-portal_token');

    return fetch(`${process.env.REACT_APP_API_BASE_URL}/portal/api/v1/${endpoint}`, {
        method: "PATCH",
        body: body,
        headers: {
            ...(Boolean(accessToken) && {Authorization: `Bearer ${accessToken}`,}),
            ...(!(body instanceof FormData)) && {'Content-Type': 'application/json'},
        },
        signal: request.signal,
    }).then((res) => {
        if(res.status === 401) return Cookies.remove('adhdcentraal-portal_token');
        if (!res.ok) throw new Error();

        return res.json();
    }).catch((error) => {
        return handleFetchError(error);
    });
};

export const deleteData = (request, endpoint) => {
    const accessToken = Cookies.get('adhdcentraal-portal_token');

    return fetch(`${process.env.REACT_APP_API_BASE_URL}/portal/api/v1/${endpoint}`, {
        method: "DELETE",
        ...(Boolean(accessToken) && {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }),
        signal: request.signal,
    }).then((res) => {
        if (!res.ok) throw new Error();
        return res;
    }).catch((error) => {
        return handleFetchError(error);
    });
};

const handleFetchError = (error) => {
    if (error?.name === "AbortError") return;
    if (error?.response?.status === 401) return Cookies.remove('adhdcentraal-portal_token');
    return { "error": error };
};