import styled from 'styled-components';
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';
import {useOpenModal} from '../../../hooks/useOpenModal';
import {Button} from '../../../components/Button/Button';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal';
import React from 'react';
import {Text} from '../../../components/Text/Text';

const StyledCloseIcon = styled(CloseIcon)`
    width: 40px;
    height: 40px;
    position: absolute;
    top: 42px;
    right: 45px;
    cursor: pointer;
`;

const StyledButton = styled(Button)`
    border: none;
    width: 100%;
    margin-top: 20px;
`;

const CancelButton = styled.button`
    background-color: transparent;
    color: var(--color-primary);
    text-align: center;
    cursor: pointer;
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    text-decoration: none;
    line-height: 29px;
    border: none;
    width: 100%;
    margin-top: 10px;
    
    &:hover {
        color: var(--color-primary-hover);
    }
`;

export const ConfirmBeforeCloseModal = ({handleCloseAndNavigate, isDirty}) => {
    const {isOpen, handleOpen, handleClose} = useOpenModal();

    const handleConfirmBeforeClose = () => {
        handleClose();
        handleCloseAndNavigate();
    }

    return (
        <>
            <StyledCloseIcon onClick={isDirty ? handleOpen : handleCloseAndNavigate} />

            <ConfirmationModal isOpen={isOpen} handleClose={handleClose}>
                <ConfirmationModal.Header>Vragenlijst afsluiten</ConfirmationModal.Header>
                <Text $noMargin $align="center">Weet je zeker dat je deze vragenlijst wil afsluiten? </Text>
                <StyledButton type="button" onClick={handleConfirmBeforeClose}>Ja, afsluiten</StyledButton>
                <CancelButton onClick={handleClose}>Annuleer</CancelButton>
            </ConfirmationModal>
        </>
    );
}