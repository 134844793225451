import {Table, TableHead, TableHeadCell, TableHeadRow} from "../../components/Table/Table";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Await, defer, useLoaderData, useNavigation, useSearchParams} from "react-router-dom";
import {Heading1} from "../../components/Heading/Heading";
import {Permissions} from "../../constants/enums";
import {Text} from "../../components/Text/Text";
import {Spinner, SpinnerOverlay, SpinnerWrapper} from "../../components/Spinner/Spinner";
import Pagination from "../../components/Pagination";
import {checkRequiredAccount} from '../Root/Root';
import {fetchProtectedData} from '../../api/fetch';
import qs from 'qs';
import Cookies from 'js-cookie';
import {PortalSelectField} from '../../components/PortalFormFields/PortalSelectField/PortalSelectField';
import {CollapsibleTableRow} from './Partials/CollapsibleTableRow';
import PageHeadingContainer from '../../components/PageHeadingContainer/PageHeadingContainer';
import {InfoTooltip} from '../../components/Tooltip/InfoTooltip';

const RelativeWrapper = styled.div`
    position: relative;
`;

const StyledTable = styled(Table)`
    overflow-x: unset;
`;

const StyledTableHeadRow = styled(TableHeadRow)`
    // Is a grid because SubTable need to be aligned with indent on both sides (so extra column on purpose)
    display: grid;
    grid-template-columns: 52px minmax(200px, 1fr) repeat(5, 1fr) 52px; // Make sure this is the same as CollapsibleTableRow's StyledTableRowInner
`;

const StyledTableHeadCell = styled(TableHeadCell)`
    // Span 2 columns because the last column is extra for indent in sub table
    grid-column: span 2;
`;

const StyledInfoTooltip = styled(InfoTooltip)`
    align-self: flex-start;
`;

export async function questionnaireOverviewLoader({request}) {
    await checkRequiredAccount([Permissions.PORTAL_ACCESS_QUESTIONNAIRE_OVERVIEW, Permissions.PATIENT_VIEW]);

    const url = new URL(request.url);
    const searchParams = new URLSearchParams(url?.search)

    const page = searchParams.get("page") ?? 1;
    const status = searchParams.get("status");
    const location = Cookies.get('locationFilter');

    const queryString = qs.stringify({
        ...(page && {page}),
        ...(status && {status}),
        ...(location && {location}),
    });

    const questionnaireOverviewPromise = fetchProtectedData(request,`questionnaire/overview${queryString ? `?${queryString}` : ""}`);

    return defer({questionnaireOverviewPromise});
}

const QuestionnaireOverview = () => {
    const {questionnaireOverviewPromise} = useLoaderData();
    let [searchParams, setSearchParams] = useSearchParams();
    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        // Reset activeIndex when searchParams change
        setActiveIndex(null);
    }, [searchParams]);

    const navigation = useNavigation();
    const isPending = navigation?.location?.pathname?.includes("/questionnaire/overview");

    const handleStatusChanged = (option) => {
        // Set status optionally
        const newParams = {...(Boolean(option?.value) && {status: option.value})};
        setSearchParams(newParams, { replace: true, preventScrollReset: true })
    };

    const statusOptions = [
        {
            label: "Alle statussen",
            value: "",
        },
        {
            label: "Openstaand",
            value: "open"
        },
        {
            label: "Ingevuld",
            value: "completed"
        },
        {
            label: "Voltooid",
            value: "completedWithoutResponse"
        },
    ];

    // TODO dayjs format
    const convertDateFormat = (dateString) => {
        var parts = dateString.split("-");
        if (parts.length === 3) {
            return parts[2] + "-" + parts[1] + "-" + parts[0];
        } else {
            return "Invalid date format";
        }
    }

    return (
        <>
            <PageHeadingContainer>
                <Heading1>Vragenlijsten Diagnostiekdag</Heading1>
                <PortalSelectField
                    options={statusOptions}
                    placeholder="Selecteer een status"
                    onChange={handleStatusChanged}
                    defaultValue={statusOptions?.find(item => item?.value === searchParams.get("status")) ?? statusOptions[0]}
                    variant="dropdown"
                />
            </PageHeadingContainer>

            <React.Suspense fallback={
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            }>
                <Await resolve={questionnaireOverviewPromise} errorElement={<Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}>
                    {(questionnaireOverviewData) => {
                        return (
                            <RelativeWrapper>
                                {questionnaireOverviewData?.items?.length > 0 ? (
                                    <>
                                        <Pagination searchResults={questionnaireOverviewData} $position="top" />
                                        <StyledTable>
                                            <TableHead>
                                                <StyledTableHeadRow>
                                                    <TableHeadCell $fontSize={'var(--fs-heading-3)'} $flex="0" $minWidth="52px" $padding="16px 0 16px 20px"></TableHeadCell>
                                                    <TableHeadCell $fontSize={"var(--fs-heading-3)"} $minWidth="200px">Naam Patient</TableHeadCell>
                                                    <TableHeadCell $fontSize={'var(--fs-heading-3)'} $minWidth="0">Medicore ID</TableHeadCell>
                                                    <TableHeadCell $fontSize={'var(--fs-heading-3)'} $minWidth="0">Datum diagnostiek</TableHeadCell>
                                                    <TableHeadCell $fontSize={'var(--fs-heading-3)'} $minWidth="0"><StyledInfoTooltip content="De getoonde locatie is de locatie van de diagnostiekdag. Indien hier een andere locatie getoond wordt dan de locatie waarop in het portaal is gefilterd, betekent dit dat de locatie in de patiëntgegevens van Medicore niet up to date (of mogelijk dubbel) is. Pas in dit geval in Medicore de locatie in de patiëntgegevens aan naar de locatie van de diagnostiekdag (en vink eventuele andere locaties daar uit).">Locatie</StyledInfoTooltip></TableHeadCell>
                                                    <TableHeadCell $fontSize={'var(--fs-heading-3)'} $minWidth="0">Status</TableHeadCell>
                                                    <StyledTableHeadCell $fontSize={'var(--fs-heading-3)'} $minWidth="0">Dagen tot diagnostiek</StyledTableHeadCell>
                                                </StyledTableHeadRow>
                                            </TableHead>

                                            {questionnaireOverviewData?.items?.map((item, rowIndex) => {
                                                const isOddRow = rowIndex % 2 === 0;

                                                return (
                                                    <CollapsibleTableRow
                                                        key={`${rowIndex}-${item?.cells?.[0]}`}
                                                        item={item}
                                                        isOpen={activeIndex === item.id}
                                                        setActiveIndex={setActiveIndex}
                                                        isOddRow={isOddRow}
                                                    />
                                                );
                                            })}
                                        </StyledTable>
                                        <Pagination searchResults={questionnaireOverviewData} />
                                    </>
                                ) : (
                                    <Text>Geen resultaten gevonden</Text>
                                )}

                                {isPending &&
                                    <SpinnerOverlay>
                                        <SpinnerWrapper>
                                            <Spinner />
                                        </SpinnerWrapper>
                                    </SpinnerOverlay>
                                }
                            </RelativeWrapper>
                        )
                    }}
                </Await>
            </React.Suspense>
        </>
    )
}

export default QuestionnaireOverview