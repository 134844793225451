import {Modal} from './Modal';
import styled from 'styled-components';
import {Heading2} from '../Heading/Heading';
import {ReactComponent as Warning} from '../../assets/icons/warning.svg';
import {ReactComponent as Close} from '../../assets/icons/close.svg';

const StyledModal = styled(Modal)`
    max-width: 512px;
    width: 100%;
`;

const Content = styled.div`
    pointer-events: initial;
    position: relative;
    width: 100%;
    max-height: 100%;
    background-color: var(--color-content-background);
    box-shadow: var(--box-shadow);
    border-radius: 16px;
    padding: 32px;
`;

const CloseIcon = styled(Close)`
    position: absolute;
    top: 16px;
    right: 16px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    
    path {
        fill: rgba(var(--color-dark-rgb), .2)
    }
`;

const WarningIcon = styled(Warning)`
    width: 32px;
    height: 32px;
    margin-bottom: 16px;
`;

const ModalTitle = styled(Heading2).attrs({
    as: "h3",
    $noMargin: true
})`
    font-weight: var(--fw-bold);
    margin-bottom: 12px;
`;

const ModalFooter = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
`;

export default function WarningModal({children, ...props}) {
    return (
        <StyledModal {...props}>
            <Content>
                {children}
            </Content>
        </StyledModal>
    );
}

WarningModal.Header = function WarningModal({children, handleClose}) {
    return (
        <>
            <CloseIcon onClick={handleClose} />
            <WarningIcon />
            <ModalTitle>{children}</ModalTitle>
        </>
    );
}

WarningModal.Footer = function WarningModal({children}) {
    return (
        <ModalFooter>{children}</ModalFooter>
    );
}