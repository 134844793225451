import {Text} from '../Text/Text';
import styled from 'styled-components';

const StyledText = styled(Text)`
    margin-top: 20px;
`;

export const TextBlockField = ({data}) => {
    if(!data?.answer) return null;

    return (
        <StyledText $noMargin>{data.answer}</StyledText>
    );
}