import styled from "styled-components";

const HeadingContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: ${({$flexDirection}) => $flexDirection ?? 'row'};
    justify-content: ${({$justifyContent}) => $justifyContent ?? 'space-between'};
    align-items: ${({$alignItems}) => $alignItems ?? 'center'};
    margin-bottom: 56px;
`;

const PageHeadingContainer = ({ children, ...props}) => {
    return <HeadingContainer {...props}>
        { children }
    </HeadingContainer>
}

export default PageHeadingContainer