import {DivaGrid} from './DivaFormFields';
import styled, {css} from 'styled-components';
import {FormField} from '../FormFields/FormFields';

const DivaGridOrderItem = styled.div`
    ${({$index}) => $index === 0 && css`
        grid-column: span 2;
    `};
    
    // Different order than array index order
    order: ${({$index}) => $index === 0 ? 0 : $index === 1 ? 1 : $index === 2 ? 3 : $index === 3 ? 2 : $index === 4 ? 4 : $index};
`;

export const QuestionGroup = ({data, disabled = false, errors = [], register = undefined, watch = undefined, control = undefined, onFormFieldBlur = undefined, identifier}) => {
    return (
        <>
            <FormField
                key={data.introduction.id}
                type={data.introduction.type}
                id={data.introduction.id}
                label={data.introduction.title}
                required={data.introduction.required}
                description={data.introduction.description}
                disabled={disabled}
                error={errors[data.introduction.id]}
                register={register}
                watch={watch}
                control={control}
                onFormFieldBlur={onFormFieldBlur}
                name={data.introduction.id}
                data={data.introduction}
                identifier={identifier}
            />

            {data.questionGroups.map(questionGroup => {
                return (
                    <DivaGrid key={questionGroup.title}>
                        {questionGroup.questions.map((item, index) => (
                            <DivaGridOrderItem key={item.id} $index={index}>
                                <FormField
                                    key={item.id}
                                    type={item.type}
                                    id={item.id}
                                    label={item.title}
                                    required={item.required}
                                    description={item.description}
                                    disabled={disabled}
                                    error={errors[item.id]}
                                    register={register}
                                    watch={watch}
                                    control={control}
                                    onFormFieldBlur={onFormFieldBlur}
                                    name={item.id}
                                    data={item}
                                    identifier={identifier}
                                />
                            </DivaGridOrderItem>
                        ))}
                    </DivaGrid>
                )
            })}
        </>
    )
}