import React, {useState} from 'react';
import {Text} from '../../components/Text/Text';
import {Await, defer, useLoaderData, useRouteLoaderData} from 'react-router-dom';
import styled from 'styled-components';
import {Spinner} from '../../components/Spinner/Spinner';
import dayjs from 'dayjs';
import {ChevronIcon, Table, TableHead, TableHeadCell, TableHeadRow, TableRow, TableRowButton, TableRowCell, TableRowInner, TrashIcon} from '../../components/Table/Table';
import {deleteData, fetchProtectedData, postData} from '../../api/fetch';
import {useOpenModal} from '../../hooks/useOpenModal';
import {LayeredCustomLink} from '../../components/CustomLink/CustomLink';
import {AddDocument} from './Partials/AddDocument';
import {DeleteDocument} from './Partials/DeleteDocument';
import {ConditionalWrap} from '../../components/Utils/Utils';
import {checkRequiredAccount} from '../Root/Root';
import {checkAccountPermissions} from '../../utils/helpers';
import {Permissions} from '../../constants/enums';

const Header = styled.div`
    margin-bottom: 60px;
    display: flex;
    justify-content: flex-end;
`;

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
`;

export async function documentsAction({request, params}) {
    let formData = await request.formData();
    let intent = formData.get("intent");

    switch(intent) {
        case "add-document": {
            formData.delete("intent");
            return await postData(request, `patient/${params.patientUUID}/document`, formData, { type: 'formData' });
        }

        case "delete-document": {
            const documentId = formData.get("documentId");

            return await deleteData(request, `patient/${params.patientUUID}/document/${documentId}`);
        }

        default:
            return {"default": true};
    }
}

export async function documentsLoader({request, params}) {
    await checkRequiredAccount(Permissions.DOCUMENT_VIEW);

    const documentPromise = fetchProtectedData(request,`patient/${params.patientUUID}/document`);

    return defer({documentPromise});
}

const Documents = () => {
    const {account} = useRouteLoaderData("root");
    const {documentPromise} = useLoaderData();
    const {isOpen, handleOpen, handleClose} = useOpenModal();
    const [targetId, setTargetId] = useState(null);
    const handleDeleteButton = (id) => {
        setTargetId(id);
        handleOpen();
    }

    return (
        <>
            {checkAccountPermissions(account, Permissions.DOCUMENT_CREATE) &&
                <Header>
                    <AddDocument />
                </Header>
            }

            <React.Suspense fallback={
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            }>
                <Await resolve={documentPromise} errorElement={<Text>De documenten kunnen niet worden opgehaald. Probeer het opnieuw.</Text>}>
                    {(documentData) => {
                        if(documentData.length === 0) return <Text>Er zijn nog geen documenten toegevoegd.</Text>

                        return (
                            <>
                                <Table>
                                    <TableHead>
                                        <TableHeadRow>
                                            <TableHeadCell $flexGrow={2}>Documentnaam</TableHeadCell>
                                            <TableHeadCell>Datum upload</TableHeadCell>
                                            <TableHeadCell $flex="0" $minWidth="72px"></TableHeadCell>
                                            <TableHeadCell $flex="0" $minWidth="82px"></TableHeadCell>
                                        </TableHeadRow>
                                    </TableHead>

                                    {documentData.map((item, index) => {
                                        const isOddRow = index % 2 === 0;
                                        const isDeleted = Boolean(item.deletedAt);

                                        const createdAtDate = dayjs(item.createdAt);
                                        const formattedCreatedAtDate = createdAtDate.format("DD-MM-YYYY");

                                        const isClickable = !isDeleted && checkAccountPermissions(account, Permissions.DOCUMENT_READ);

                                        return (
                                            <TableRow key={item.id} $isClickable={isClickable}>
                                                <TableRowInner $isOddRow={isOddRow}>
                                                    <TableRowCell $variant="title" $flexGrow={2} $deleted={isDeleted}>
                                                        <ConditionalWrap condition={isDeleted} wrap={children => <s>{children}</s>}>
                                                            {item.title}
                                                        </ConditionalWrap>
                                                    </TableRowCell>
                                                    <TableRowCell $deleted={isDeleted}>{item.createdAt && formattedCreatedAtDate}</TableRowCell>
                                                    <TableRowCell $flex="0" $minWidth="72px">
                                                        {(!isDeleted && checkAccountPermissions(account, Permissions.DOCUMENT_DELETE)) &&
                                                            <TableRowButton onClick={() => handleDeleteButton(item.id)}><TrashIcon /></TableRowButton>
                                                        }
                                                    </TableRowCell>
                                                    <TableRowCell $flex="0" $minWidth="82px">
                                                        {isClickable &&
                                                            <LayeredCustomLink to={item.url} type="external"><ChevronIcon/></LayeredCustomLink>
                                                        }
                                                    </TableRowCell>
                                                </TableRowInner>
                                            </TableRow>
                                        );
                                    })}
                                </Table>

                                <DeleteDocument isOpen={isOpen} handleClose={handleClose} item={targetId} />
                            </>
                        );
                    }}
                </Await>
            </React.Suspense>
        </>
    );
}

export default Documents;