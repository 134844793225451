import {useEffect, useState} from 'react';
import Cookies from 'js-cookie';

export const useCookie = (key, initialValue = "") => {
    const [value, setValue] = useState(() => {
        const storedValue = Cookies.get(key);
        return Boolean(storedValue) ? storedValue : initialValue;
    });

    useEffect(() => {
        Cookies.set(key, value, { expires: 365, secure: true });
    }, [key, value]);

    return [value, setValue];
};