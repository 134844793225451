import styled from 'styled-components';
import {Text} from '../../../components/Text/Text';
import React from 'react';
import {Alert} from '../../../components/Alert/Alert';

const StyledResultAlertList = styled.div`
    margin-bottom: 30px;
    display: flex;
    gap: 10px;
`;

export const ResultAlertList = ({data}) => {
    return (
        <StyledResultAlertList>
            <Text $noMargin $bold>Signaleringen:</Text>
            {data?.alerts?.length > 0 ? (
                data?.alerts?.map((item, index) => (
                    <Alert key={`${item}-${index}`}>{item}</Alert>
                ))
            ) : (
                <Text $noMargin>Geen</Text>
            )}
        </StyledResultAlertList>
    );
}