import Tooltip from '../Tooltip/Tooltip';
import React, {useState} from 'react';
import {ReactComponent as CopyIcon} from '../../assets/icons/copy-icon2.svg';
import {ReactComponent as CheckIcon} from '../../assets/icons/check2.svg';
import styled from 'styled-components';
import {CSSTransition, SwitchTransition} from 'react-transition-group';

const StyledCopyButton = styled.button`
    background-color: rgba(var(--color-blue-50-rgb), .1);
    border: none;
    cursor: pointer;
    border-radius: 8px;
    padding: 10px;

    .icon-enter {
        opacity: 0;
        transform: scale(0.85);
    }
    .icon-enter-active {
        opacity: 1;
        transform: scale(1);
        transition: opacity .2s, transform .2s;
    }
    .icon-exit {
        opacity: 1;
        transform: scale(1);
    }
    .icon-exit-active {
        opacity: 0;
        transform: scale(0.85);
        transition: opacity .2s, transform .2s;
    }
`;

export function CopyButton() {
    const [tooltipText, setTooltipText] = useState('Kopieer');
    const [copied, setCopied] = useState(false);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(window.location.href);

        setTooltipText('Gekopieerd!');
        setCopied(true);
        setTimeout(() => {
            setTooltipText('Kopieer');
            setCopied(false);
        }, 1000);
    };

    return (
        <Tooltip content={tooltipText} placement={'bottom'}>
            <StyledCopyButton type="button" onClick={handleCopyClick}>
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        key={copied ? 'check' : 'copy'}
                        timeout={200}
                        classNames="icon"
                    >
                        {copied ? <CheckIcon /> : <CopyIcon />}
                    </CSSTransition>
                </SwitchTransition>
            </StyledCopyButton>
        </Tooltip>
    );
}