import {SideDrawer} from './SideDrawer';
import styled from 'styled-components';
import {Heading1} from '../Heading/Heading';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';

const HeaderWrapper = styled.header`
    padding: 0 30px;
`;

const Header = styled.div`
    padding: 16px 0;
    border-bottom: 2px solid var(--color-divider);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
`;

const StyledCloseIcon = styled(CloseIcon)`
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;
`;

const Box = styled.div`
    flex: 1 1 0;
`;

const Items = styled.div`
    display: flex;
    flex-direction: column;
`;

function NewsSideDrawer({isOpen, handleClose, children, ...props}) {
    return (
        <SideDrawer isOpen={isOpen} handleClose={handleClose} {...props}>
            <HeaderWrapper {...props}>
                <Header>
                    <Box>
                        <StyledCloseIcon onClick={handleClose} />
                    </Box>
                    <Box>
                        <Heading1 as="h2" $align="center" $noMargin>Meldingen</Heading1>
                    </Box>
                    <Box />
                </Header>
            </HeaderWrapper>
            <Items>
                {children}
            </Items>
        </SideDrawer>
    );
}

export default NewsSideDrawer;