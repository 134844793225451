import {Button, buttonVariants} from '../../../components/Button/Button';
import React, {useEffect, useState} from 'react';
import FormModal from '../../../components/Modal/FormModal';
import styled from 'styled-components';
import {useOpenModal} from '../../../hooks/useOpenModal';
import {Controller, useForm} from 'react-hook-form';
import {FormField, FormFieldWrapper} from '../../../components/FormFields/FormFields';
import {useFetcher, useParams} from 'react-router-dom';
import {Text} from '../../../components/Text/Text';
import {Statuses} from '../../../constants/enums';
import {PortalSelectField} from '../../../components/PortalFormFields/PortalSelectField/PortalSelectField';
import {PortalDateTimeField} from '../../../components/PortalFormFields/PortalDateTimeField/PortalDateTimeField';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {EditIcon, TableRowButton} from '../../../components/Table/Table';

const Box = styled.div`
    flex: 1 1 0;
`;

const StyledButton = styled(Button)`
    ${({$variant}) => buttonVariants($variant)};
    border: none;
    width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
    gap: 30px;
`;

dayjs.extend(customParseFormat)

export const EditPlannedNotification = ({item}) => {
    const {patientUUID} = useParams();
    const {isOpen, handleOpen, handleClose} = useOpenModal();

    const sendOptions = [
        { value: "send", label: "Direct versturen" },
        { value: "plan", label: "Inplannen" }
    ]

    const {register, control, handleSubmit, formState: { errors }, clearErrors} = useForm({
        defaultValues: {
            intent: "edit-planned-notification",
            title: item.title,
            select: sendOptions[1],
            pushAt: item.sendAt ? dayjs(item.sendAt, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss") : "",
            message: item.text
        }
    });

    const fetcher = useFetcher();
    const [status, setStatus] = useState(Statuses.IDLE);
    const [error, setError] = useState(false);

    const onSubmit = (data) => {
        clearErrors();

        const submitData = {
            intent: data.intent,
            id: item.id,
            title: data.title,
            pushAt: dayjs(item.sendAt, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss"),
            message: data.message,
        }

        setStatus(Statuses.SUBMITTING);
        setError(false);
        fetcher.submit(submitData, { method: "POST", action: `/patient/${patientUUID}/berichten/ingepland`});
    }

    // Fetcher callback
    useEffect(() => {
        if (fetcher?.state === "idle") {
            if (fetcher?.data?.error) {
                setStatus(Statuses.IDLE);
                return setError(true);
            }

            if (fetcher?.data) {
                setStatus(Statuses.IDLE);
                handleClose();
            }
        }
        //eslint-disable-next-line
    }, [fetcher]);

    const disabled = status === Statuses.SUBMITTING;

    return (
        <>
            <TableRowButton onClick={handleOpen}><EditIcon /></TableRowButton>

            <FormModal isOpen={isOpen} handleClose={handleClose}>
                <FormModal.Header handleClose={handleClose}>Pushbericht wijzigen</FormModal.Header>

                <Form id="edit-planned-notification-form" onSubmit={handleSubmit(onSubmit)}>
                    <FormField
                        type="TextField"
                        id="Title"
                        placeholder="Typ hier de titel (max. 65 karakters)"
                        label="Titel"
                        required={true}
                        maxLength={65}
                        disabled={disabled}
                        error={errors.title}
                        register={register}
                        name="title"
                    />

                    <FormFieldWrapper
                        id="SelectField"
                        label="Status"
                        error={errors.select}
                    >
                        <Controller
                            name="select"
                            control={control}
                            rules={{ required: true }}
                            disabled={true}
                            render={({field}) => {
                                return (
                                    <PortalSelectField
                                        {...field}
                                        options={sendOptions}
                                    />
                                )
                            }}
                        />

                    </FormFieldWrapper>

                    <FormFieldWrapper
                        id="DateField"
                        label="Datum en tijd"
                        error={errors.pushAt}
                    >
                        <Controller
                            name="pushAt"
                            control={control}
                            rules={{ required: true }}
                            disabled={true}
                            render={({
                                field: { onChange, value },
                            }) => {
                                return (
                                    <PortalDateTimeField
                                        defaultValue={value || ""}
                                        onChange={(date) => {
                                            onChange(date?.isValid ? date : "");
                                        }}
                                        disabled={true}
                                        placeholder="Kies een datum en tijd"
                                    />
                                )
                            }}
                        />
                    </FormFieldWrapper>

                    <FormField
                        type="TextArea"
                        id="Message"
                        placeholder="Typ hier je bericht (max. 200 karakters)"
                        label="Bericht"
                        required={true}
                        maxLength={200}
                        disabled={disabled}
                        error={errors.message}
                        register={register}
                        name="message"
                    />
                </Form>

                {error && <Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}

                <FormModal.Footer>
                    <Box><StyledButton type="button" onClick={handleClose} $variant="secondary">Annuleren</StyledButton></Box>
                    <Box><StyledButton type="submit" form="edit-planned-notification-form" disabled={disabled} loading={disabled}>Wijzigen</StyledButton></Box>
                </FormModal.Footer>
            </FormModal>
        </>
    );
}