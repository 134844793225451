import {Heading1} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import styled from 'styled-components';

const StyledFallback = styled.div`
    min-height: 400px;
`;

const Fallback = () => {
    return (
        <StyledFallback>
            <Heading1 $noMargin>Patient niet gevonden</Heading1>
            <Text>Ga naar het patiëntenoverzicht op het intranet.</Text>
        </StyledFallback>
    );
}

export default Fallback;