export const fetchPublicData = (request, endpoint) => {
    return fetch(`${process.env.REACT_APP_API_BASE_URL}/public/api/v1/${endpoint}`, {
        signal: request.signal,
    }).then((res) => {
        if (!res.ok) throw new Error();

        return res.json();
    }).catch((error) => {
        if (error?.name === "AbortError") return;
        return { "error": error };
    });
};

export const postPublicData = (request, endpoint, body, options = {}) => {
    return fetch(`${process.env.REACT_APP_API_BASE_URL}/public/api/v1/${endpoint}`, {
        method: "POST",
        body: body,
        headers: {
            ...(options?.type !== "formData") && {'Content-Type': 'application/json'},
        },
        signal: request.signal,
    }).then((res) => {
        if (!res.ok) throw new Error();

        return res.json();
    }).catch((error) => {
        if (error?.name === "AbortError") return;
        return { "error": error };
    });
};