import styled from 'styled-components';
import {SubHeading} from '../Heading/Heading';
import {useEffect, useRef} from 'react';
import {ReactComponent as SliderIcon} from '../../assets/icons/slider.svg';

const StyledLinearSliderField = styled.div``;

const InputRange = styled.input`
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
    outline: none;
    padding: 0;
    margin: 0;

    // Progress support
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));

    // Track styles
    &::-webkit-slider-runnable-track {
        background: linear-gradient(var(--color-slider), var(--color-slider)) 0/var(--sx) 100% no-repeat, var(--color-grey-20);
        height: 8px;
        border-radius: 4px;

    }
    
    &::-moz-range-track {
        background: linear-gradient(var(--color-slider), var(--color-slider)) 0/var(--sx) 100% no-repeat, var(--color-grey-20);
        height: 8px;
        border-radius: 4px;
    }
    
    // Thumb styles
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        margin-top: -6px;
        background: var(--color-slider);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid var(--color-white);
    }
    
    &::-moz-range-thumb {
        background: var(--color-slider);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid var(--color-white);
    }
`;

const Flags = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: calc(100% + 12px);
    margin-left: -6px;
`;

const Flag = styled.p`
    background-color: var(--color-slider);
    font-size: var(--fs-linear-flag);
    font-weight: var(--fw-bold);
    color: var(--color-grey-10);
    margin: 0;
    text-align: center;
    min-width: 28px;
    min-height: 28px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledSliderIcon = styled(SliderIcon)`
    width: 16px;
    height: 16px;
`;

const Steps = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
`;

const Step = styled.p`
    font-size: var(--fs-linear-slider-step);
    color: var(--color-text-alpha-50);
    margin: 0;
    text-align: center;
    min-width: 20px;
`;

const Labels = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    gap: 20px;
`;

const StyledSubHeading = styled(SubHeading).attrs({
    $noMargin: true,
})`
    color: var(--color-text-alpha-50);
`;

export const LinearSliderField = ({data}) => {
    const ref = useRef(null);
    const hasAnswer = Boolean(data.answer);

    useEffect(() => {
        if(ref?.current) {
            let e = ref.current;
            e.style.setProperty('--value', e.value);
            e.style.setProperty('--min', e.min === '' ? '0' : e.min);
            e.style.setProperty('--max', e.max === '' ? '10' : e.max);
            const handleInput = () => e.style.setProperty('--value', e.value);
            e.addEventListener('input', handleInput);

            return () => {
                e.removeEventListener('input', handleInput);
            }
        }
    }, [ref]);


    return (
        <StyledLinearSliderField>
            <Flags>
                {hasAnswer ? (
                    [...Array(10)].map((_, index) => (
                        data.answer === index + 1 ? <Flag key={index} $answer={data.answer}>{index + 1}</Flag> : <span key={index} />
                    ))
                ) : (
                    <>
                        <span />
                        <Flag><StyledSliderIcon/></Flag>
                        <span />
                    </>
                )}
            </Flags>
            <div>
                <InputRange ref={ref} type="range" value={hasAnswer ? data.answer : 2} min="1" max={hasAnswer ? 10 : 3} disabled />
            </div>
            <Steps>
                {[...Array(10)].map((_, index) => (
                    <Step key={index}>{index + 1}</Step>
                ))}
            </Steps>
            <Labels>
                <StyledSubHeading>{data.leftLabel}</StyledSubHeading>
                <StyledSubHeading>{data.rightLabel}</StyledSubHeading>
            </Labels>
        </StyledLinearSliderField>
    );
}