import {Text} from '../../../components/Text/Text';
import {ReactComponent as Check} from '../../../assets/icons/check.svg';
import styled from 'styled-components';

const StyledCompletedSuccessfully = styled.div`
    display: flex;
    gap: 12px;
`;

const StyledCheck = styled(Check)`
    width: 25px;
    height: 25px;
    flex-shrink: 0;
`;

const StyledText = styled(Text)`
    margin-top: 2px;
`;

export const CompletedSuccessfully = () => {
    return (
        <StyledCompletedSuccessfully>
            <StyledCheck />
            <StyledText $noMargin>De vragenlijst is afgerond. Bedankt voor het invullen van de vragenlijst!</StyledText>
        </StyledCompletedSuccessfully>
    );
}