export const parseDivaData = (inputArray) => {
    const structuredData = {
        part1: {
            introduction: inputArray[0],
            questionGroups: []
        },
        part2: {
            introduction: inputArray[46],
            questionGroups: []
        },
        part3criterionB: {
            introduction: inputArray[92],
            questions: [inputArray[93]]
        },
        part3criterionC: {
            introduction: inputArray[94],
            questions: inputArray.slice(95, 108)
        }
    };

    // Questions for part 1
    for (let i = 1; i <= 45; i++) {
        const part1QuestionGroup = `A${Math.ceil(i / 5)}`;
        const titleIndex = Math.ceil(i / 5) - 1;
        if (!structuredData.part1.questionGroups[titleIndex]) {
            structuredData.part1.questionGroups[titleIndex] = {
                title: part1QuestionGroup,
                questions: []
            };
        }
        structuredData.part1.questionGroups[titleIndex].questions.push(inputArray[i]);
    }

    // Questions for part 2
    for (let i = 47; i <= 91; i++) {
        const questionIndex = i - 47;
        const part2QuestionGroup = `H/I${Math.ceil((questionIndex + 1) / 5)}`;
        const titleIndex = Math.ceil((questionIndex + 1) / 5) - 1;
        if (!structuredData.part2.questionGroups[titleIndex]) {
            structuredData.part2.questionGroups[titleIndex] = {
                title: part2QuestionGroup,
                questions: []
            };
        }
        structuredData.part2.questionGroups[titleIndex].questions.push(inputArray[i]);
    }

    return structuredData;
}