import styled, {css} from 'styled-components';
import {Heading2} from '../Heading/Heading';
import {ScrollLink} from '../CustomLink/CustomLink';

export const TableOfContents = styled.div`
    position: fixed;
    top: 120px;
    transform: translateX(calc(-100% - 99px)); // -100% - padding-left of modal, - 1 px to prevent whitespace 
    background-color: var(--color-content-background);
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 10px 0 0 10px;
`;

export const TableOfContentsItem = styled(Heading2).attrs({
    as: ScrollLink,
    $noMargin: true
})`
    cursor: pointer;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: color .1s ease, background-color .1s ease;
    
    &:hover {
        background-color: var(--color-table-of-contents-hover);
    }
    
    ${({$active}) => $active && css`
        background-color: var(--color-table-of-contents-active);
        color: var(--color-white);
        
        &:hover {
            background-color: var(--color-primary-hover);
        }
    `};
`;