import {useRef, useState} from 'react';
import {useFlyOutMenu} from '../../hooks/useFlyOutMenu';
import {NavLink, useLocation} from 'react-router-dom';
import {ConditionalWrap} from '../Utils/Utils';
import Tooltip from '../Tooltip/Tooltip';
import styled, {css} from 'styled-components';
import {Heading3, SubHeading} from '../Heading/Heading';
import {ReactComponent as Chevron} from '../../assets/icons/chevron.svg';

const NavItemWrapper = styled.div`
    position: relative;
`;

const NavButton = styled(NavLink)`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 18px;
    border-radius: 8px;
    padding: 12px 14px;
    text-decoration: none;
    background: transparent;
    border: none;
    width: 100%;
    cursor: pointer;
    
    svg {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        
        ${({$useFill}) => $useFill ? css` path { fill: var(--color-primary); }` : css`path { stroke: var(--color-primary); }`}
    }
    
    &:hover { background-color: var(--color-aside-item-hover); } 

    &.active {
        background-color: var(--color-primary);
        
        svg {
            ${({$useFill}) => $useFill ? css`path { fill: var(--color-white); }` : css`path { stroke: var(--color-white); }`}
        }
    }
   
    // Extra hoverable space for fly out menu
    ${({$addExtraHoverableSpace}) => $addExtraHoverableSpace && css`
        &::after {
            display: block;
            content: "";
            height: 100%;
            width: 20px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: -20px;
        }
    `};
`;

// Also used in LogoutButton and LocationButton
export const NavItemLabel = styled(Heading3).attrs({
    as: "span",
    $noMargin: true
})`
    justify-content: space-between;
    align-items: center;
    color: var(--color-primary);
    white-space: nowrap;
    display: flex;
    width: 100%;
    
    ${NavButton}.active & {
        color: var(--color-white);
    }
`;

const DisclosureChevron = styled(Chevron)`
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
    transition: transform .2s ease;
    
    path { fill: var(--color-primary); }

    ${NavButton}.active & {
        path { fill: var(--color-white); }
    }
    
    ${({$isDisclosureOpen}) => $isDisclosureOpen && "transform: rotate(270deg)"}
`;

const SubItems = styled.div`
    width: max-content;
    position: absolute;
    top: 0;
    left: 58px;
    border: 1px solid var(--color-grey-20);
    background-color: var(--color-white);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    box-shadow: var(--box-shadow);
`;

const SubNavLink = styled(SubHeading).attrs({
    as: NavLink,
    end: true,
    $noMargin: true,
})`
    color: var(--color-primary);
    border-radius: 6px;
    padding: 12px;
    text-decoration: none;
    line-height: 1;
    white-space: nowrap;

    &:hover { background-color: var(--color-aside-item-hover); }

    &.active {
        background-color: var(--color-primary);
        color: var(--color-white);
    }
`;

const Disclosure = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 8px;
    padding-left: 38px;

    &:before {
        display: block;
        content: "";
        position: absolute;
        top: 8px;
        left: 25px;
        width: 2px;
        height: calc(100% - 35px); // 100% - 16px (8 padding top and 8 from SubNavLink's ::before) - half of last item's height
        background-color: var(--color-aside-item-accent);
    }
`;

const DisclosureNavLink = styled(SubNavLink)`
    position: relative;
    white-space: unset;
    line-height: 1.25;
    padding: 10px 12px;

    &:before {
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(calc(-50% - 4px));
        left: -13px;
        width: 11px;
        height: 8px;
        border-bottom: 2px solid var(--color-aside-item-accent);
        border-left: 2px solid var(--color-aside-item-accent);
        border-bottom-left-radius: 8px;
    }
`;

const InnerDisclosureNavLink = styled.span`
    display: block;
    width: 177px; // To prevent word break during transition
`;

export function NavItem({expanded, collapseSideBar, label, to, subItems, useFill, children}) {
    // When NavItem has subItems it should show a fly out menu
    const ref = useRef(null);
    const {open, setOpen} = useFlyOutMenu(ref);

    // When NavItem has subItems, it should be active when the link starts with prefix passed in "to" prop
    const {pathname} = useLocation();
    const hasSubItems = subItems?.length > 0;
    const subItemIsActive = hasSubItems && pathname?.startsWith(to);

    const [isDisclosureOpen, setIsDisclosureOpen] = useState((hasSubItems && subItemIsActive))

    return (
        <NavItemWrapper ref={ref}>
            <ConditionalWrap condition={(!expanded && !hasSubItems)} wrap={children => <Tooltip content={label} offsetX={"10px"}>{children}</Tooltip>}>
                <NavButton
                    as={(hasSubItems && expanded) ? "button" : undefined}
                    to={(hasSubItems && expanded) ? undefined : to}
                    onClick={hasSubItems ? () => setIsDisclosureOpen(!isDisclosureOpen) : collapseSideBar}
                    className={subItemIsActive ? "active" : undefined}
                    $useFill={useFill}
                    $addExtraHoverableSpace={open && hasSubItems}
                >
                    {children}

                    {expanded &&
                        <NavItemLabel>
                            {label}
                            {hasSubItems && <DisclosureChevron $isDisclosureOpen={isDisclosureOpen} />}
                        </NavItemLabel>
                    }

                </NavButton>
            </ConditionalWrap>

            {/* Fly out when collapsed */}
            {(open && hasSubItems && !expanded) &&
                <SubItems>
                    {subItems?.map(item => (
                        <SubNavLink
                            key={item.label}
                            to={item.to}
                            onClick={() => setOpen(false)}
                        >
                            {item.label}
                        </SubNavLink>
                    ))}
                </SubItems>
            }

            {/* Disclosure when expanded */}
            {(isDisclosureOpen && hasSubItems && expanded) &&
                <Disclosure>
                    {subItems?.map(item => (
                        <DisclosureNavLink
                            key={item.label}
                            to={item.to}
                            onClick={() => setOpen(false)}
                        >
                            <InnerDisclosureNavLink>
                                {item.label}
                            </InnerDisclosureNavLink>
                        </DisclosureNavLink>
                    ))}
                </Disclosure>
            }
        </NavItemWrapper>
    );
}