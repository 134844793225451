import styled from 'styled-components';

export const Container = styled.section`
    max-width: ${props => props.$maxWidth ?? '1000px'};
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
`;

export const ConditionalWrap = ({condition, wrap, fallback, children}) => {
    return condition ? wrap(children) : fallback ? fallback(children) : children;
}