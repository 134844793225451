import styled from 'styled-components';
import checkboxInactive from '../../assets/icons/checkbox-inactive.svg';
import checkboxChecked from '../../assets/icons/checkbox-checked.svg';
import checkboxCheckedDisabled from '../../assets/icons/checkbox-checked-disabled.svg';
import radioCheck from '../../assets/icons/radio-check.svg';

export const Input = styled.input`
    width: 100%;
    min-height: 46px;
    padding: 13px 17px;
    padding-left: ${(props) => props.paddingLeft ? props.paddingLeft : 'auto'};
    border-radius: 20px;
    color: var(--color-text);
    font-size: var(--fs-text);
    background-color: var(--color-white);
    appearance: none;
    outline: none;
    border: none;
    box-shadow: var(--box-shadow);
    
    &:disabled {
        color: var(--color-text-alpha-50);
        background-color: var(--color-input-background);
        box-shadow: unset;
    }

    ::placeholder {
        color: var(--color-dark);
        opacity: 0.3;
        font-style: italic;
    }
`;

export const Checkbox = styled.input`
    appearance: none;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    content: url(${checkboxInactive});

    &:not(:disabled):checked {
        content: url(${checkboxChecked});
    }

    &:disabled:checked {
        content: url(${checkboxCheckedDisabled});
    }
`;

export const RadioButton = styled.input`
    border: 1px solid #EAECF4;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    background-color: var(--color-white);
    border-radius: 50%;
    cursor: pointer;
    appearance: none;
    
    &:checked {
        border: 1px solid var(--color-primary);
        background-color: var(--color-primary);
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(${radioCheck});
        background-size: 70%;
    }
`;