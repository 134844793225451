import {ReactComponent as ExclamationMark} from '../../assets/icons/exclamation-mark.svg';
import styled from 'styled-components';
import {Text} from '../Text/Text';

const StyledAlert = styled.div``;

const ExclamationMarkIcon = styled(ExclamationMark)`
    width: 20px;
    height: 20px;
    vertical-align: bottom;
    margin-right: 10px;
`;

const StyledText = styled(Text).attrs({
    $bold: true,
    $noMargin: true
})`
    display: inline-block;
    color: var(--color-alert);
`;

export const Alert = ({children}) => {
    return (
        <StyledAlert>
            <ExclamationMarkIcon />
            <StyledText>{children}</StyledText>
        </StyledAlert>
    );
}