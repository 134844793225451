import {Input} from '../Input/Input';
import styled from 'styled-components';

const StyledMultiTextInputField = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const MultiTextInputField = ({data}) => {
    return (
        <StyledMultiTextInputField>
            {data?.answer?.map((item, index) => (
                <Input
                    key={index}
                    type="text"
                    defaultValue={item}
                    disabled
                    $noMargin
                />
            ))}
        </StyledMultiTextInputField>
    );
}