import {useEffect, useState} from "react";
import styled from "styled-components";
import GreyCross from "../../assets/icons/grey-cross.svg"
import GreyCheck from "../../assets/icons/grey-check.svg"

const StyledLabel = styled.label`
    cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
    text-indent: -9999px;
    width: 46px;
    height: 29px;
    background: ${({ checked }) => checked ? 'var(--color-toggle-checked)' : 'var(--color-toggle-unchecked)'};
    display: block;
    border-radius: 100px;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        left: ${({checked}) => (!checked ? "3px" : "calc(55% - 5px)")};
        top: 3px;
        width: 23px;
        height: 23px;
        background: #fff ${({ checked }) => (!checked ? `url(${GreyCross})` : `url(${GreyCheck})`)};
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 50%;
        border-radius: 90px;
        transition: 0.3s;
    }
    
    &[disabled] {
        opacity: 0.5;
    }
`;

const Toggle = ({ id, initialValue = false, onChange, disabled }) => {
    const [checked, setChecked] = useState(initialValue);

    useEffect(() => {
        setChecked(initialValue);
    }, [initialValue]);

    function handleOnChange(e) {
        const newState = e.target.checked;
        setChecked(newState);
        if (onChange) {
            onChange(id, newState);
        }
    }

    return (
        <StyledLabel htmlFor={`checkbox-${id}`} checked={checked} disabled={disabled}>
            <input
                id={`checkbox-${id}`}
                type="checkbox"
                checked={checked}
                onChange={handleOnChange}
                disabled={disabled}
            />
        </StyledLabel>
    );
};

export default Toggle