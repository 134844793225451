import {Spinner, SpinnerOverlay, SpinnerWrapper} from '../../components/Spinner/Spinner';
import {Await, defer, useLoaderData, useNavigation, useOutletContext, useRouteLoaderData} from 'react-router-dom';
import {Text} from '../../components/Text/Text';
import Pagination from '../../components/Pagination';
import {ChevronIcon, FilterIcon, Table, TableHead, TableHeadCell, TableHeadRow, TableRow, TableRowCell, TableRowInner} from '../../components/Table/Table';
import dayjs from 'dayjs';
import {UsedFiltersList} from '../../components/AppliedFilters/UsedFiltersList';
import {LayeredCustomLink} from '../../components/CustomLink/CustomLink';
import React from 'react';
import {checkRequiredAccount} from '../Root/Root';
import {Permissions} from '../../constants/enums';
import Cookies from 'js-cookie';
import qs from 'qs';
import {fetchProtectedData} from '../../api/fetch';
import styled from 'styled-components';
import Tooltip from '../../components/Tooltip/Tooltip';

const StyledTable = styled(Table)`
    // CSS hack to fix tooltip overflow
    padding-bottom: 200px;
    margin-bottom: -200px;
`;

const StyledTooltip = styled(Tooltip)`
    width: max-content;
    max-width: calc(100vw - 400px);
`;

const RelativeWrapper = styled.div`
    position: relative;
`;

export async function sentNotificationCenterMessagesLoader({request}) {
    await checkRequiredAccount([Permissions.PORTAL_ACCESS_NOTIFICATION_CENTER_OVERVIEW, Permissions.NOTIFICATION_VIEW]);

    const url = new URL(request.url);
    const searchParams = new URLSearchParams(url?.search)
    const page = searchParams.get("page") ?? 1;
    const location = Cookies.get('locationFilter');

    const queryString = qs.stringify({
        ...(page && {page}),
        ...(location && {location}),
        isSent: true,
    });

    const notificationCenterMessagesPromise = fetchProtectedData(request,`group-notification${queryString ? `?${queryString}` : ""}`);

    return defer({notificationCenterMessagesPromise});
}

export async function plannedNotificationCenterMessagesLoader({request}) {
    await checkRequiredAccount([Permissions.PORTAL_ACCESS_NOTIFICATION_CENTER_OVERVIEW, Permissions.NOTIFICATION_VIEW]);

    const url = new URL(request.url);
    const searchParams = new URLSearchParams(url?.search)
    const page = searchParams.get("page") ?? 1;
    const location = Cookies.get('locationFilter');

    const queryString = qs.stringify({
        ...(page && {page}),
        ...(location && {location}),
        isSent: false,
    });

    const notificationCenterMessagesPromise = fetchProtectedData(request,`group-notification${queryString ? `?${queryString}` : ""}`);

    return defer({notificationCenterMessagesPromise});
}

// NOTE: This route is used with a different loader for Sent and Planned notifications!
const NotificationCenterMessagesList = () => {
    const {locationData} = useRouteLoaderData("portal");
    const {notificationCenterMessagesPromise} = useLoaderData();
    const {healthInsuranceData} = useOutletContext();

    const navigation = useNavigation();
    const isPending = navigation?.location?.pathname?.includes("/berichtencentrum/verstuurde-berichten");

    return (
        <React.Suspense fallback={
            <SpinnerWrapper>
                <Spinner />
            </SpinnerWrapper>
        }>
            <Await resolve={notificationCenterMessagesPromise} errorElement={<Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}>
                {(notificationCenterMessagesData) => {
                    return (
                        <RelativeWrapper>
                            {notificationCenterMessagesData?.items?.length > 0 ? (
                                <>
                                    <Pagination searchResults={notificationCenterMessagesData} $position="top" />
                                    <StyledTable>
                                        <TableHead>
                                            <TableHeadRow>
                                                <TableHeadCell $flex="0" $minWidth="196px">Datum</TableHeadCell>
                                                <TableHeadCell $flex="0" $minWidth="156px">Status</TableHeadCell>
                                                <TableHeadCell>Titel</TableHeadCell>
                                                <TableHeadCell>Bericht</TableHeadCell>
                                                <TableHeadCell $flex="0" $minWidth="128px">Aantal</TableHeadCell>
                                                <TableHeadCell $flex='0' $minWidth='8px'></TableHeadCell>
                                                <TableHeadCell $flex='0' $minWidth='8px'></TableHeadCell>
                                            </TableHeadRow>
                                        </TableHead>
                                        {notificationCenterMessagesData?.items?.map((item, rowIndex) => {
                                            return (
                                                <TableRow key={`row-${rowIndex}`} $isClickable>
                                                    <TableRowInner $isOddRow={rowIndex % 2 === 0}>
                                                        <TableRowCell $flex="0" $minWidth="196px">{ dayjs(item.sendAt).format('DD-MM-YYYY HH:mm')}</TableRowCell>
                                                        <TableRowCell $flex="0" $minWidth="156px">{ item.sentAt ? 'Verstuurd' : 'Ingepland' }</TableRowCell>
                                                        <TableRowCell $variant="title">{ item.title }</TableRowCell>
                                                        <TableRowCell>{ item.text }</TableRowCell>
                                                        <TableRowCell $flex="0" $minWidth="128px">{ item.count }</TableRowCell>
                                                        <TableRowCell $flex="0" $padding="16px 0" $minWidth="40px">
                                                            { !!item.usedFilters?.length &&
                                                                <StyledTooltip
                                                                    content={
                                                                        <UsedFiltersList locationData={locationData} healthInsuranceData={healthInsuranceData} usedFilters={item.usedFilters} />
                                                                    }
                                                                    orientation={'left'}
                                                                    backgroundColor="var(--color-grey-10)"
                                                                >
                                                                    <FilterIcon />
                                                                </StyledTooltip>
                                                            }
                                                        </TableRowCell>
                                                        <TableRowCell $flex="0" $padding="16px 0" $minWidth="40px">
                                                            <LayeredCustomLink to={`/berichtencentrum/verstuurde-berichten/${item.id}`}><ChevronIcon /></LayeredCustomLink>
                                                        </TableRowCell>
                                                    </TableRowInner>
                                                </TableRow>
                                            );
                                        })}
                                    </StyledTable>
                                    <Pagination searchResults={notificationCenterMessagesData} />
                                </>
                            ) : (
                                <Text>Geen resultaten gevonden</Text>
                            )}

                            {isPending &&
                                <SpinnerOverlay>
                                    <SpinnerWrapper>
                                        <Spinner />
                                    </SpinnerWrapper>
                                </SpinnerOverlay>
                            }
                        </RelativeWrapper>
                    );
                }}
            </Await>
        </React.Suspense>
    );
}

export default NotificationCenterMessagesList;