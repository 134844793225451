import React, {useEffect, useState} from 'react';
import {useFetcher, useParams} from 'react-router-dom';
import {Statuses} from '../../../constants/enums';
import ConfirmationModal, {CancelButton} from '../../../components/Modal/ConfirmationModal';
import {Text} from '../../../components/Text/Text';
import styled from 'styled-components';
import {Button} from '../../../components/Button/Button';

const StyledButton = styled(Button)`
    border: none;
    width: 100%;
    margin-top: 20px;
`;

export const RegisterReservation = ({isOpen, handleClose, item}) => {
    const fetcher = useFetcher();
    const {patientUUID} = useParams();

    const [status, setStatus] = useState(Statuses.IDLE);
    const [error, setError] = useState(false);

    const handleRegisterReservation = () => {
        setStatus(Statuses.SUBMITTING);
        setError(false);

        const formData = new FormData();
        formData.append("intent", "register-reservation");
        formData.append("participantId", item);
        fetcher.submit(formData, { method: "POST", action: `/patient/${patientUUID}/afspraken`});
    }

    // Fetcher callback
    useEffect(() => {
        if(fetcher?.state === "idle" && (fetcher?.data || fetcher?.data === "")) {
            setStatus(Statuses.IDLE);

            if(fetcher?.data?.error) return setError(true);

            handleClose();
        }
        //eslint-disable-next-line
    }, [fetcher]);

    const disabled = status === Statuses.SUBMITTING;

    useEffect(() => {
        if(isOpen) setError(false);
    }, [item, isOpen]);

    return (
        <ConfirmationModal isOpen={isOpen} handleClose={handleClose}>
            <ConfirmationModal.Header>Reservering verwerken</ConfirmationModal.Header>
            <Text $noMargin $align="center">Weet je zeker dat je de reservering wil verwerken?</Text>
            <StyledButton type="button" onClick={handleRegisterReservation} disabled={disabled} loading={disabled}>Ja, verwerken</StyledButton>
            <CancelButton onClick={handleClose}>Annuleren</CancelButton>
            {error && <Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}
        </ConfirmationModal>
    );
}