import {checkRequiredAccount} from '../Root/Root';
import {useLoaderData} from 'react-router-dom';
import styled from 'styled-components';

const Outer = styled.div`
    position: absolute;
    width: ${({$width}) => $width ?? 'calc(100% - 64px)' };
    height: ${({$height}) => $height ?? 'calc(100vh - 32px)' };
    left: 32px;
    top: 32px;
    background-color: var(--color-background);
    border-radius: 4px;

    @supports not (aspect-ratio: 16 / 9) {
        overflow: hidden;
        padding-top: 56.25%;
    }
`;

const Iframe = styled.iframe`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    border: none;

    @supports not (aspect-ratio: 16 / 9) {
        position: absolute;
        inset: 0;
    }
`;

export async function patientsOverviewLoader() {
    const {account} = await checkRequiredAccount();

    if(!account?.profession) {
        throw new Response("Forbidden", { status: 403});
    }

    return {account};
}

const PatientsOverview = () => {
    const {account} = useLoaderData();

    switch (account?.profession) {
        case "Arts":
        case "Physician assistant":
        case "Verpleegkundig specialist GGZ":
        case "Verpleegkundige":
            return (
                <Outer>
                    <Iframe
                        src="https://lookerstudio.google.com/embed/reporting/3d07f642-e7eb-4683-ae39-88825170d59d/page/U1GHD"
                        frameBorder="0"
                        allowFullScreen
                        height="8000px"
                        style={{height: "8000px"}}
                        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                    />
                </Outer>
            );
        case "Psychiater":
            return (
                <Outer>
                    <Iframe
                        src="https://lookerstudio.google.com/embed/reporting/6bfcb46b-29a3-4ac4-a97d-142e296bceea/page/A7gpD"
                        frameBorder="0"
                        allowFullScreen
                        style={{height: "17000px"}}
                        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                    />
                </Outer>
            );
        case "Gz-psycholoog":
        case "WO-psycholoog":
            return (
                <Outer>
                    <Iframe
                        src="https://lookerstudio.google.com/embed/reporting/a78c21a0-9618-457b-b0d6-8e1076bd2df1/page/9KjKD"
                        frameBorder="0"
                        allowFullScreen
                        height="8000px"
                        style={{height: "8000px"}}
                        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                    />
                </Outer>
            );
        default:
            return (
                <Outer>
                    <p>Je hebt geen toegang tot het patienten overzicht</p>
                </Outer>
            )
    }
}

export default PatientsOverview