import {checkRequiredAccount} from "../Root/Root";
import {Permissions} from "../../constants/enums";
import {Await, defer, useLoaderData, useParams, useRouteLoaderData} from "react-router-dom";
import {deleteData, fetchProtectedData, postData} from "../../api/fetch";
import {useOpenModal} from "../../hooks/useOpenModal";
import {checkAccountPermissions} from "../../utils/helpers";
import React, {useState} from "react";
import {Spinner} from "../../components/Spinner/Spinner";
import {Text} from "../../components/Text/Text";
import {Table, TableHead, TableHeadCell, TableHeadRow, TableRegisterButton, TableRow, TableRowButton, TableRowCell, TableRowInner, TrashIcon} from "../../components/Table/Table";
import dayjs from "dayjs";
import styled from "styled-components";
import {DeleteReservation} from "./Partials/DeleteReservation";
import {Button} from "../../components/Button/Button";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Tooltip from "../../components/Tooltip/Tooltip";
import {ConditionalWrap} from '../../components/Utils/Utils';
import {RegisterReservation} from './Partials/RegisterReservation';

const Header = styled.div`
    margin-bottom: 60px;
    display: flex;
    justify-content: flex-end;
`;

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
`;

const StyledTable = styled(Table)`
    // CSS hack to fix tooltip overflow
    padding-bottom: 200px;
    margin-bottom: -200px;
`;

const StyledTooltip = styled(Tooltip)`
    width: max-content;
    max-width: 240px;
    white-space: unset;
    text-align: left;
    padding: 10px 12px;
`;

const StyledButton = styled(Button)`
    min-width: 200px;
    height: 45px;
`;

dayjs.extend(customParseFormat)

export async function appointmentsAction({request}){
    let formData = await request.formData();
    let intent = formData.get("intent");

    const participantId = formData.get("participantId");
    switch(intent) {
        case "remove-reservation": {
            return await deleteData(request, `reservation/${participantId}`);
        }
        case "register-reservation": {
            return await postData(request, `reservation/${participantId}/register`, JSON.stringify({}))
        }
        default:
            return {"default": true};
    }
}

export async function appointmentsLoader({request, params}){
    await checkRequiredAccount(Permissions.APPOINTMENT_VIEW);

    const appointmentPromise = fetchProtectedData(request,`patient/${params.patientUUID}/appointments`);

    return defer({appointmentPromise});
}

const Appointments = () => {
    const {account} = useRouteLoaderData("root");
    const {appointmentPromise} = useLoaderData();
    const {patientUUID} = useParams()

    const {isOpen: removeReservationIsOpen, handleOpen: removeReservationHandleOpen, handleClose: removeReservationHandleClose} = useOpenModal();
    const [removeReservationId, setRemoveReservationId] = useState(null);
    const handleRemoveButton = (id) => {
        setRemoveReservationId(id);
        removeReservationHandleOpen();
    }

    const {isOpen: registerReservationIsOpen, handleOpen: registerReservationHandleOpen, handleClose: registerReservationHandleClose} = useOpenModal();
    const [registerReservationId, setRegisterReservationId] = useState(null);
    const handleRegisterButton = (id) => {
        setRegisterReservationId(id);
        registerReservationHandleOpen();
    }

    return (
        <>
            {checkAccountPermissions(account, Permissions.APPOINTMENT_CREATE) &&
                <Header>
                    <React.Suspense fallback={
                        <StyledButton to={`/patient/${patientUUID}/afspraak-reserveren`} loading={true} />
                    }>
                        <Await resolve={appointmentPromise} errorElement={<Button to={`/patient/${patientUUID}/afspraak-reserveren`}>Diagnostiekdag reserveren</Button>}>
                            {(appointmentData) => {
                                return (
                                    <Button to={`/patient/${patientUUID}/afspraak-reserveren`}>{appointmentData?.length > 0 ? "Opnieuw plannen" : "Diagnostiekdag reserveren"}</Button>
                                );
                            }}
                        </Await>
                    </React.Suspense>
                </Header>
            }

            <React.Suspense fallback={
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            }>
                <Await resolve={appointmentPromise} errorElement={<Text>De afspraken kunnen niet worden opgehaald. Probeer het opnieuw.</Text>}>
                    {(appointmentData) => {
                        if(appointmentData.length === 0) return <Text>Er zijn nog geen afspraken gereserveerd.</Text>

                        return (
                            <>
                                <StyledTable>
                                    <TableHead>
                                        <TableHeadRow>
                                            <TableHeadCell>Datum</TableHeadCell>
                                            <TableHeadCell>Status</TableHeadCell>
                                            <TableHeadCell>Agenda</TableHeadCell>
                                            <TableHeadCell>Gereserveerd op</TableHeadCell>
                                            <TableHeadCell $flex="0" $minWidth="156px" />
                                            <TableHeadCell $flex="0" $padding="0 0 15px 0" $minWidth="48px"></TableHeadCell>
                                        </TableHeadRow>
                                    </TableHead>

                                    {appointmentData.map((item, index) => {
                                        const isOddRow = index % 2 === 0;

                                        const date = dayjs(item.date, "DD/MM/YYYY").format("DD-MM-YYYY");
                                        const reservedAt = item?.reservation?.reservedAt && dayjs(item?.reservation?.reservedAt, "DD/MM/YYYY").format("DD-MM-YYYY");

                                        return (
                                            <TableRow key={`${date}-${index}`}>
                                                <TableRowInner $isOddRow={isOddRow}>
                                                    <TableRowCell>{date} {item?.time}</TableRowCell>
                                                    <TableRowCell>{item.reservation?.status}</TableRowCell>
                                                    <TableRowCell>{item.resource}</TableRowCell>
                                                    <TableRowCell>{reservedAt}</TableRowCell>
                                                    <TableRowCell $flex="0" $minWidth="156px">
                                                        {(checkAccountPermissions(account, Permissions.PARTICIPANT_VIEW)) &&
                                                            item?.reservation?.status !== "Ingepland" && <TableRegisterButton onClick={() => handleRegisterButton(item.reservation?.reservedBy?.participantId)}>{item?.reservation?.status === "Gereserveerd" ? "Verwerken" : "Opnieuw"}</TableRegisterButton>
                                                        }
                                                    </TableRowCell>
                                                    <TableRowCell $flex="0" $padding="16px 0" $minWidth="48px">
                                                        {(checkAccountPermissions(account, [Permissions.PARTICIPANT_DELETE, Permissions.APPOINTMENT_DELETE])) &&
                                                            <ConditionalWrap
                                                                condition={item?.reservation?.status === "Ingepland"}
                                                                wrap={children => (
                                                                    <StyledTooltip
                                                                        content="Deze afspraak is reeds ingepland, dus moet via Medicore worden verwijderd."
                                                                        orientation="left"
                                                                    >
                                                                        {children}
                                                                    </StyledTooltip>
                                                                )}>
                                                                <TableRowButton disabled={item.reservation?.status === "Ingepland"} onClick={() => item.reservation?.status !== "Ingepland" ? handleRemoveButton(item.reservation?.reservedBy?.participantId) : undefined}><TrashIcon /></TableRowButton>
                                                            </ConditionalWrap>
                                                        }
                                                    </TableRowCell>
                                                </TableRowInner>
                                            </TableRow>
                                        );
                                    })}
                                </StyledTable>

                                <DeleteReservation isOpen={removeReservationIsOpen} handleClose={removeReservationHandleClose} item={removeReservationId} />
                                <RegisterReservation isOpen={registerReservationIsOpen} handleClose={registerReservationHandleClose} item={registerReservationId} />
                            </>
                        );
                    }}
                </Await>
            </React.Suspense>
        </>
    );
}

export default Appointments;