import styled from 'styled-components';
import {FormField} from '../FormFields/FormFields';

const StyledFormFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;
`;

export const DefaultFormFields = ({currentStepData, errors, register, watch, control, onFormFieldBlur, identifier}) => {
    return (
        <StyledFormFields>
            {currentStepData?.items.map(item => (
                <FormField
                    key={item.id}
                    type={item.type}
                    id={item.id}
                    label={item.title}
                    required={item.required}
                    description={item.description}
                    disabled={false}
                    error={errors[item.id]}
                    register={register}
                    watch={watch}
                    control={control}
                    onFormFieldBlur={onFormFieldBlur}
                    name={item.id}
                    data={item}
                    identifier={identifier}
                />
            ))}
        </StyledFormFields>
    );
}

export const DefaultFormFieldsAnswers = ({data}) => {
    return (
        <StyledFormFields>
            {data.map(item => (
                <FormField
                    key={item.id}
                    type={item.type}
                    id={item.id}
                    label={item.title}
                    required={item.required}
                    description={item.description}
                    disabled={true}
                    data={item}
                />
            ))}
        </StyledFormFields>
    );
}