import {fetchPublicData, postPublicData} from '../../api/public';
import {Outlet, useFetcher, useLoaderData, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useOpenModal} from '../../hooks/useOpenModal';
import {useForm} from 'react-hook-form';
import {Statuses} from '../../constants/enums';
import {CompletedSuccessfully} from './Partials/CompletedSuccessfully';
import {AlreadyFilledIn} from './Partials/AlreadyFilledIn';

export async function publicQuestionnaireAction({request, params}) {
    const url = new URL(request.url);
    const signature = encodeURIComponent(url?.searchParams?.get('c'));
    let formData = await request.formData();
    let intent = formData.get("intent");

    switch(intent) {
        case "fill-in-public-questionnaire": {
            formData.delete("intent");
            return await postPublicData(request, `questionnaires/${params.uuid}?signature=${signature}`, formData, { type: 'formData' });
        }

        default:
            return {"default": true};
    }
}

export async function publicQuestionnaireLoader({request, params}) {
    const url = new URL(request.url);
    const signature = encodeURIComponent(url?.searchParams?.get('c'));

    const publicQuestionnaireData = await fetchPublicData(request, `questionnaires/${params.uuid}?signature=${signature}`);

    if(publicQuestionnaireData?.error) throw new Response("", { status: 404 });

    return {publicQuestionnaireData};
}

const PublicQuestionnaire = () => {
    const {publicQuestionnaireData} = useLoaderData();
    const {uuid, step} = useParams();
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();
    const signature = searchParams.get('c');
    const encodedSignature = encodeURIComponent(signature);

    // Handle step index and state if form should really be submitted (every next step is submit for validation)
    const [stepIndex, setStepIndex] = useState(0);
    const [shouldSubmitForm, setShouldSubmitForm] = useState(false);
    const shouldNavigateToNextStep = stepIndex + 1 < publicQuestionnaireData?.steps?.length;
    const shouldSoftSubmitForm = stepIndex + 1 >= publicQuestionnaireData?.steps?.length;

    const handlePreviousClick = () => {
        setStepIndex(stepIndex - 1);
    }

    // When reloading on a step page, redirect back to introduction page
    useEffect(() => {
        if(stepIndex === 0 && step >= 1) {
            navigate(`/vragenlijst/${uuid}?c=${encodedSignature}`);
        }
        //eslint-disable-next-line
    }, []);

    // If step (from URL) is not the same as the stepIndex + 1, a user navigated with browser prev/next btns. Set the step to the correct index based on the step from URL
    useEffect(() => {
        if(step && (parseInt(step) !== stepIndex + 1)) {
            setStepIndex(parseInt(step) - 1);
        }
    }, [step, stepIndex])

    // Before submitting, open the confirmation modal
    const {isOpen, handleOpen, handleClose} = useOpenModal();

    const extendedHandleClose = () => {
        setShouldSubmitForm(false);
        handleClose();
    }

    // Form initialization and handle submission
    const {register, handleSubmit, formState: { errors }, watch} = useForm({
        defaultValues: {
            intent: "fill-in-public-questionnaire",
        }
    });

    const fetcher = useFetcher();
    const [status, setStatus] = useState(Statuses.IDLE);
    const [error, setError] = useState(false);

    const onSubmit = (data) => {
        if(shouldNavigateToNextStep) {
            let newStepIndex = stepIndex + 1;
            setStepIndex(newStepIndex);
            navigate(`/vragenlijst/${uuid}/${newStepIndex + 1}?c=${encodedSignature}`);
        }

        if (shouldSoftSubmitForm && !shouldSubmitForm) {
            setShouldSubmitForm(true);
            handleOpen();
        }

        if(shouldSubmitForm) {
            setStatus(Statuses.SUBMITTING);
            setError(false);

            const filteredData = Object.fromEntries(
                Object.entries(data).map(([key, value]) => {
                    if (Array.isArray(value)) {
                        // Format the checkbox array to the expected JSON structure for CheckboxesField
                        const arr = value.map((id) => {
                            if (data.hasOwnProperty(`other-${id}`)) {
                                return {
                                    id,
                                    value: data[`other-${id}`]
                                };
                            } else {
                                return { id };
                            }
                        });
                        return [key, arr?.length > 0 ? JSON.stringify(arr) : JSON.stringify([])];
                    } else if (typeof value === 'object' && value !== null) {
                        // Filter out unchecked RadioButtonsField
                        return [key, value?.id ? JSON.stringify(value) : JSON.stringify({})];
                    } else {
                        // Filter out null values
                        return [key, value ? value : ""];
                    }
                })
            );

            fetcher.submit(filteredData, { method: "POST", action: `/vragenlijst/${uuid}?c=${encodedSignature}`})
        }
    }

    // Fetcher callback
    const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

    useEffect(() => {
        if(fetcher?.data?.error) {
            setStatus(Statuses.IDLE);
            return setError(true);
        }

        if(fetcher?.data) {
            handleClose();
            setIsSuccessfullySubmitted(true);
        }
        //eslint-disable-next-line
    }, [fetcher]);

    // Handle different view states if necessary
    if(isSuccessfullySubmitted) return <CompletedSuccessfully />
    if(publicQuestionnaireData?.done) return <AlreadyFilledIn />

    return (
        <form id="fill-in-public-questionnaire-form" onSubmit={handleSubmit(onSubmit)}>
            <Outlet context={{
                publicQuestionnaireData,
                encodedSignature,
                stepIndex,
                errors,
                register,
                watch,
                shouldSoftSubmitForm,
                isOpen,
                extendedHandleClose,
                status,
                error,
                handlePreviousClick
            }} />
        </form>
    );
}

export default PublicQuestionnaire;