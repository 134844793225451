import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import styled from "styled-components";
import moment from "moment";
import 'moment/locale/nl';
import dateIcon from '../../assets/icons/date.svg';

const DateContainer = styled(Datetime)`
    
    .form-control {
        width: 100%;
        min-height: 46px;
        padding: 13px 17px 13px 47px;
        padding-left: ${(props) => props.paddingLeft ? props.paddingLeft : 'auto'};
        border-radius: 20px;
        color: var(--color-text);
        font-size: var(--fs-text);
        background-color: var(--color-white);
        appearance: none;
        outline: none;
        border: none;
        box-shadow: var(--box-shadow);
        background-image: url(${dateIcon});
        background-repeat: no-repeat;
        background-position: left 13px top 50%;

        &:disabled {
            color: var(--color-text-alpha-50);
            background-color: var(--color-input-background);
            box-shadow: unset;
        }

        ::placeholder {
            color: var(--color-dark);
            opacity: 0.3;
            font-style: italic;
        }
        
    }
    
    table {
        font-size: var(--fs-search-button);
    }
`

// NOTE: Is vervangen door PortalDateTimeField (icm met Controlled) ivm package dependency deprecated moment.js
export const DateField = ({ ...props }) => {

    const yesterday = moment().subtract( 1, 'day' );
    const valid = function (current) {
        return moment.isMoment(current) && current.isAfter(yesterday);
    };

    const validateDate = (value) => {
        if (!moment(value, 'YYYY-MM-DD HH:mm:ss', true).isValid()) {
            return 'Ongeldige datum. Gebruik YYYY-MM-DD HH:mm';
        }
        return true;
    };

    return (
        <DateContainer
            innerRef={{
                ...(props.register && props.register(props.name, {
                    required: props.required,
                    validate: validateDate
                }))
            }}
            initialValue={props.defaultValue}
            onBlur={() => { if(props.onFormFieldBlur) props.onFormFieldBlur()}}
            isValidDate={valid}
            inputProps={{ placeholder: props.placeholder, disabled: props.disabled }}
            dateFormat={props.dateFormat ?? "YYYY-MM-DD"}
            timeFormat={props.timeFormat ?? "HH:mm"}
            locale="nl"
            onChange={props.onChange ? (date) => props.onChange(date) : (date) => {
                props.setValue && props.setValue(props.name, formatDate(date))
            }}
        />
    );
};

const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss")
};