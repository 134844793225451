import {useEffect, useRef} from 'react';
import Cookies from 'js-cookie';
import {getCurrentEnvironment} from '../utils/helpers';
import {Environments} from '../constants/enums';

export const useTokenExpirationCheck = (expirationCallback) => {
    const intervalRef = useRef(null);
    const currentEnvironment = getCurrentEnvironment();
    const shouldLog = currentEnvironment.type === Environments.DEV || currentEnvironment.type === Environments.TEST;

    useEffect(() => {
        const expirationDateFromCookie = Cookies.get('adhdcentraal-portal_expiration');
        if (!expirationDateFromCookie) return;
        const expirationDate = new Date(expirationDateFromCookie);

        const debugLog = (message) => {
            if (shouldLog) {
                console.log(message);
            }
        }

        const setNextCheck = (delay) => intervalRef.current = setTimeout(checkTimeUntilExpiration, delay);

        function checkTimeUntilExpiration() {
            clearTimeout(intervalRef.current);

            const currentDate = new Date();
            const timeRemaining = (expirationDate - currentDate) / 1000;

            debugLog(`Time remaining: ${timeRemaining.toFixed(0)} seconds`);

            if (timeRemaining <= 30) {
                debugLog("Expiration time reached.");
                expirationCallback();
            } else if (timeRemaining < 60) {
                debugLog("Less than 1 minute left, checking again in 10 seconds.");
                setNextCheck(10 * 1000); // 10 seconds
            } else if (timeRemaining < 300) {
                debugLog("Less than 5 minutes left, checking again in 1 minute.");
                setNextCheck(60 * 1000); // 1 minute
            } else if (timeRemaining < 1800) {
                debugLog("Less than 30 minutes left, checking again in 5 minutes.");
                setNextCheck(5 * 60 * 1000); // 5 minutes
            } else if (timeRemaining < 3600) {
                debugLog("Less than 1 hour left, checking again in 15 minutes.");
                setNextCheck(15 * 60 * 1000); // 15 minutes
            } else {
                debugLog("More than 1 hour left, checking again in 30 minutes.");
                setNextCheck(30 * 60 * 1000); // 30 minutes
            }
        }

        checkTimeUntilExpiration();

        return () => clearTimeout(intervalRef.current);
        //eslint-disable-next-line
    }, []);
}