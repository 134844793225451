import {Modal} from './Modal';
import styled, {css} from 'styled-components';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import {AspectRatio} from '../AspectRatio/AspectRatio';

const StyledModal = styled(Modal)`
    max-width: 500px;
    width: 100%;
`;

const Inner = styled.div`
    pointer-events: initial;
    position: relative;
    width: 100%;
    background-color: var(--color-content-background);
    box-shadow: var(--box-shadow);
    border-radius: 20px;
`;

const StyledCloseIcon = styled(CloseIcon)`
    width: 40px;
    height: 40px;
    cursor: pointer;
    
    ${({$hasImage}) => $hasImage ? css`
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;
        filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.25));
       
        path { fill: var(--color-white) }
        
    ` : css`
        display: flex;
        margin-left: auto;
        margin-bottom: 10px;
    `};
`;

const ImageWrapper = styled.div`
    border-radius: 20px 20px 0 0;
    overflow: hidden;
`;

const ContentWrapper = styled.div`
    padding: 30px;
`;

const InnerContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

function NewsModal({children, ...props}) {
    return (
        <StyledModal {...props}>
            <Inner>
                {children}
            </Inner>
        </StyledModal>
    );
}

NewsModal.Image = function NewsModal({children, src, alt, ...props}) {
    if(!src) return null;

    return (
        <ImageWrapper>
            <AspectRatio src={src} alt={alt} {...props} />
        </ImageWrapper>
    );
}

NewsModal.Content = function NewsModal({children, handleClose, hasImage = false}) {
    return (
        <ContentWrapper>
            <StyledCloseIcon onClick={handleClose} $hasImage={hasImage} />
            <InnerContent>
                {children}
            </InnerContent>
        </ContentWrapper>
    );
}

export default NewsModal;