import {Button, buttonVariants} from '../../../components/Button/Button';
import React, {useEffect, useState} from 'react';
import FormModal from '../../../components/Modal/FormModal';
import styled from 'styled-components';
import {useOpenModal} from '../../../hooks/useOpenModal';
import {Controller, useForm} from 'react-hook-form';
import {FormField, FormFieldWrapper} from '../../../components/FormFields/FormFields';
import {useFetcher, useParams} from 'react-router-dom';
import {Text} from '../../../components/Text/Text';
import {Statuses} from '../../../constants/enums';
import ConfirmationModal, {CancelButton} from "../../../components/Modal/ConfirmationModal";
import {PortalSelectField} from '../../../components/PortalFormFields/PortalSelectField/PortalSelectField';
import {PortalDateTimeField} from '../../../components/PortalFormFields/PortalDateTimeField/PortalDateTimeField';
import dayjs from 'dayjs';

const Box = styled.div`
    flex: 1 1 0;
`;

const StyledButton = styled(Button)`
    ${({$variant}) => buttonVariants($variant)};
    border: none;
    width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
    gap: 30px;
`

export const AddNotification = () => {
    const {patientUUID} = useParams();
    const {isOpen, handleOpen, handleClose} = useOpenModal();
    const {isOpen: confirmIsOpen, handleOpen: handleConfirmOpen, handleClose: handleConfirmClose} = useOpenModal()

    const sendOptions = [
        { value: "send", label: "Direct versturen" },
        { value: "plan", label: "Inplannen" }
    ]

    const {register, control, watch, reset, handleSubmit, trigger, formState: { errors }, clearErrors} = useForm({
        defaultValues: {
            intent: "add-notification",
            select: sendOptions[0]
        }
    });

    const watchSelect = watch("select", sendOptions[0])
    const fetcher = useFetcher();
    const [status, setStatus] = useState(Statuses.IDLE);
    const [error, setError] = useState(false);

    const confirm = async () => {
        if (await trigger()) {
            handleConfirmOpen()
        }
    }

    const onSubmit = (data) => {
        clearErrors();

        setStatus(Statuses.SUBMITTING);
        setError(false);

        const submitData = {
            intent: data.intent,
            title: data.title,
            ...(data.pushAt && {pushAt: data.pushAt && dayjs(data.pushAt).format("YYYY-MM-DD HH:mm:ss")}),
            message: data.message,
        }

        fetcher.submit(submitData, { method: "POST", encType: "application/json", action: `/patient/${patientUUID}/berichten`});
    }

    // Fetcher callback
    useEffect(() => {
        if(fetcher?.state === "idle") {
            if(fetcher?.data?.error) {
                setStatus(Statuses.IDLE);
                return setError(true);
            }

            if(fetcher?.data) {
                setStatus(Statuses.SUCCESS);
                handleConfirmClose()
                handleClose()
            }
        }
        //eslint-disable-next-line
    }, [fetcher]);

    const handleOnExited = () => {
        reset();
        setStatus(Statuses.IDLE);
    }

    const disabled = status === Statuses.SUBMITTING;

    return (
        <>
            <Button type="button" onClick={handleOpen}>Pushbericht sturen</Button>

            <FormModal isOpen={isOpen} handleClose={handleClose}>
                <FormModal.Header handleClose={handleClose}>Pushbericht sturen</FormModal.Header>

                <Form id="add-notification-form" onSubmit={handleSubmit(onSubmit)}>
                    <FormField
                        type="TextField"
                        id="Title"
                        placeholder="Typ hier de titel (max. 65 karakters)"
                        label="Titel"
                        required={true}
                        maxLength={65}
                        error={errors.title}
                        register={register}
                        name="title"
                    />

                    <FormFieldWrapper
                        id="SelectField"
                        label="Wanneer wil je het pushbericht versturen?"
                        required={true}
                        error={errors.select}
                    >
                        <Controller
                            name="select"
                            control={control}
                            rules={{ required: true }}
                            render={({field}) => {
                                return (
                                    <PortalSelectField
                                        {...field}
                                        options={sendOptions}
                                    />
                                )
                            }}
                        />

                    </FormFieldWrapper>

                    {watchSelect?.value === "plan" &&
                        <FormFieldWrapper
                            id="DateField"
                            label="Datum en tijd"
                            required={true}
                            error={errors.pushAt}
                        >
                            <Controller
                                name="pushAt"
                                control={control}
                                rules={{ required: true }}
                                render={({
                                    field: { onChange, value },
                                }) => {
                                    return (
                                        <PortalDateTimeField
                                            value={value || ""}
                                            onChange={(date) => {
                                                onChange(date?.isValid ? date : "");
                                            }}
                                            placeholder="Kies een datum en tijd"
                                        />
                                    )
                                }}
                            />
                        </FormFieldWrapper>
                    }

                    <FormField
                        type="TextArea"
                        id="Message"
                        placeholder="Typ hier je bericht (max. 200 karakters)"
                        label="Bericht"
                        required={true}
                        maxLength={200}
                        error={errors.message}
                        register={register}
                        name="message"
                    />
                </Form>

                {error && <Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}

                <FormModal.Footer>
                    <Box><StyledButton type="button" onClick={handleClose} $variant="secondary">Annuleren</StyledButton></Box>
                    <Box><StyledButton type="button" onClick={confirm}  disabled={disabled} loading={disabled}>Versturen</StyledButton></Box>
                </FormModal.Footer>

                <ConfirmationModal isOpen={confirmIsOpen} handleOpen={handleConfirmOpen} handleClose={handleConfirmClose} onExited={status === Statuses.SUCCESS ? handleOnExited : undefined}>
                    <ConfirmationModal.Header>Pushbericht {watchSelect?.value === "send" ? "versturen" : watchSelect?.value === "plan" ? "inplannen" : ""}</ConfirmationModal.Header>
                    <Text $align="center">
                        {watchSelect?.value === "send"
                            ? "Weet je zeker dat je dit pushbericht wil versturen? Je kunt dit niet meer ongedaan maken."
                            : watchSelect?.value === "plan" ? "Weet je zeker dat je dit pushbericht wil inplannen? Je kunt de verzenddatum hierna niet meer wijzigen."
                            : ""
                        }
                    </Text>
                    <StyledButton type="submit" form="add-notification-form" disabled={disabled} loading={disabled}>Ja, {watchSelect?.value === "send" ? "versturen" : watchSelect?.value === "plan" ? "inplannen" : ""}</StyledButton>
                    <CancelButton onClick={handleConfirmClose}>Annuleer</CancelButton>
                    {error && <Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}
                </ConfirmationModal>
            </FormModal>
        </>
    );
}