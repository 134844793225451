import {Outlet} from 'react-router-dom';
import styled from 'styled-components';
import background from '../assets/background.jpg';
import {Container} from '../components/Utils/Utils';
import {ReactComponent as Logo} from '../assets/logo.svg';

const StyledPortalLayout = styled.div`
    background-color: var(--color-background);
    background-image: url(${background});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
    display: flex;
    justify-content: center;
`;

const Content = styled.div`
    background-color: var(--color-content-background);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    padding: 60px;
    position: relative;
    margin-bottom: 200px;
    
    @media screen and (max-width: 768px) {
        padding: 30px;
    }

    @media screen and (max-width: 525px) {
        padding: 30px 20px;
        margin-left: -20px;
        width: 100vw;
        border-radius: 0;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0;
`;

const Box = styled.div`
    flex: 1 1 0;
`;

const StyledLogo = styled(Logo)`
    display: block;
    width: 120px;
    height: 88px;
    object-fit: cover;
    margin: 0 auto;
`;

export default function GuestLayout() {
    return (
        <StyledPortalLayout>
            <Container>
                <Header>
                    <Box />

                    <Box>
                        <StyledLogo />
                    </Box>

                    <Box />
                </Header>
                <Content >
                    <Outlet />
                </Content>
            </Container>
        </StyledPortalLayout>
    );
}