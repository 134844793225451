import {Modal} from './Modal';
import styled from 'styled-components';
import {Heading1} from '../Heading/Heading';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import {forwardRef} from 'react';

const StyledModal = styled(Modal)`
    max-width: ${({$identifier}) => $identifier === "Diva" ? "1200px" : "800px"};
    width: 100%;
`;

const Content = styled.div`
    pointer-events: initial;
    position: relative;
    width: 100%;
    background-color: var(--color-content-background);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    padding: 50px 100px;
`;

const StyledCloseIcon = styled(CloseIcon)`
    width: 40px;
    height: 40px;
    position: absolute;
    top: 42px;
    right: 45px;
    cursor: pointer;
`;

const FormTitle = styled(Heading1).attrs({
    as: "h3",
    $align: "center",
    $noMargin: true
})`
    margin-bottom: 45px;
`;

const Footer = styled.div`
    display: flex;
    gap: 35px;
    margin-top: 50px;
`;

const FormModal = forwardRef(function FormModal(props, ref) {
    const {children, identifier, ...otherProps} = props;

    return (
        <StyledModal ref={ref} $identifier={identifier} {...otherProps}>
            <Content>
                {children}
            </Content>
        </StyledModal>
    );
})

FormModal.Header = function FormModal({children, handleClose}) {
    return (
        <>
            <StyledCloseIcon onClick={handleClose} />
            <FormTitle>{children}</FormTitle>
        </>
    );
}

FormModal.Footer = function FormModal({children}) {
    return (
        <Footer>
            {children}
        </Footer>
    );
}

export default FormModal;