import {Heading1} from '../../components/Heading/Heading';
import {useOutletContext} from 'react-router-dom';
import {MarkdownText, Text} from '../../components/Text/Text';
import styled from 'styled-components';
import {Button} from '../../components/Button/Button';

const ButtonWrapper = styled.div`
    text-align: right;
    margin-top: 64px;
`;

const StyledButton = styled(Button)`
    min-width: calc(50% -  35px);
`;

const PublicQuestionnaireIntroduction = () => {
    const {publicQuestionnaireData, encodedSignature} = useOutletContext();

    return (
        <>
            <Heading1 $noMargin>{publicQuestionnaireData?.title}</Heading1>
            {publicQuestionnaireData?.status && <Text $bold>{publicQuestionnaireData.status}</Text>}
            {publicQuestionnaireData?.description && <MarkdownText text={publicQuestionnaireData.description} $noMargin/>}
            <ButtonWrapper>
                <StyledButton to={`1?c=${encodedSignature}`}>{publicQuestionnaireData?.buttonText}</StyledButton>
            </ButtonWrapper>
        </>
    );
};

export default PublicQuestionnaireIntroduction;