import styled, {keyframes} from "styled-components";
import spinnerImage from '../../assets/spinner.png';
import spinnerInvertedImage from '../../assets/spinner-inverted.png';

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    
    to {
        transform: rotate(360deg);
    }
`;

export const SpinnerOverlay = styled.div`
    background-color: rgba(var(--color-grey-10-rgb), .75);
    position: absolute;
    inset: 0;
    padding: 80px 0;
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 76px;
`;

export const Spinner = styled.img.attrs({
    src: spinnerImage,
    alt: ""
})`
    user-select: none;
    display: inline-block;
    width: 50px;
    height: 50px;
    animation: ${rotate} 1.25s ease-out infinite;
`;

export const SpinnerInverted = styled(Spinner).attrs({
    src: spinnerInvertedImage,
    alt: ""
})``;