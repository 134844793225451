import {Outlet, ScrollRestoration, useLocation, useNavigation} from 'react-router-dom';
import nProgress from 'nprogress';
import {useEffect} from 'react';
import styled from 'styled-components';
import GlobalStyle from '../../globalStyles';
import {Spinner} from '../../components/Spinner/Spinner';
import Cookies from 'js-cookie';
import {fetchProtectedData} from '../../api/fetch';
import {checkAccountPermissions} from '../../utils/helpers';

const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const SpinnerWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

// Closure to await routes that are dependent of the account, this makes the rootLoader sequential to other parallel loaders
export let rootLoaderPromise = createRootLoaderPromise();

function createRootLoaderPromise() {
    return (async () => {
        if (Cookies.get('adhdcentraal-portal_token')) {
            const accountData = await fetchProtectedData(null,"account", {
                doNotCatchError: true
            });
            return { account: accountData };
        }
        return { account: null };
    })();
}

// Reset the rootLoaderPromise when account is stale
export function resetRootLoaderPromise() {
    rootLoaderPromise = createRootLoaderPromise();
}

// Check if the required account is present or redirect to the login page
export async function checkRequiredAccount(permissionToCheck) {
    const {account} = await rootLoaderPromise;

    if(!account) {
        throw new Response("Unauthorized", { status: 401});
    }

    // If a permission is passed, check if this permission is present or return unauthorized
    if(permissionToCheck && !checkAccountPermissions(account, permissionToCheck)) {
        throw new Response("Forbidden", { status: 403});
    }

    return {account};
}

// Optionally check if an account is present (and handle locally if no account)
export async function checkOptionalAccount() {
    const {account} = await rootLoaderPromise;
    return {account};
}

export async function rootLoader() {
    const {account} = await rootLoaderPromise;
    return {account};
}

export function shouldRevalidateRoot() {
    return false;
}

// Do not revalidate parent routes on pagination
export function shouldNotRevalidateParentOnPagination({currentUrl, nextUrl, defaultShouldRevalidate}) {
    const nextUrlSearchParamsObj = Object.fromEntries(nextUrl?.searchParams);

    // Do not revalidate if on the same page but only searchParams change
    if (currentUrl?.pathname === nextUrl?.pathname) {
        if (Object.keys(nextUrlSearchParamsObj)?.length > 0) {
            return false;
        }
    }

    return defaultShouldRevalidate;
}

const Root = () => {
    const location = useLocation();
    const navigation = useNavigation();

    // NProgress bar (loadingbar on page change)
    nProgress.configure({
        showSpinner: false,
        template: '<div class="bar" role="bar"></div>',
        minimum: 0.3,
        speed: 400,
    });

    // Normal navigation in data router is idle -> loading -> idle, so start nProgress if state changed to "loading"
    if (navigation.state === "loading") {
        nProgress.start();
    }

    if (navigation.state === "idle") {
        nProgress.done();
    }

    // When the location changed, complete the nProgress
    useEffect(() => {
        nProgress.done();
    }, [location])

    return (
        <StyledLayout>
            <GlobalStyle/>
            <ScrollRestoration/>
            <Outlet/>
        </StyledLayout>
    );
}

export default Root;

export const ApiFailureFallbackLayout = ({children}) => {
    return (
        <StyledLayout>
            <GlobalStyle/>
            <ScrollRestoration/>
            {children}
        </StyledLayout>
    );
}

export const RootFallbackElement = () => {
    return (
        <StyledLayout>
            <GlobalStyle/>
            <SpinnerWrapper>
                <Spinner/>
            </SpinnerWrapper>
        </StyledLayout>
    );
}