import styled, {css} from 'styled-components';
import {ReactComponent as Chevron} from '../../assets/icons/chevron.svg';
import {CustomLink} from '../CustomLink/CustomLink';
import {SpinnerInverted} from '../Spinner/Spinner';
import {forwardRef} from 'react';

export const buttonVariants = (variant = "primary") => ({
    primary: css`
        background-color: var(--color-primary);
        color: var(--color-white);

        &:hover {
            background-color: var(--color-primary-hover);
        }
    `,
    secondary: css`
        background-color: var(--color-secondary-button);
        color: var(--color-white);
        
        &:hover {
            background-color: var(--color-secondary-button-hover);
        }
    `,
}[variant]);

const StyledButton = styled(CustomLink)`
    ${({$variant}) => buttonVariants($variant)};
    border-radius: 30px;
    display: inline-flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: ${ props => props.$padding || '8px 16px 8px 20px'};
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    text-decoration: none;
    line-height: 29px;
    text-align: center;
    ${({$type}) => ($type === "button" || $type === "submit") && 'border: none;'};
    width: ${ props => props.$width || 'auto'};
    
    &:disabled {
        opacity: .8;
        pointer-events: none;
        color: rgba(var(--color-grey-90-rgb), 0.25);
        background: rgba(var(--color-grey-90-rgb), 0.06);
        
        svg {
            path {
                fill: rgba(var(--color-grey-90-rgb), 0.25);
            }
        }
    }
`;

const StyledChevron = styled(Chevron)`
    width: 20px;
    height: 20px;
    flex-shrink: 0;
`;

const StyledSpinnerInverted = styled(SpinnerInverted)`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;

export const Button = forwardRef(function Button(props, ref) {
    const {className, children, to, type, suffix = <StyledChevron />, loading, ...otherProps} = props;

    return (
        <StyledButton ref={ref} className={className} to={to} as={(type === "button" || type === "submit") ? "button" : undefined} type={type} $type={type} {...otherProps}>
            {children}
            {loading ? <StyledSpinnerInverted/> : suffix}
        </StyledButton>
    );
})