import {ChevronIcon, Table, TableHead, TableHeadCell, TableHeadRow, TableRow, TableRowCell, TableRowInner} from '../../../components/Table/Table';
import {LayeredCustomLink} from '../../../components/CustomLink/CustomLink';
import React from 'react';
import styled from 'styled-components';
import {Heading2} from '../../../components/Heading/Heading';
import {ReactComponent as HistoryIcon} from '../../../assets/icons/history.svg';
import {useAsyncValue} from 'react-router-dom';

const StyledHistory = styled.div`
    margin-top: 20px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 16px;
    padding: 20px;
`;

const HistoryTitle = styled(Heading2).attrs({
    $noMargin: true,
})`
    color: var(--color-text-alpha-50);
    margin-bottom: 30px;
`;

const StyledHistoryIcon = styled(HistoryIcon)`
    width: 24px;
    height: 24px;

    path { fill: var(--color-text-alpha-50); }
`;

const Chevron = styled(ChevronIcon)`
    width: 32px;
    height: 32px;
    vertical-align: middle;
    cursor: pointer;
`;

export const History = ({recentlyViewedPatients}) => {
    const data = useAsyncValue();

    if (!data?.items?.length || !recentlyViewedPatients?.length) return null;

    return (
        <StyledHistory>
            <HistoryTitle>Onlangs bekeken</HistoryTitle>

            <Table>
                <TableHead>
                    <TableHeadRow>
                        <TableHeadCell $flex="0" $minWidth="44px" $padding="0px 0 15px 20px"></TableHeadCell>
                        <TableHeadCell $fontSize={"var(--fs-heading-3)"}>Naam patiënt</TableHeadCell>
                        <TableHeadCell $fontSize={"var(--fs-heading-3)"}>Medicore ID</TableHeadCell>
                        <TableHeadCell $fontSize={'var(--fs-heading-3)'}>Geboortedatum</TableHeadCell>
                        <TableHeadCell $fontSize={'var(--fs-heading-3)'}>E-mailadres</TableHeadCell>
                        <TableHeadCell $fontSize={'var(--fs-heading-3)'}>Telefoonnummer</TableHeadCell>
                        <TableHeadCell $fontSize={'var(--fs-heading-3)'}>Locatie</TableHeadCell>
                        <TableHeadCell $flex="0" $minWidth="72px"></TableHeadCell>
                    </TableHeadRow>
                </TableHead>

                {data?.items
                    ?.sort((a, b) => recentlyViewedPatients?.indexOf(a.id) - recentlyViewedPatients?.indexOf(b.id))
                    ?.map((item, rowIndex) => {
                        const key = `${rowIndex}-${item?.cells?.[0]}`;
                        const isOddRow = rowIndex % 2 === 0;

                        return (
                            <TableRow key={key} $isClickable={true}>
                                <TableRowInner $isOddRow={isOddRow} $transparent={true}>
                                    <TableRowCell $flex="0" $minWidth="44px" $padding="16px 0 16px 20px"><StyledHistoryIcon /></TableRowCell>
                                    <TableRowCell $variant="title">{item.name && item.name}</TableRowCell>
                                    <TableRowCell>{item.medicoreID && item.medicoreID}</TableRowCell>
                                    <TableRowCell>{item.birthDate && item.birthDate}</TableRowCell>
                                    <TableRowCell>{item.email && item.email}</TableRowCell>
                                    <TableRowCell>{item.phoneNumber && item.phoneNumber}</TableRowCell>
                                    <TableRowCell>{item?.location?.[0] && item.location[0]}</TableRowCell>
                                    <TableRowCell $flex="0" $minWidth="72px">
                                        <LayeredCustomLink to={`/patient/${item.id}`}><Chevron /></LayeredCustomLink>
                                    </TableRowCell>
                                </TableRowInner>
                            </TableRow>
                        );
                    })
                }
            </Table>
        </StyledHistory>
    );
}