import styled from 'styled-components';
import {Button} from '../../../components/Button/Button';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal';
import {Text} from '../../../components/Text/Text';
import React from 'react';
import {Statuses} from '../../../constants/enums';

const StyledButton = styled(Button)`
    border: none;
    width: 100%;
    margin-top: 20px;
`;

const CancelButton = styled.button`
    background-color: transparent;
    color: var(--color-primary);
    text-align: center;
    cursor: pointer;
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    text-decoration: none;
    line-height: 29px;
    border: none;
    width: 100%;
    margin-top: 10px;
    
    &:hover {
        color: var(--color-primary-hover);
    }
`;

// NOTE: Currently also used in PublicQuestionnaireStep
export const ConfirmBeforeSubmitModal = ({isOpen, handleClose, status, error, form}) => {
    const disabled = status === Statuses.SUBMITTING;

    return (
        <ConfirmationModal isOpen={isOpen} handleClose={handleClose}>
            <ConfirmationModal.Header>Vragenlijst afronden</ConfirmationModal.Header>
            <Text $noMargin $align="center">Weet je zeker dat je deze vragenlijst wil afronden? Je kunt deze hierna niet meer wijzigen.</Text>
            <StyledButton type="submit" form={form} disabled={disabled} loading={disabled}>Ja, afronden</StyledButton>
            <CancelButton onClick={handleClose}>Annuleer</CancelButton>
            {error && <Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}
        </ConfirmationModal>
    );
}