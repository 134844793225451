import styled from 'styled-components';
import {SubHeading} from '../Heading/Heading';
import veryUnhappy from '../../assets/icons/very-unhappy.svg';
import veryUnhappyOutline from '../../assets/icons/very-unhappy-outline.svg';
import unhappy from '../../assets/icons/unhappy.svg';
import unhappyOutline from '../../assets/icons/unhappy-outline.svg';
import neutral from '../../assets/icons/neutral.svg';
import neutralOutline from '../../assets/icons/neutral-outline.svg';
import happy from '../../assets/icons/happy.svg';
import happyOutline from '../../assets/icons/happy-outline.svg';
import veryHappy from '../../assets/icons/very-happy.svg';
import veryHappyOutline from '../../assets/icons/very-happy-outline.svg';

const StyledSmileyButtonsField = styled.div`
    max-width: fit-content;
`;

const Smileys = styled.div`
    display: flex;
    gap: 9px;
`;

const Smiley = styled.input`
    appearance: none;
    width: 61px;
    height: 61px;
    flex-shrink: 0;
    content: url(${({$inactive}) => $inactive});

    &:checked {
        content: url(${({$active}) => $active});
    }
`;

const Labels = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

const StyledSubHeading = styled(SubHeading).attrs({
    $noMargin: true,
})`
    color: var(--color-text-alpha-50);
`;

const smileyOptions = [
    {id: 1, inactive: veryUnhappyOutline, active: veryUnhappy },
    {id: 2, inactive: unhappyOutline, active: unhappy },
    {id: 3, inactive: neutralOutline, active: neutral },
    {id: 4, inactive: happyOutline, active: happy },
    {id: 5, inactive: veryHappyOutline, active: veryHappy },
];

export const SmileyButtonsField = ({data}) => {
    return (
        <StyledSmileyButtonsField>
            <Smileys>
                {smileyOptions.map(option => (
                    <Smiley key={option.id} type="radio" defaultChecked={data.answer === option.id} $inactive={option.inactive} $active={option.active} disabled />
                ))}
            </Smileys>
            <Labels>
                <StyledSubHeading>{data.leftLabel}</StyledSubHeading>
                <StyledSubHeading>{data.rightLabel}</StyledSubHeading>
            </Labels>
        </StyledSmileyButtonsField>
    );
}